import React, { useState, useEffect } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

export default function PDFDownloadLinkWrapper(props) {
  return (
    <PDFDownloadLink
      document={props.children}
      fileName={props.pdfName} // ${pdfName}
    >
      {({ blob, url, loading, error }) => {
        if (loading === false && blob && url && props.buttonRef) {
          setTimeout(() => {
            props.onPdfRender();
          }, 500);
        }
        return (
          <button
            type="button"
            className="dnld_btn_1 mt-3 disable_Class"
            disabled={loading}
            ref={props.buttonRef}
            hidden
          >
            {loading ? "Loading Card..." : "Download Card"}
          </button>
        );
      }}
    </PDFDownloadLink>
  );
}
