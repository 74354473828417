import {
  fb_icon,
  Mask,
  Message,
  phone_icon,
  Shape,
  World,
  youtube_icon,
} from "../../shared/images";

function Footer() {
  return (
    <>
      {" "}
      {/* className="footer" */}
      <section className="pt-3">
        {/*<div className="container">
          <div className="row">
            <div className="col-md-3 d-flex">
              <span className="location__icon mr-2">
                <img src={Mask} alt="Location Icon" className="loc_icon" />
              </span>
              <h6 className="address">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </h6>
            </div>
            <div className="col-md-2">
              <h6 className="phn_number">
                <span className="mr-2">
                  <img src={phone_icon} alt="Phone Icon" className="phn_icon" />
                </span>
                +1 789 123456
              </h6>
            </div>
            <div className="col-md-4">
              <h6 className="email">
                <span className="mr-2">
                  <img src={Message} alt="Email Icon" className="email_icon" />
                </span>
                info@signsapp.com
              </h6>
              <div className="social_icon d-flex">
                <div className="icons">
                  <img src={Shape} alt="twitter icpon" className="icon_img" />
                </div>
                <div className="icons">
                  <img src={fb_icon} alt="twitter icpon" className="icon_img" />
                </div>
                <div className="icons">
                  <img src={youtube_icon} alt="twitter icpon" className="icon_img" />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <h6 className="nesw_letter">
                Receive special offers and get our latest updates.
              </h6>
              <div className ="news_letr_div">
              <input name="email" type="email" className="news_letter_input" placeholder="Enter e-mail" />
              <button type="submit" className="join_btn">Join</button>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <section className="footer_bottom">
        <div className="container">
          <div className="d-flex px-5 pt-2 justify-content-between">
            <div className="langauge">
              <h6 className="eng_des">
                <span className="globe">
                  <img src={World} alt="Globe Icon" className="globe_img" />
                </span>
                English
              </h6>
            </div>
            <div className="copyright">
              <p className="copy_right_desc">
                Copyright © 2020. All rights reserved.
              </p>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}

export default Footer;
