import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

export default function PdfWrapper(props) {
  return (
    <Document>
      <Page size="A4" wrap={false}>
        {props.children}
      </Page>
    </Document>
  );
}
