const emptyWristbandPattern54 = {
  size: 96,
  emptySize: 54,
  bgColor: "#197D3E",
  border_color: "#fff",
  class: "three-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const emptyWristbandPattern96 = {
  size: 120,
  emptySize: 96,
  bgColor: "#197D3E",
  class: "four-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "03",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "003",
                  reverse_code: "030",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "103",
                  reverse_code: "031",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "203",
                  reverse_code: "032",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "300",
                  reverse_code: "003",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "301",
                  reverse_code: "013",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "302",
                  reverse_code: "023",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "303",
                  reverse_code: "033",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "13",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "013",
                  reverse_code: "130",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "113",
                  reverse_code: "131",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "213",
                  reverse_code: "132",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "310",
                  reverse_code: "103",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "311",
                  reverse_code: "113",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "312",
                  reverse_code: "123",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "313",
                  reverse_code: "133",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "23",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "023",
                  reverse_code: "230",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "123",
                  reverse_code: "231",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "223",
                  reverse_code: "232",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "320",
                  reverse_code: "203",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "321",
                  reverse_code: "213",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "322",
                  reverse_code: "223",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "323",
                  reverse_code: "233",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "33",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "033",
                  reverse_code: "330",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "133",
                  reverse_code: "331",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "233",
                  reverse_code: "332",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "330",
                  reverse_code: "303",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "331",
                  reverse_code: "313",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "332",
                  reverse_code: "323",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "333",
                  reverse_code: "333",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "43",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "043",
                  reverse_code: "430",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "143",
                  reverse_code: "431",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "243",
                  reverse_code: "432",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "340",
                  reverse_code: "403",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "341",
                  reverse_code: "413",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "342",
                  reverse_code: "423",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "343",
                  reverse_code: "433",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "53",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "053",
                  reverse_code: "530",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "153",
                  reverse_code: "531",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "253",
                  reverse_code: "532",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "350",
                  reverse_code: "503",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "351",
                  reverse_code: "513",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "352",
                  reverse_code: "523",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "353",
                  reverse_code: "533",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const emptyWristbandPattern150 = {
  size: 216,
  emptySize: 150,
  bgColor: "#197D3E",
  class: "five-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "03",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "04",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "003",
                  reverse_code: "030",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "004",
                  reverse_code: "040",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "103",
                  reverse_code: "031",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "104",
                  reverse_code: "041",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "203",
                  reverse_code: "032",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "204",
                  reverse_code: "042",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "300",
                  reverse_code: "003",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "301",
                  reverse_code: "013",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "302",
                  reverse_code: "023",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "303",
                  reverse_code: "033",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "304",
                  reverse_code: "043",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "400",
                  reverse_code: "004",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "401",
                  reverse_code: "014",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "402",
                  reverse_code: "024",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "403",
                  reverse_code: "034",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "404",
                  reverse_code: "044",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "13",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "14",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "013",
                  reverse_code: "130",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "014",
                  reverse_code: "140",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "113",
                  reverse_code: "131",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "114",
                  reverse_code: "141",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "213",
                  reverse_code: "132",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "214",
                  reverse_code: "142",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "310",
                  reverse_code: "103",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "311",
                  reverse_code: "113",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "312",
                  reverse_code: "123",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "313",
                  reverse_code: "133",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "314",
                  reverse_code: "143",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "410",
                  reverse_code: "104",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "411",
                  reverse_code: "114",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "412",
                  reverse_code: "124",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "413",
                  reverse_code: "134",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "414",
                  reverse_code: "144",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "23",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "24",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "023",
                  reverse_code: "230",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "024",
                  reverse_code: "240",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "123",
                  reverse_code: "231",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "124",
                  reverse_code: "241",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "223",
                  reverse_code: "232",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "224",
                  reverse_code: "242",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "320",
                  reverse_code: "203",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "321",
                  reverse_code: "213",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "322",
                  reverse_code: "223",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "323",
                  reverse_code: "233",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "324",
                  reverse_code: "243",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "420",
                  reverse_code: "204",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "421",
                  reverse_code: "214",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "422",
                  reverse_code: "224",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "423",
                  reverse_code: "234",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "424",
                  reverse_code: "244",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "33",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "34",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "033",
                  reverse_code: "330",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "034",
                  reverse_code: "340",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "133",
                  reverse_code: "331",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "134",
                  reverse_code: "341",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "233",
                  reverse_code: "332",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "234",
                  reverse_code: "342",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "330",
                  reverse_code: "303",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "331",
                  reverse_code: "313",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "332",
                  reverse_code: "323",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "333",
                  reverse_code: "333",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "334",
                  reverse_code: "343",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "430",
                  reverse_code: "304",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "431",
                  reverse_code: "314",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "432",
                  reverse_code: "324",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "433",
                  reverse_code: "334",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "434",
                  reverse_code: "344",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "43",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "44",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "043",
                  reverse_code: "430",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "044",
                  reverse_code: "440",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "143",
                  reverse_code: "431",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "144",
                  reverse_code: "441",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "243",
                  reverse_code: "432",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "244",
                  reverse_code: "442",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "340",
                  reverse_code: "403",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "341",
                  reverse_code: "413",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "342",
                  reverse_code: "423",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "343",
                  reverse_code: "433",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "344",
                  reverse_code: "443",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "440",
                  reverse_code: "404",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "441",
                  reverse_code: "414",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "442",
                  reverse_code: "424",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "443",
                  reverse_code: "434",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "444",
                  reverse_code: "444",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "53",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "54",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "053",
                  reverse_code: "530",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "054",
                  reverse_code: "540",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "153",
                  reverse_code: "531",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "154",
                  reverse_code: "541",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "253",
                  reverse_code: "532",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "254",
                  reverse_code: "542",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "350",
                  reverse_code: "503",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "351",
                  reverse_code: "513",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "352",
                  reverse_code: "523",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "353",
                  reverse_code: "533",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "354",
                  reverse_code: "543",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "450",
                  reverse_code: "504",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "451",
                  reverse_code: "514",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "452",
                  reverse_code: "524",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "453",
                  reverse_code: "534",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "454",
                  reverse_code: "544",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const emptyWristbandPattern216 = {
  size: 294,
  emptySize: 216,
  bgColor: "#197D3E",
  class: "six-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "03",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "04",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "05",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "003",
                  reverse_code: "030",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "004",
                  reverse_code: "040",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "005",
                  reverse_code: "050",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "103",
                  reverse_code: "031",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "104",
                  reverse_code: "041",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "105",
                  reverse_code: "051",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "203",
                  reverse_code: "032",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "204",
                  reverse_code: "042",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "205",
                  reverse_code: "052",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "300",
                  reverse_code: "003",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "301",
                  reverse_code: "013",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "302",
                  reverse_code: "023",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "303",
                  reverse_code: "033",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "304",
                  reverse_code: "043",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "305",
                  reverse_code: "053",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "400",
                  reverse_code: "004",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "401",
                  reverse_code: "014",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "402",
                  reverse_code: "024",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "403",
                  reverse_code: "034",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "404",
                  reverse_code: "044",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "405",
                  reverse_code: "054",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "500",
                  reverse_code: "005",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "501",
                  reverse_code: "015",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "502",
                  reverse_code: "025",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "503",
                  reverse_code: "035",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "504",
                  reverse_code: "045",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "505",
                  reverse_code: "055",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "13",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "14",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "15",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "013",
                  reverse_code: "130",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "014",
                  reverse_code: "140",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "015",
                  reverse_code: "150",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "113",
                  reverse_code: "131",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "114",
                  reverse_code: "141",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "115",
                  reverse_code: "151",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "213",
                  reverse_code: "132",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "214",
                  reverse_code: "142",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "215",
                  reverse_code: "152",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "310",
                  reverse_code: "103",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "311",
                  reverse_code: "113",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "312",
                  reverse_code: "123",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "313",
                  reverse_code: "133",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "314",
                  reverse_code: "143",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "315",
                  reverse_code: "153",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "410",
                  reverse_code: "104",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "411",
                  reverse_code: "114",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "412",
                  reverse_code: "124",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "413",
                  reverse_code: "134",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "414",
                  reverse_code: "144",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "415",
                  reverse_code: "154",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "510",
                  reverse_code: "105",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "511",
                  reverse_code: "115",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "512",
                  reverse_code: "125",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "513",
                  reverse_code: "135",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "514",
                  reverse_code: "145",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "515",
                  reverse_code: "155",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "23",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "24",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "25",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "023",
                  reverse_code: "230",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "024",
                  reverse_code: "240",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "025",
                  reverse_code: "250",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "123",
                  reverse_code: "231",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "124",
                  reverse_code: "241",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "125",
                  reverse_code: "251",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "223",
                  reverse_code: "232",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "224",
                  reverse_code: "242",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "225",
                  reverse_code: "252",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "320",
                  reverse_code: "203",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "321",
                  reverse_code: "213",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "322",
                  reverse_code: "223",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "323",
                  reverse_code: "233",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "324",
                  reverse_code: "243",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "325",
                  reverse_code: "253",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "420",
                  reverse_code: "204",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "421",
                  reverse_code: "214",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "422",
                  reverse_code: "224",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "423",
                  reverse_code: "234",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "424",
                  reverse_code: "244",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "425",
                  reverse_code: "254",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "520",
                  reverse_code: "205",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "521",
                  reverse_code: "215",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "522",
                  reverse_code: "225",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "523",
                  reverse_code: "235",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "524",
                  reverse_code: "245",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "525",
                  reverse_code: "255",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "33",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "34",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "35",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "033",
                  reverse_code: "330",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "034",
                  reverse_code: "340",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "035",
                  reverse_code: "350",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "133",
                  reverse_code: "331",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "134",
                  reverse_code: "341",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "135",
                  reverse_code: "351",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "233",
                  reverse_code: "332",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "234",
                  reverse_code: "342",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "235",
                  reverse_code: "352",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "330",
                  reverse_code: "303",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "331",
                  reverse_code: "313",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "332",
                  reverse_code: "323",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "333",
                  reverse_code: "333",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "334",
                  reverse_code: "343",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "335",
                  reverse_code: "353",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "430",
                  reverse_code: "304",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "431",
                  reverse_code: "314",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "432",
                  reverse_code: "324",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "433",
                  reverse_code: "334",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "434",
                  reverse_code: "344",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "435",
                  reverse_code: "354",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "530",
                  reverse_code: "305",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "531",
                  reverse_code: "315",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "532",
                  reverse_code: "325",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "533",
                  reverse_code: "335",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "534",
                  reverse_code: "345",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "535",
                  reverse_code: "355",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "43",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "44",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "45",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "043",
                  reverse_code: "430",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "044",
                  reverse_code: "440",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "045",
                  reverse_code: "450",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "143",
                  reverse_code: "431",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "144",
                  reverse_code: "441",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "145",
                  reverse_code: "451",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "243",
                  reverse_code: "432",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "244",
                  reverse_code: "442",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "245",
                  reverse_code: "452",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "340",
                  reverse_code: "403",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "341",
                  reverse_code: "413",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "342",
                  reverse_code: "423",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "343",
                  reverse_code: "433",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "344",
                  reverse_code: "443",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "345",
                  reverse_code: "453",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "440",
                  reverse_code: "404",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "441",
                  reverse_code: "414",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "442",
                  reverse_code: "424",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "443",
                  reverse_code: "434",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "444",
                  reverse_code: "444",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "445",
                  reverse_code: "454",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "540",
                  reverse_code: "405",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "541",
                  reverse_code: "415",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "542",
                  reverse_code: "425",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "543",
                  reverse_code: "435",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "544",
                  reverse_code: "445",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "545",
                  reverse_code: "455",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "53",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "54",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "55",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "053",
                  reverse_code: "530",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "054",
                  reverse_code: "540",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "055",
                  reverse_code: "550",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "153",
                  reverse_code: "531",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "154",
                  reverse_code: "541",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "155",
                  reverse_code: "551",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "253",
                  reverse_code: "532",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "254",
                  reverse_code: "542",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "255",
                  reverse_code: "552",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "350",
                  reverse_code: "503",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "351",
                  reverse_code: "513",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "352",
                  reverse_code: "523",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "353",
                  reverse_code: "533",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "354",
                  reverse_code: "543",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "355",
                  reverse_code: "553",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "450",
                  reverse_code: "504",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "451",
                  reverse_code: "514",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "452",
                  reverse_code: "524",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "453",
                  reverse_code: "534",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "454",
                  reverse_code: "544",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "455",
                  reverse_code: "554",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "550",
                  reverse_code: "505",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "551",
                  reverse_code: "515",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "552",
                  reverse_code: "525",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "553",
                  reverse_code: "535",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "554",
                  reverse_code: "545",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "555",
                  reverse_code: "555",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const fillWristbandPattern54 = {
  size: 96,
  emptySize: 54,
  bgColor: "#197D3E",
  border_color: "#fff",
  class: "three-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const fillWristbandPattern96 = {
  size: 120,
  emptySize: 96,
  bgColor: "#197D3E",
  class: "four-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "03",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "003",
                  reverse_code: "030",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "103",
                  reverse_code: "031",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "203",
                  reverse_code: "032",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "300",
                  reverse_code: "003",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "301",
                  reverse_code: "013",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "302",
                  reverse_code: "023",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "303",
                  reverse_code: "033",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "13",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "013",
                  reverse_code: "130",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "113",
                  reverse_code: "131",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "213",
                  reverse_code: "132",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "310",
                  reverse_code: "103",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "311",
                  reverse_code: "113",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "312",
                  reverse_code: "123",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "313",
                  reverse_code: "133",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "23",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "023",
                  reverse_code: "230",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "123",
                  reverse_code: "231",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "223",
                  reverse_code: "232",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "320",
                  reverse_code: "203",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "321",
                  reverse_code: "213",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "322",
                  reverse_code: "223",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "323",
                  reverse_code: "233",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "33",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "033",
                  reverse_code: "330",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "133",
                  reverse_code: "331",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "233",
                  reverse_code: "332",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "330",
                  reverse_code: "303",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "331",
                  reverse_code: "313",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "332",
                  reverse_code: "323",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "333",
                  reverse_code: "333",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "43",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "043",
                  reverse_code: "430",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "143",
                  reverse_code: "431",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "243",
                  reverse_code: "432",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "340",
                  reverse_code: "403",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "341",
                  reverse_code: "413",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "342",
                  reverse_code: "423",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "343",
                  reverse_code: "433",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "53",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "053",
                  reverse_code: "530",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "153",
                  reverse_code: "531",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "253",
                  reverse_code: "532",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "350",
                  reverse_code: "503",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "351",
                  reverse_code: "513",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "352",
                  reverse_code: "523",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "353",
                  reverse_code: "533",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const fillWristbandPattern150 = {
  size: 216,
  emptySize: 150,
  bgColor: "#197D3E",
  class: "five-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "03",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "04",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "003",
                  reverse_code: "030",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "004",
                  reverse_code: "040",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "103",
                  reverse_code: "031",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "104",
                  reverse_code: "041",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "203",
                  reverse_code: "032",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "204",
                  reverse_code: "042",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "300",
                  reverse_code: "003",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "301",
                  reverse_code: "013",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "302",
                  reverse_code: "023",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "303",
                  reverse_code: "033",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "304",
                  reverse_code: "043",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "400",
                  reverse_code: "004",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "401",
                  reverse_code: "014",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "402",
                  reverse_code: "024",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "403",
                  reverse_code: "034",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "404",
                  reverse_code: "044",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "13",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "14",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "013",
                  reverse_code: "130",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "014",
                  reverse_code: "140",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "113",
                  reverse_code: "131",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "114",
                  reverse_code: "141",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "213",
                  reverse_code: "132",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "214",
                  reverse_code: "142",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "310",
                  reverse_code: "103",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "311",
                  reverse_code: "113",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "312",
                  reverse_code: "123",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "313",
                  reverse_code: "133",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "314",
                  reverse_code: "143",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "410",
                  reverse_code: "104",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "411",
                  reverse_code: "114",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "412",
                  reverse_code: "124",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "413",
                  reverse_code: "134",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "414",
                  reverse_code: "144",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "23",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "24",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "023",
                  reverse_code: "230",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "024",
                  reverse_code: "240",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "123",
                  reverse_code: "231",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "124",
                  reverse_code: "241",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "223",
                  reverse_code: "232",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "224",
                  reverse_code: "242",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "320",
                  reverse_code: "203",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "321",
                  reverse_code: "213",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "322",
                  reverse_code: "223",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "323",
                  reverse_code: "233",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "324",
                  reverse_code: "243",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "420",
                  reverse_code: "204",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "421",
                  reverse_code: "214",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "422",
                  reverse_code: "224",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "423",
                  reverse_code: "234",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "424",
                  reverse_code: "244",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "33",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "34",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "033",
                  reverse_code: "330",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "034",
                  reverse_code: "340",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "133",
                  reverse_code: "331",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "134",
                  reverse_code: "341",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "233",
                  reverse_code: "332",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "234",
                  reverse_code: "342",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "330",
                  reverse_code: "303",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "331",
                  reverse_code: "313",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "332",
                  reverse_code: "323",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "333",
                  reverse_code: "333",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "334",
                  reverse_code: "343",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "430",
                  reverse_code: "304",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "431",
                  reverse_code: "314",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "432",
                  reverse_code: "324",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "433",
                  reverse_code: "334",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "434",
                  reverse_code: "344",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "43",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "44",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "043",
                  reverse_code: "430",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "044",
                  reverse_code: "440",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "143",
                  reverse_code: "431",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "144",
                  reverse_code: "441",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "243",
                  reverse_code: "432",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "244",
                  reverse_code: "442",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "340",
                  reverse_code: "403",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "341",
                  reverse_code: "413",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "342",
                  reverse_code: "423",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "343",
                  reverse_code: "433",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "344",
                  reverse_code: "443",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "440",
                  reverse_code: "404",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "441",
                  reverse_code: "414",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "442",
                  reverse_code: "424",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "443",
                  reverse_code: "434",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "444",
                  reverse_code: "444",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "53",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "54",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "053",
                  reverse_code: "530",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "054",
                  reverse_code: "540",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "153",
                  reverse_code: "531",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "154",
                  reverse_code: "541",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "253",
                  reverse_code: "532",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "254",
                  reverse_code: "542",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "350",
                  reverse_code: "503",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "351",
                  reverse_code: "513",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "352",
                  reverse_code: "523",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "353",
                  reverse_code: "533",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "354",
                  reverse_code: "543",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "450",
                  reverse_code: "504",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "451",
                  reverse_code: "514",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "452",
                  reverse_code: "524",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "453",
                  reverse_code: "534",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "454",
                  reverse_code: "544",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const fillWristbandPattern216 = {
  size: 294,
  emptySize: 216,
  bgColor: "#197D3E",
  class: "six-x",
  gridData: [
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "00",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "01",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "02",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "03",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "04",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "05",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "000",
                  reverse_code: "000",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "001",
                  reverse_code: "010",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "002",
                  reverse_code: "020",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "003",
                  reverse_code: "030",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "004",
                  reverse_code: "040",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "005",
                  reverse_code: "050",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "100",
                  reverse_code: "001",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "101",
                  reverse_code: "011",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "102",
                  reverse_code: "021",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "103",
                  reverse_code: "031",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "104",
                  reverse_code: "041",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "105",
                  reverse_code: "051",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "200",
                  reverse_code: "002",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "201",
                  reverse_code: "012",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "202",
                  reverse_code: "022",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "203",
                  reverse_code: "032",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "204",
                  reverse_code: "042",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "205",
                  reverse_code: "052",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "300",
                  reverse_code: "003",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "301",
                  reverse_code: "013",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "302",
                  reverse_code: "023",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "303",
                  reverse_code: "033",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "304",
                  reverse_code: "043",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "305",
                  reverse_code: "053",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "400",
                  reverse_code: "004",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "401",
                  reverse_code: "014",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "402",
                  reverse_code: "024",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "403",
                  reverse_code: "034",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "404",
                  reverse_code: "044",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "405",
                  reverse_code: "054",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "500",
                  reverse_code: "005",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "501",
                  reverse_code: "015",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "502",
                  reverse_code: "025",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "503",
                  reverse_code: "035",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "504",
                  reverse_code: "045",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "505",
                  reverse_code: "055",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "10",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "11",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "12",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "13",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "14",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "15",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "010",
                  reverse_code: "100",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "011",
                  reverse_code: "110",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "012",
                  reverse_code: "120",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "013",
                  reverse_code: "130",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "014",
                  reverse_code: "140",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "015",
                  reverse_code: "150",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "110",
                  reverse_code: "101",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "111",
                  reverse_code: "111",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "112",
                  reverse_code: "121",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "113",
                  reverse_code: "131",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "114",
                  reverse_code: "141",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "115",
                  reverse_code: "151",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "210",
                  reverse_code: "102",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "211",
                  reverse_code: "112",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "212",
                  reverse_code: "122",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "213",
                  reverse_code: "132",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "214",
                  reverse_code: "142",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "215",
                  reverse_code: "152",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "310",
                  reverse_code: "103",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "311",
                  reverse_code: "113",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "312",
                  reverse_code: "123",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "313",
                  reverse_code: "133",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "314",
                  reverse_code: "143",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "315",
                  reverse_code: "153",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "410",
                  reverse_code: "104",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "411",
                  reverse_code: "114",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "412",
                  reverse_code: "124",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "413",
                  reverse_code: "134",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "414",
                  reverse_code: "144",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "415",
                  reverse_code: "154",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "510",
                  reverse_code: "105",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "511",
                  reverse_code: "115",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "512",
                  reverse_code: "125",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "513",
                  reverse_code: "135",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "514",
                  reverse_code: "145",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "515",
                  reverse_code: "155",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "20",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "21",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "22",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "23",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "24",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "25",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "020",
                  reverse_code: "200",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "021",
                  reverse_code: "210",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "022",
                  reverse_code: "220",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "023",
                  reverse_code: "230",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "024",
                  reverse_code: "240",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "025",
                  reverse_code: "250",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "120",
                  reverse_code: "201",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "121",
                  reverse_code: "211",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "122",
                  reverse_code: "221",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "123",
                  reverse_code: "231",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "124",
                  reverse_code: "241",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "125",
                  reverse_code: "251",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "220",
                  reverse_code: "202",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "221",
                  reverse_code: "212",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "222",
                  reverse_code: "222",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "223",
                  reverse_code: "232",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "224",
                  reverse_code: "242",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "225",
                  reverse_code: "252",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "320",
                  reverse_code: "203",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "321",
                  reverse_code: "213",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "322",
                  reverse_code: "223",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "323",
                  reverse_code: "233",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "324",
                  reverse_code: "243",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "325",
                  reverse_code: "253",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "420",
                  reverse_code: "204",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "421",
                  reverse_code: "214",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "422",
                  reverse_code: "224",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "423",
                  reverse_code: "234",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "424",
                  reverse_code: "244",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "425",
                  reverse_code: "254",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "520",
                  reverse_code: "205",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "521",
                  reverse_code: "215",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "522",
                  reverse_code: "225",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "523",
                  reverse_code: "235",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "524",
                  reverse_code: "245",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "525",
                  reverse_code: "255",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      grid: [
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "30",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "31",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "32",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "33",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "34",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "35",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "030",
                  reverse_code: "300",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "031",
                  reverse_code: "310",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "032",
                  reverse_code: "320",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "033",
                  reverse_code: "330",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "034",
                  reverse_code: "340",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "035",
                  reverse_code: "350",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "130",
                  reverse_code: "301",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "131",
                  reverse_code: "311",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "132",
                  reverse_code: "321",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "133",
                  reverse_code: "331",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "134",
                  reverse_code: "341",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "135",
                  reverse_code: "351",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "230",
                  reverse_code: "302",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "231",
                  reverse_code: "312",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "232",
                  reverse_code: "322",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "233",
                  reverse_code: "332",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "234",
                  reverse_code: "342",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "235",
                  reverse_code: "352",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "330",
                  reverse_code: "303",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "331",
                  reverse_code: "313",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "332",
                  reverse_code: "323",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "333",
                  reverse_code: "333",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "334",
                  reverse_code: "343",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "335",
                  reverse_code: "353",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "430",
                  reverse_code: "304",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "431",
                  reverse_code: "314",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "432",
                  reverse_code: "324",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "433",
                  reverse_code: "334",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "434",
                  reverse_code: "344",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "435",
                  reverse_code: "354",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "530",
                  reverse_code: "305",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "531",
                  reverse_code: "315",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "532",
                  reverse_code: "325",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "533",
                  reverse_code: "335",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "534",
                  reverse_code: "345",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "535",
                  reverse_code: "355",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "40",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "41",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "42",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "43",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "44",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "45",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "040",
                  reverse_code: "400",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "041",
                  reverse_code: "410",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "042",
                  reverse_code: "420",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "043",
                  reverse_code: "430",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "044",
                  reverse_code: "440",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "045",
                  reverse_code: "450",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "140",
                  reverse_code: "401",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "141",
                  reverse_code: "411",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "142",
                  reverse_code: "421",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "143",
                  reverse_code: "431",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "144",
                  reverse_code: "441",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "145",
                  reverse_code: "451",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "240",
                  reverse_code: "402",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "241",
                  reverse_code: "412",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "242",
                  reverse_code: "422",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "243",
                  reverse_code: "432",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "244",
                  reverse_code: "442",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "245",
                  reverse_code: "452",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "340",
                  reverse_code: "403",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "341",
                  reverse_code: "413",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "342",
                  reverse_code: "423",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "343",
                  reverse_code: "433",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "344",
                  reverse_code: "443",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "345",
                  reverse_code: "453",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "440",
                  reverse_code: "404",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "441",
                  reverse_code: "414",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "442",
                  reverse_code: "424",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "443",
                  reverse_code: "434",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "444",
                  reverse_code: "444",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "445",
                  reverse_code: "454",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "540",
                  reverse_code: "405",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "541",
                  reverse_code: "415",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "542",
                  reverse_code: "425",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "543",
                  reverse_code: "435",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "544",
                  reverse_code: "445",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "545",
                  reverse_code: "455",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
        {
          grid: [
            {
              grid: [
                {
                  name: "",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "50",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "51",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "52",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "53",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "54",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "55",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "0",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "050",
                  reverse_code: "500",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "051",
                  reverse_code: "510",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "052",
                  reverse_code: "520",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "053",
                  reverse_code: "530",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "054",
                  reverse_code: "540",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "055",
                  reverse_code: "550",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "1",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "150",
                  reverse_code: "501",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "151",
                  reverse_code: "511",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "152",
                  reverse_code: "521",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "153",
                  reverse_code: "531",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "154",
                  reverse_code: "541",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "155",
                  reverse_code: "551",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "2",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "250",
                  reverse_code: "502",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "251",
                  reverse_code: "512",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "252",
                  reverse_code: "522",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "253",
                  reverse_code: "532",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "254",
                  reverse_code: "542",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "255",
                  reverse_code: "552",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "3",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "350",
                  reverse_code: "503",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "351",
                  reverse_code: "513",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "352",
                  reverse_code: "523",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "353",
                  reverse_code: "533",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "354",
                  reverse_code: "543",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "355",
                  reverse_code: "553",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "4",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "450",
                  reverse_code: "504",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "451",
                  reverse_code: "514",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "452",
                  reverse_code: "524",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "453",
                  reverse_code: "534",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "454",
                  reverse_code: "544",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "455",
                  reverse_code: "554",
                  color: "",
                  zone_name: "",
                },
              ],
            },
            {
              grid: [
                {
                  name: "5",
                  code: "",
                  reverse_code: "",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "550",
                  reverse_code: "505",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "551",
                  reverse_code: "515",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "552",
                  reverse_code: "525",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "553",
                  reverse_code: "535",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "554",
                  reverse_code: "545",
                  color: "",
                  zone_name: "",
                },
                {
                  name: "",
                  code: "555",
                  reverse_code: "555",
                  color: "",
                  zone_name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export {
  emptyWristbandPattern54,
  emptyWristbandPattern96,
  emptyWristbandPattern150,
  emptyWristbandPattern216,
  fillWristbandPattern54,
  fillWristbandPattern96,
  fillWristbandPattern150,
  fillWristbandPattern216,
};
