import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { coachCardLayoutType, wristBandType } from "../../shared/constants";
import { PDFViewer } from "@react-pdf/renderer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function QRConfirmationModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        contentLabel={
          // props.bandType == wristBandType.pitching   ------ for coach card preview of V1 card
          //   ? "coach-card-pitching"
          //   :
          "coach-card-modal"
        }
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">COLLEGE COACH CARD</h2>
          <div className="coach-card-input-div">
            {props.isScaneMode == true ? (
              <></>
            ) : (
              <div>
                <label className="checkbox-container">
                  QR Code
                  <input
                    type="checkbox"
                    checked={props.isShowQR == true ? true : false}
                    onClick={() => {
                      props.setIsShowQR(!props.isShowQR);
                    }}
                  />
                  <span className="checkbox-checkmark"></span>
                </label>
                <small>
                  Check box to add a QR code that allows access to college
                  coaches.
                </small>
              </div>
            )}

            <button
              type="button"
              className="input_login_btn_mrgn"
              onClick={props.downloadPdfFile}
            >
              Download
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
