import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { coachCardLayoutType, wristBandType } from "../../shared/constants";
import { PDFViewer } from "@react-pdf/renderer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function PDFNameModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel={
          // props.bandType == wristBandType.pitching   ------ for coach card preview of V1 card
          //   ? "coach-card-pitching"
          //   :
          "coach-card-modal"
        }
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">COACH CARD</h2>
          <div>
            {/* className="coach-card-main-div"  ------ for coach card preview of V1 card */}
            {/* {props.bandType == wristBandType.pitching && (   ------ for coach card preview of V1 card
              <div className="coach-card-left-div">
                <PDFViewer>{props.children}</PDFViewer>
              </div>
            )} */}
            <div
            // className={                                   ------ for coach card preview of V1 card
            //   props.bandType == wristBandType.pitching
            //     ? "coach-card-right-div"
            //     : ""
            // }
            >
              <form onSubmit={props.downloadPdfFile}>
                <div className="row">
                  <div className="col-md-12">
                    <h6 htmlFor="name">
                      {/* className="h_cls"   ------ for coach card preview of V1 card */}
                      Choose a Layout{" "}
                      <small>
                        (How would you like to read the player card?)
                      </small>
                    </h6>
                  </div>
                </div>
                <div
                  className={
                    "row mt-2" +
                    (props.isScaneMode == true
                      ? "d-flex justify-content-center"
                      : "")
                  }
                >
                  {/* className='mrgn' */}
                  <div className="col-md-4 col-12">
                    <div className="form__item">
                      <div className="custom-radio">
                        <input
                          className="custom-radio__control"
                          type="radio"
                          id={coachCardLayoutType.overThenDown}
                          name="LayoutTypeRadio"
                          onChange={(e) =>
                            props.handleLayoutChange(
                              coachCardLayoutType.overThenDown
                            )
                          }
                          checked={
                            props.layoutType == coachCardLayoutType.overThenDown
                          }
                        />
                        <label
                          className="custom-radio__label"
                          htmlFor={coachCardLayoutType.overThenDown}
                        >
                          Over, Then Down
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="form__item">
                      <div className="custom-radio">
                        <input
                          className="custom-radio__control"
                          type="radio"
                          id={coachCardLayoutType.downThenOver}
                          name="LayoutTypeRadio"
                          onChange={(e) =>
                            props.handleLayoutChange(
                              coachCardLayoutType.downThenOver
                            )
                          }
                          checked={
                            props.layoutType == coachCardLayoutType.downThenOver
                          }
                        />
                        <label
                          className="custom-radio__label"
                          htmlFor={coachCardLayoutType.downThenOver}
                        >
                          Down, Then Over
                        </label>
                      </div>
                    </div>
                  </div>
                  {props.isScaneMode == true ? (
                    <></>
                  ) : (
                    <div className="col-md-4 col-12">
                      <div className="form__item">
                        <div className="custom-radio">
                          <input
                            className="custom-radio__control"
                            type="radio"
                            id={coachCardLayoutType.both}
                            name="LayoutTypeRadio"
                            onChange={(e) =>
                              props.handleLayoutChange(coachCardLayoutType.both)
                            }
                            checked={
                              props.layoutType == coachCardLayoutType.both
                            }
                          />
                          <label
                            className="custom-radio__label"
                            htmlFor={coachCardLayoutType.both}
                          >
                            Both
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="coach-card-input-div">
                  {props.isScaneMode == true ? (
                    <></>
                  ) : (
                    <input
                      type="text"
                      placeholder="ENTER COACH CARD TITLE"
                      className="login_input"
                      value={props.pdfName}
                      onChange={(e) => {
                        props.setPdfName(e.target.value);
                      }}
                      autoFocus
                      required
                    />
                  )}
                  {/* {props.bandType == wristBandType.pitching && (  ------ for coach card preview of V1 card
                    <input
                      type="number"
                      placeholder="ENTER ZONE INITIAL HIFHT"
                      className="login_input"
                      value={props.coachCradInitialHeight}
                      onChange={(e) => {
                        props.setCoachCradInitialHeight(e.target.value);
                      }}
                      required
                    />
                  )} */}

                  {props.isScaneMode == true ? (
                    <></>
                  ) : (
                    <div>
                      <label className="checkbox-container">
                        QR Code
                        <input
                          type="checkbox"
                          checked={props.isShowQR == true ? true : false}
                          onClick={() => {
                            props.setIsShowQR(!props.isShowQR);
                          }}
                        />
                        <span className="checkbox-checkmark"></span>
                      </label>
                      <small>
                        Check box to add a QR code that allows access to college
                        coaches.
                      </small>
                    </div>
                  )}

                  <button type="submit" className="input_login_btn_mrgn">
                    Download
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
