import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {
  edit_icon
  , Ellipse_1
  , Ellipse_2
  , Ellipse_3
  , Ellipse_4
  , Ellipse_5
  , Ellipse_6
  , Ellipse_7
  , Ellipse_8
  , Ellipse_9
  , Ellipse_10
  , Ellipse_11
  , Ellipse_12
  , Ellipse_13
  , read_icon
  , deleteImg
  , Group_24145
} from '../../shared/images';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function DeletesignModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="viewsignsModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 style={{ fontSize: "20px", paddingTop: "20px" }} className="hire_us_head">
            Are you sure, You want to delete this?
          </h2>
          <div className="shuffle_div">
            <button
              className="input_login_btn_mrgn"
              onClick={props.onDelete}
              type="submit"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
