export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const defaultUserId = 1;
export const defaultZoneSizeLink =
  "https://alphasalliance.com/product/alpha-wristbands/";

export const zonesDefaultColor = {
  diable: "#9A999B",
  active: "#6EE0DE",
  border: "#9A999B",
  font: "#FFFFFF",
  activeBorder: "#197d3e",
};
export const UserSubscriptionModes = {
  gamming: "game",
  showCase: "show",
};
export const coachCardLayoutType = {
  overThenDown: "OverThenDown",
  downThenOver: "DownThenOver",
  both: "Both",
};
export const landingBannerBtn = {
  wristbands: "wristbands",
  signs: "signs",
  profile: "profile",
};
export const wristBandType = {
  pitching: "pitching",
  offensive: "offensive",
  defensive: "defensive",
};
export const APP_ERROR_MSGS = {
  StandardErrorMsg: "Something Went wrong. Please try Again.",
  FormValidationMsg: "Please fix the errors and try again.",
  SaveMsg: "Saved successfully",
  DeleteMsg: "Deleted successfully",
  noRecordFound: "No record found.",
  DeleteConfirmMsg: "Are you sure you want to delete this record?",
  NoPermission: "You don't have permission.",
  AlertPermissionDenied: "You don't have permission to access this feature",
};
export const APIUrls = {
  // Auth
  Login: `${BASE_URL}/api/admin/login`,
  GetMe: `${BASE_URL}/api/admin/me`,
  LoadUserDetails: `${BASE_URL}/userDefaultSettings`,
  SaveUserDetails: `${BASE_URL}/userDefaultSettings/store`,
  LoadUserPermissions: `${BASE_URL}/getGroupPermissions`,

  // Signs
  GetSigns: `${BASE_URL}/signs`,
  DeleteSign: `${BASE_URL}/signs`,
  SaveSign: `${BASE_URL}/signs`,
  UpdateSign: `${BASE_URL}/signs`,

  // Wristbands
  GetWristbands: `${BASE_URL}/wristbands`,
  GetWristbandDetail: `${BASE_URL}/wristbands`,
  DeleteWristband: `${BASE_URL}/wristbands`,
  SaveWristband: `${BASE_URL}/wristbands`,
  UpdateWristband: `${BASE_URL}/wristbands`,
  validateQRScannEmail: `${BASE_URL}/sendQrscanEmailVerifyOtp`,
  validateEmailVerificationCode: `${BASE_URL}/verifyQrscanEmailOtp`,
  validateDownloadLimit: `${BASE_URL}/addUserDownload`,
};
export function CreateUserDefaultSignsUrl(id) {
  return `${BASE_URL}/user/${id}/signs/create`;
}

const pixelsInInch = 75; // 80
const getPixels = (inches) => parseFloat((inches * pixelsInInch).toFixed(1));

export const downloadPrintDimensions = [
  {
    val: 0,
    label:
      "4.5 in x 2.75 in (Default Card Size - Guaranteed to Fit Alpha Wristbands)",
    width: getPixels(4.5),
    height: getPixels(2.75),
  },
  {
    val: 1,
    label: "4.7 in x 2.8 in",
    width: getPixels(4.7),
    height: getPixels(2.8),
  },
  {
    val: 2,
    label: "4.5 in x 2.20 in (Default Card Size for Alpha Colored Wristbands)",
    width: getPixels(4.5),
    height: getPixels(2.2),
  },
  {
    val: 3,
    label: "5 in x 3 in",
    width: getPixels(5),
    height: getPixels(3),
  },
  {
    val: 4,
    label: "3.5 in x 2.25 in",
    width: getPixels(3.5),
    height: getPixels(2.25),
  },
  {
    val: 5,
    label: "4.33 in x 2.16 in (Default Card Size for Alpha Youth Wristbands)",
    width: getPixels(4.33),
    height: getPixels(2.16),
  },
];

export const defaultPrintDimensionVal = downloadPrintDimensions[0].val;
export const defaultPrintDimensionWidth = downloadPrintDimensions[0].width;
export const defaultPrintDimensionHeight = downloadPrintDimensions[0].height;

export const zonesColorList = [
  "#92C7DE",
  "#DE9292",
  "#A5DE92",
  "#B092DE",
  "#DEB292",
  "#92CEDE",
  "#DD92DE",
  "#DE92AC",
  "#EBEF57",
  "#EF8257",
  "#57EFB2",
  "#57BDEF",
  "#6357EF",
  "#EF57A5",
  "#BDC2F8",
  "#CCF8BD",
  "#F8BDBD",
  "#CCC9E2",
  "#E2CBC9",
  "#E2C9DF",
  "#E2C9DC",
  "#C9E2D6",
  "#4DB5DF",
  "#FCEC51",
  "#FC5179",
];

export const zonesSizes = [
  {
    val: 16,
    label: "4 x 4 (16 Boxes)",
    x: 4,
    y: 4,
    selectValue: 16,
  },
  {
    val: 20,
    label: "4 x 5 (20 Boxes)",
    x: 4,
    y: 5,
    selectValue: 1,
  },
  {
    val: 24,
    label: "4 x 6 (24 Boxes)",
    x: 4,
    y: 6,
    selectValue: 2,
  },
  {
    val: 28,
    label: "4 x 7 (28 Boxes)",
    x: 4,
    y: 7,
    selectValue: 3,
  },
  {
    val: 32,
    label: "4 x 8 (32 Boxes)",
    x: 4,
    y: 8,
    selectValue: 4,
  },
  //
  {
    val: 20,
    label: "5 x 4 (20 Boxes)",
    x: 5,
    y: 4,
    selectValue: 5,
  },
  {
    val: 25,
    label: "5 x 5 (25 Boxes)",
    x: 5,
    y: 5,
    selectValue: 25,
  },
  {
    val: 30,
    label: "5 x 6 (30 Boxes)",
    x: 5,
    y: 6,
    selectValue: 30,
  },
  {
    val: 35,
    label: "5 x 7 (35 Boxes)",
    x: 5,
    y: 7,
    selectValue: 35,
  },
  {
    val: 40,
    label: "5 x 8 (40 Boxes)",
    x: 5,
    y: 8,
    selectValue: 9,
  },
  //
  {
    val: 24,
    label: "6 x 4 (24 Boxes)",
    x: 6,
    y: 4,
    selectValue: 10,
  },
  {
    val: 30,
    label: "6 x 5 (30 Boxes)",
    x: 6,
    y: 5,
    selectValue: 11,
  },
  {
    val: 36,
    label: "6 x 6 (36 Boxes)",
    x: 6,
    y: 6,
    selectValue: 12,
  },
  {
    val: 42,
    label: "6 x 7 (42 Boxes)",
    x: 6,
    y: 7,
    selectValue: 13,
  },
  {
    val: 48,
    label: "6 x 8 (48 Boxes)",
    x: 6,
    y: 8,
    selectValue: 14,
  },
  //
  {
    val: 28,
    label: "7 x 4 (28 Boxes)",
    x: 7,
    y: 4,
    selectValue: 15,
  },
  {
    val: 35,
    label: "7 x 5 (35 Boxes)",
    x: 7,
    y: 5,
    selectValue: 26,
  },
  {
    val: 42,
    label: "7 x 6 (42 Boxes)",
    x: 7,
    y: 6,
    selectValue: 17,
  },
  {
    val: 49,
    label: "7 x 7 (49 Boxes)",
    x: 7,
    y: 7,
    selectValue: 18,
  },
  {
    val: 56,
    label: "7 x 8 (56 Boxes)",
    x: 7,
    y: 8,
    selectValue: 19,
  },
  //
  {
    val: 32,
    label: "8 x 4 (32 Boxes)",
    x: 8,
    y: 4,
    selectValue: 20,
  },
  {
    val: 40,
    label: "8 x 5 (40 Boxes)",
    x: 8,
    y: 5,
    selectValue: 21,
  },
  {
    val: 48,
    label: "8 x 6 (48 Boxes)",
    x: 8,
    y: 6,
    selectValue: 22,
  },
  {
    val: 56,
    label: "8 x 7 (56 Boxes)",
    x: 8,
    y: 7,
    selectValue: 23,
  },
  {
    val: 64,
    label: "8 x 8 (64 Boxes)",
    x: 8,
    y: 8,
    selectValue: 24,
  },
];

export const gamingZonesColorList = [
  "#fc0303",
  "#f7ae03",
  "#fff703",
  "#03fc03",
  "#6503f7",
  "#ee03ff",
  "#5efffc",
  "#1e12c7",
  "#030303",
  "#a6a6a6",
  "#ffffff",
  "#b05f1c",
];
