import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import SelectZone from "../pages/wristband/SelectZone";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function Re_arrangeModal(props) {
  const isVisible = (item) => {
    let index = item.index.split("");
    let box = props.zonesArray[index[0]][index[1]];
    let tempData = box.data.filter(function (el) {
      return el.label == props?.sign?.abbreviation;
    });
    if (tempData.length > 0) return true;
    else return false;
  };
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="Remove Sign"
        class="customemODAL modal-scroll"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2
            style={{ fontSize: "25px", paddingTop: "20px", margin: "0px" }}
            className="hire_us_head"
          >
            Change the divisions
          </h2>
          <p className="p_description">
            {props?.sign?.name}
            <span className="span_p_description">{props?.sign?.abbreviation}</span>
          </p>

          <div className="p_description_main">
            <div className="p_description_child">
              <p className="p_description-1">
                <span>
                  <span>Total Pitches: {props?.sign?.counts}</span>
                  <br />
                  <span>Your pitches count: {props?.zonesCount?.total}</span>
                  <br />
                  <span>
                    Total Pitches Remaining:{" "}
                    <span
                      className={
                        props?.remainingCount < 0
                          ? "red_color"
                          : props?.remainingCount == 0
                          ? "green_color"
                          : "blue_color"
                        // props?.zonesCount?.total == props?.sign?.counts
                        //   ? "green_color"
                        //   : "red_color"
                      }
                    >
                      {props?.remainingCount}
                    </span>
                  </span>
                </span>
              </p>
            </div>
            <div className="p_description_child">
              <div className="p_description_child-4">
                <SelectZone
                  isVisualZones={true}
                  zoneContainer_label={props?.sign?.abbreviation}
                  {...props.vidsualdndProps}
                  zones={props.zonesArray}
                  handleZoneClick={() => {}}
                  onDragEnd={() => {}}
                  selectedZonesToMerge={[]}
                  isModal={true}
                  signObj={props.signObj ?? null}
                />
              </div>
            </div>
          </div>

          {props?.zonesCount?.list.map(function (item, index) {
            return (
              isVisible(item) && (
                <div className="input_rearange_div">
                  <label className="rearange_zone_label">Zone {item.name}</label>
                  <input
                    type="text"
                    className="rearange_zone_input"
                    value={item.val}
                    onChange={(e) =>
                      props.handleInputChange(e.target.value, index)
                    }
                  />
                </div>
              )
            );
          })}

          <div className="shuffle_div">
            <button
              className="input_login_btn_mrgn"
              id={props.signName}
              onClick={props.handleSave}
              type="button"
              disabled={!(props?.zonesCount?.total == props?.sign?.counts)}
            >
              save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
