import { landingBannerBtn } from "../../shared/constants";

export default function LanidngBanner({ activeTab, handleOnClick }) {
  return (
    <section className="banner">
      <div className="banner_btns">
        <button
          type="button"
          onClick={() => handleOnClick(landingBannerBtn.wristbands)}
          className={
            activeTab == landingBannerBtn.wristbands
              ? "wrist_band_btn"
              : "sign_btn"
          }
        >
          Wristbands
        </button>
        <button
          type="button"
          onClick={() => handleOnClick(landingBannerBtn.signs)}
          className={
            activeTab == landingBannerBtn.signs
              ? "wrist_band_btn"
              : "sign_btn"
          }
        >
          Signs
        </button>
        {/* <button
          type="button"
          onClick={() => handleOnClick(landingBannerBtn.profile)}
          className={
            activeTab == landingBannerBtn.profile
              ? "wrist_band_btn"
              : "sign_btn"
          }
        >
          Profile
        </button> */}
      </div>
    </section>
  );
}
