import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {
  edit_icon,
  Ellipse_1,
  Ellipse_2,
  Ellipse_3,
  Ellipse_4,
  Ellipse_5,
  Ellipse_6,
  Ellipse_7,
  Ellipse_8,
  Ellipse_9,
  Ellipse_10,
  Ellipse_11,
  Ellipse_12,
  Ellipse_13,
  read_icon,
  deleteImg,
  Group_24145,
} from "../../shared/images";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function SignCreateModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="viewsignsModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">Signs</h2>
          <form action>
            <div className="row">
              <div className="col-md-6">
                <label className="label_cls" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="login_input_pswrd"
                  placeholder="Fastball Away"
                />
              </div>
              <div className="col-md-6">
                <label className="label_cls" htmlFor="abbrivattion">
                  Abbreviation
                </label>
                <input
                  type="text"
                  name="abbrivattion"
                  className="login_input_pswrd"
                  placeholder="FBA"
                />
              </div>
            </div>

            <div className="selectColors">
              <h6 className="select_clr_head">Select Color</h6>
              <div className="clr_div">
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_1}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_2}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_3}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_4}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_5}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_6}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_7}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_8}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_9}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_10}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_11}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_12}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a href="#" className="color_link">
                  <img
                    src={Ellipse_13}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <div className="input_div">
                  <input type="color" className="color_picker frColr" />
                  <img
                    src={Group_24145}
                    alt=""
                    className="color_img_picker frz"
                  />
                </div>
              </div>
            </div>
            <div className="btn_save_div">
              <button type="submit" className="input_login_btn">
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
