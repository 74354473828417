import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {
  edit_icon,
  Ellipse_1,
  Ellipse_2,
  Ellipse_3,
  Ellipse_4,
  Ellipse_5,
  Ellipse_6,
  Ellipse_7,
  Ellipse_8,
  Ellipse_9,
  Ellipse_10,
  Ellipse_11,
  Ellipse_12,
  Ellipse_13,
  read_icon,
  deleteImg,
  Group_24145,
} from "../../shared/images";
import { wristBandType, APP_ERROR_MSGS } from "../../shared/constants";
import { getSigns } from "../../actions/signs";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function EditsignsModal(props) {
  const onAbbreviationChange = (e) => {
    const limit = props.data?.category == wristBandType.pitching ? 3 : 6;
    if (e.target.value != " " && e.target.value.toString().length < limit)
      props.handleDataChange("abbreviation", e.target.value);
  };

  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="viewsignsModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">
            {props.data?.id ? "Edit Sign" : "Create Sign"}{" "}
          </h2>
          <form onSubmit={props.onFormSave}>
            {props.isOnlyColor && props.isOnlyColor == true ? (
              ""
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_cls" htmlFor="name">
                      Choose a category
                    </label>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form__item">
                          <div className="custom-radio">
                            <input
                              className="custom-radio__control"
                              type="radio"
                              id={wristBandType.pitching}
                              name="signTypeRadio"
                              onChange={(e) =>
                                props.handleDataChange(
                                  "category",
                                  wristBandType.pitching
                                )
                              }
                              checked={
                                props.data?.category == wristBandType.pitching
                              }
                              disabled={props.isRadioDisabled}
                            />
                            <label
                              className="custom-radio__label"
                              htmlFor={wristBandType.pitching}
                            >
                              {wristBandType.pitching}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form__item">
                          <div className="custom-radio">
                            <input
                              className="custom-radio__control"
                              type="radio"
                              id={wristBandType.offensive}
                              name="signTypeRadio"
                              onChange={(e) =>
                                props.handleDataChange(
                                  "category",
                                  wristBandType.offensive
                                )
                              }
                              checked={
                                props.data?.category == wristBandType.offensive
                              }
                              disabled={props.isRadioDisabled}
                            />
                            <label
                              className="custom-radio__label"
                              htmlFor={wristBandType.offensive}
                            >
                              {wristBandType.offensive}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form__item">
                          <div className="custom-radio">
                            <input
                              className="custom-radio__control"
                              type="radio"
                              id={wristBandType.defensive}
                              name="signTypeRadio"
                              onChange={(e) =>
                                props.handleDataChange(
                                  "category",
                                  wristBandType.defensive
                                )
                              }
                              checked={
                                props.data?.category == wristBandType.defensive
                              }
                              disabled={props.isRadioDisabled}
                            />
                            <label
                              className="custom-radio__label"
                              htmlFor={wristBandType.defensive}
                            >
                              {wristBandType.defensive}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <label className="label_cls" htmlFor="name">
                      Name
                    </label>
                    <input
                      type="text"
                      value={props.data?.name}
                      onChange={(e) => {
                        if (e.target.value != " ")
                          props.handleDataChange("name", e.target.value);
                      }}
                      className="login_input_pswrd"
                      list="signNamesList"
                      required
                    />

                    <datalist id="signNamesList">
                      {props.list?.map((item, index) => {
                        return (
                          <option readonly value=" ">
                            {item.name.toUpperCase()}
                          </option>
                        );
                      })}
                    </datalist>
                  </div>
                  <div className="col-md-6">
                    <label className="label_cls" htmlFor="abbrivattion">
                      Abbreviation
                    </label>

                    <input
                      type="text"
                      className="login_input_pswrd"
                      list="abbrivattionsList"
                      value={props.data?.abbreviation}
                      onChange={onAbbreviationChange}
                      required
                    />

                    <datalist id="abbrivattionsList">
                      {props.filteredList?.map((item, index) => {
                        return (
                          <option readonly value=" ">
                            {item.abbreviation}
                          </option>
                        );
                      })}
                    </datalist>
                    {/* {props.abbreviationExistError && (
                      <span className="modalError-text">
                        *Abbreviation already exists.
                      </span>
                    )} */}

                    {/* <input
                      type="text"
                      value={props.data?.abbreviation}
                      onChange={(e) => {
                        if (e.target.value.toString().length < 3) {
                          props.handleDataChange(
                            "abbreviation",
                            e.target.value
                          );
                        }
                      }}
                      className="login_input_pswrd"
                      required
                    /> */}
                  </div>
                </div>
              </>
            )}

            <div className="selected_clr">
              <h6 className="selected_clr_head">Selected Color</h6>
              <div className="color_img_div">
                <div
                  className="div_clr border-clr-white"
                  style={{ backgroundColor: props.data?.color }}
                ></div>
              </div>

              {props.showOverrideOption && (
                <div className="new">
                  <div className="form-group">
                    <input
                      onChange={props.onOverrideColorChange}
                      type="checkbox"
                      id="color-override-checkbox"
                      checked={props.isZoneColorOverride}
                    />
                    <label htmlFor="color-override-checkbox">
                      Override zones color
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div className="selectColors">
              <h6 className="select_clr_head">Select Color</h6>
              <div className="clr_div">
                <a
                  onClick={(e) => props.handleDataChange("color", "#FF0000")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_1}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#FF6200")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_2}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#6EE0DE")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_3}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#000000")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_4}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#00F83B")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_5}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#BF1B1C")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_6}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#0046FE")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_7}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#FFC800")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_8}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#CFCFCF")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_9}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#007F42")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_10}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#844707")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_11}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#FE5BB7")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_12}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <a
                  onClick={(e) => props.handleDataChange("color", "#5600FD")}
                  className="color_link"
                >
                  <img
                    src={Ellipse_13}
                    alt="Color Image"
                    className="color_img"
                  />
                </a>
                <div className="input_div">
                  <input
                    type="color"
                    className="color_picker frColr"
                    onChange={(e) =>
                      props.handleDataChange("color", e.target.value)
                    }
                  />
                  <img
                    src={Group_24145}
                    alt=""
                    className="color_img_picker frz"
                  />
                </div>
              </div>
            </div>

            <div className="btn_save_div">
              <button type="submit" className="input_login_btn">
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
