import React, { useState, useEffect, useRef } from "react";

function ZonesViewRadio(props) {
  return (
    <div className="row mt-3">
      <div className="col-md-6 col-12">
        <div className="form__item">
          <div className="custom-radio">
            <input
              className="custom-radio__control"
              type="radio"
              id="game-CardsTypeRadio"
              name="CardsTypeRadio"
              onChange={(e) => props.handleOptionChange(false)}
              checked={props.value == false}
            />
            <label
              className="custom-radio__label"
              htmlFor="game-CardsTypeRadio"
            >
              CATCHERS VIEW
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-12">
        <div className="form__item">
          <div className="custom-radio">
            <input
              className="custom-radio__control"
              type="radio"
              id="showCase-CardsTypeRadio"
              name="CardsTypeRadio"
              onChange={(e) => props.handleOptionChange(true)}
              checked={props.value == true}
            />
            <label
              className="custom-radio__label"
              htmlFor="showCase-CardsTypeRadio"
            >
              PITCHERS VIEW
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZonesViewRadio;
