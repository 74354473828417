import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function RemoveSignModel(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="Remove Sign"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 style={{ fontSize: "20px" , paddingTop: "20px" }} className="hire_us_head">
            Are you sure, You want to delete this?
          </h2>
          <div className="shuffle_div">
            <button
              className="input_login_btn_mrgn"
              id={props.signName}
              onClick={(e) => props.removeSign(e.target.id)}
              type="submit"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
