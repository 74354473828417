import React, { useState, useEffect } from "react";
import { Text, Font } from "@react-pdf/renderer";
import {
  calculateRatio,
  //  getGridBoxFontSize
} from "../../../shared/functions";
import { zonesDefaultColor } from "../../../shared/constants";
import fontFamilySrc from "../../../assets/fonts/Roboto/RobotoCondensed-Regular.ttf";

Font.register({
  family: "Roboto",
  src: fontFamilySrc,
  format: "truetype",
});

export default function PrintWistband(props) {
  const [boxWidth, setBoxWidth] = useState(0);
  const [boxHeight, setBoxHeight] = useState(0);
  const [fontSize, setFontSize] = useState(0);

  useEffect(() => {
    setBoxWidth(getBoxWidth());
    // setFontSize(getBoxFontSize());
    setBoxHeight(getBoxheight());
  }, [props.wristbandPattern, props.width, props.height]);

  const getBoxStyle = (
    bgColor,
    borderColor,
    index,
    index1,
    index2,
    index3,
    textFontSize
  ) => ({
    backgroundColor: bgColor,
    borderTop: index == 0 && index1 == 0 ? `1px solid ${borderColor}` : "none",
    borderLeft:
      index2 == 0 && index3 == 0 ? `1px solid ${borderColor}` : "none",
    borderRight: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`,
    fontSize: textFontSize, // fontSize,
    color: "#fff",
    fontWeight: 600,
    // marginRight: 1,
    // marginBottom: 1,
    width: boxWidth,
    height: boxHeight,
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: "Roboto",
  });

  const getHeadingStyle = () => ({
    color: "#9a999b",
    marginBottom: 1,
    fontSize: fontSize + 3,
    fontWeight: 600,
    fontFamily: "Roboto",
    // textAlign: "center",
  });

  const getBoxWidth = () => {
    let boxWidth = 0;
    let matrixSize = props.wristbandPattern.emptySize;
    let boxCount =
      matrixSize == 54
        ? 12
        : matrixSize == 96
        ? 15
        : matrixSize == 150
        ? 18
        : 21;
    boxWidth = props.width / boxCount; //- 1;
    return boxWidth;
  };

  const getBoxheight = () => {
    let boxHeight = 0;
    let showHeading = 0;
    let matrixSize = props.wristbandPattern.emptySize;
    let boxCount =
      matrixSize == 54
        ? 8 + showHeading
        : matrixSize == 96
        ? 10 + showHeading
        : matrixSize == 150
        ? 12 + showHeading
        : 14 + showHeading;
    boxHeight = props.height / boxCount; //- 1;
    return boxHeight;
  };

  const getBoxFontSize = (fontText) => {
    let deductVal = 0;
    let addVal = 0;

    if (props.wristbandPattern.size == 96) {
      addVal = 4.9;
      deductVal = 0.2;
    } else if (props.wristbandPattern.size == 120) {
      addVal = 3.5;
      deductVal = 0.4;
    } else if (props.wristbandPattern.size == 216) {
      addVal = 2.5;
      deductVal = 0.3;
    } else {
      addVal = 1.9;
      deductVal = 0.1;
    }

    const size = +Math.round(getBoxWidth()) / 4;
    return fontText < 4 ? size + addVal : size - deductVal;
  };

  return (
    // <Document>
    //     <Page size="A4" wrap={false}>
    [...Array(props.printCount ? props.printCount : 1)].map(() => {
      return (
        <div
          style={{ height: parseInt(props.height) + 25, width: props.width }}
        >
          {props.wristbandPitcherName != "" &&
            props.wristbandPitcherName != undefined && (
              <div style={{ textAlign: "center" }}>
                <Text
                  style={{
                    fontSize: 10,
                    marginBottom: "5px",
                    fontFamily: "Roboto",
                  }}
                >
                  {props.wristbandPitcherName}
                </Text>
              </div>
            )}
          <div style={{ height: props.height, width: props.width }}>
            {props.wristbandPattern?.gridData?.map(function (item, index1) {
              return (
                <>
                  <div className={props.wristbandPattern.class}>
                    <div style={{ flexDirection: "row" }}>
                      {item.grid.map(function (item, index2) {
                        return (
                          <div key={index2} className="child_div">
                            {item.grid.map(function (item, index3) {
                              return (
                                <div style={{ flexDirection: "row" }}>
                                  {item.grid.map(function (item, index4) {
                                    let text = `${item.name ?? ""}${
                                      item.zone_name ?? ""
                                    }`.length;
                                    // const textFontSize = getGridBoxFontSize(
                                    //   boxWidth,
                                    //   boxHeight,
                                    //   text,
                                    //   props.wristbandPattern.size
                                    // );
                                    const textFontSize = getBoxFontSize(text);

                                    const boxBackgroundColor =
                                      item.overrideColor
                                        ? item.overrideColor
                                        : item.zoneColor &&
                                          item.zoneColor != "" &&
                                          item.zoneColor !=
                                            zonesDefaultColor.diable
                                        ? item.zoneColor
                                        : item.color != ""
                                        ? item.color
                                        : props.wristbandPattern.bgColor;

                                    return (
                                      <div
                                        style={getBoxStyle(
                                          boxBackgroundColor,
                                          props.wristbandPattern.border_color,
                                          index1,
                                          index3,
                                          index2,
                                          index4,
                                          textFontSize
                                        )}
                                        key={index4}
                                      >
                                        <Text
                                          style={{
                                            fontFamily: "Roboto",
                                            color:
                                              calculateRatio(
                                                boxBackgroundColor,
                                                "#ffffff"
                                              ) <
                                              1 / 3
                                                ? "#ffffff"
                                                : "#000000",
                                          }}
                                        >
                                          {item.name}
                                          {item.zone_name}
                                        </Text>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* {index1 == 0 ? (
              <div
                style={{
                  height: boxHeight,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={getHeadingStyle()}>
                  Wristband Software SignsApp
                </Text>
              </div>
            ) : null} */}
                </>
              );
            })}
          </div>
        </div>
      );
    })
    //     </Page>
    // </Document>
  );
}
