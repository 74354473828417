import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import {
  convertToCommaString,
  getReverseIndexes,
  getCoachCardInitialBoxWidth,
  getCoachCardInitialBoxHeight,
  getMergeZoneMarginVal,
  calculateRatio,
  getSelectZoneSizeObj,
  getCurrentRowStatus,
  getColumnRemoveBoxIndexes,
  measureTextWidth,
  getBoxInitialIndexes,
  getRowActiveIndexes,
  getAllActiveZoneIndexes,
} from "../../../shared/functions";
import { wristBandType } from "../../../shared/constants";
import appLogo from "../../../assets/images/Asset_1@4x.png";

const styles = StyleSheet.create({
  CoachCardTitle: {
    textAlign: "center",
    paddingTop: 5,
    fontSize: 15,
    textTransform: "uppercase",
  },
});

function CoachCard(props) {
  const zoneContentFontSize = 9;
  const zoneContentTextLineHight = (zoneContentFontSize / 100) * 130;
  const boxHeaderHeight = 16;
  const dummyContent = "------";
  const coverRightSpace = false;
  const adjustToTop = false;

  const isShowNotes = false; // props.children ? false : true;
  const zonePadding = 2;
  const zonesDetailsObj = getSelectZoneSizeObj(props.matrixType);
  const [splicedSigns, setSplicedSigns] = useState([]);
  const [removedCount, setRemovedCount] = useState(0);
  const [columnRemovedCount, setColumnRemovedCount] = useState(0);
  const [cardHeaderHeight, setCardHeaderHeight] = useState(
    props.bandType == wristBandType.pitching ? 55 : 45
  );
  const [appLogoHeight, setAppLogoHeight] = useState(40);

  // zones area
  const [pitchingZonesContainerHeight, setPitchingZonesContainerHeight] =
    useState(props.children ? 490 : props.isShowQR ? 665 : 685);
  const [zonesContainerHeight, setZonesContainerHeight] = useState(
    props.children ? 500 : props.isShowQR ? 675 : 695
  );
  const [zoneInitialWidth, setZoneInitialWidth] = useState(
    getCoachCardInitialBoxWidth(
      zonesDetailsObj ? zonesDetailsObj.x - columnRemovedCount : 0
    )
  );
  const [zoneInitialHeight, setZoneInitialHeight] = useState(
    getCoachCardInitialBoxHeight(
      zonesDetailsObj ? zonesDetailsObj.x : 0,
      props.bandType == wristBandType.pitching && props.children,
      pitchingZonesContainerHeight,
      props.zones,
      zonePadding,
      isShowNotes
    )
  );
  // zones area

  const [cardFooterHeight, setCardFooterHeight] = useState(
    props.children
      ? 230
      : isShowNotes && props.bandType == wristBandType.pitching
      ? appLogoHeight + zoneInitialHeight * 2
      : props.isShowQR
      ? 70
      : 50
  );
  const [notesHeight, setNotesHeight] = useState(
    appLogoHeight - zoneInitialHeight * 2
  );
  const getBoxWidth = (
    item,
    rowIndex,
    columnIndex,
    splicedSignsList,
    activeZonesIndexes,
    finalInitialWidth
  ) => {
    let widthCount = item.mergedZonesCount;

    // --------- COVER RIGHT EMPTY SPACES --------
    if (coverRightSpace && item.content && item.data.length > 0) {
      const currentRow = splicedSignsList[rowIndex];
      const boxIndex = item.initialId.split("");
      const boxColumnIndex = boxIndex[1];
      const boxIndexesDetails = getBoxInitialIndexes(
        currentRow,
        boxColumnIndex,
        true,
        true,
        splicedSignsList,
        activeZonesIndexes,
        item,
        rowIndex
      );
      const activeCheckIndexes = boxIndexesDetails.coveredIndexes;
      widthCount = activeCheckIndexes.length;
    }

    // calculate return with padding
    if (widthCount == 1) return finalInitialWidth;
    const marginVal = getMergeZoneMarginVal(widthCount);
    return finalInitialWidth * widthCount + zonePadding * marginVal;
  };
  const getBoxHeight = (item, rowIndex, itemWidth) => {
    let hightCount = item.verticalmergedZonesCount;

    if (item.content && item.data.length > 0) {
      // --------- EACH BOX SHOULD TAKE ONLY NEEDED HEIGHT----------------------
      let signRows = 0;
      for (let i = 0; i < item.data.length; i++) {
        const itemContent = convertToCommaString([...item.data[i].arr]);
        let textWidth = measureTextWidth(
          `${dummyContent}${itemContent}`,
          zoneContentFontSize
        );
        if (textWidth > itemWidth) {
          let decimalRowsCount = textWidth / itemWidth;
          const [integerText, decimalText] = decimalRowsCount
            .toString()
            .split(".");

          if (decimalText && decimalText[0] > 8) {
            const textToCalculate = `${dummyContent}${itemContent}`
              .split(" ")
              .join("_");
            textWidth = measureTextWidth(textToCalculate, zoneContentFontSize);
            decimalRowsCount = textWidth / itemWidth;
          }

          signRows += decimalRowsCount.toString().includes(".")
            ? parseInt(decimalRowsCount.toString().split(".")[0]) + 1
            : parseInt(decimalRowsCount);
        } else if (textWidth > 0) {
          signRows += 1;
        }
      }
      return signRows * zoneContentTextLineHight + boxHeaderHeight;

      // --------- COVER EMPTY SPACES BELOW APPROACH----------------------
      // const splicedSignsList = [...splicedSigns];
      // const currentRow = splicedSignsList[rowIndex];
      // const boxIndex = item.initialId.split("");
      // const boxColumnIndex = boxIndex[1];
      // const boxIndexesDetails = getBoxInitialIndexes(
      //   currentRow,
      //   boxColumnIndex,
      //   true
      // );
      // const activeCheckIndexes = boxIndexesDetails.coveredIndexes;

      // const processStartRowIndex = rowIndex + hightCount;
      // for (let i = processStartRowIndex; i < splicedSignsList.length; i++) {
      //   let processRowBoxIndex = 0;
      //   const processRowActiveIndexes = [];
      //   for (let j = 0; j < splicedSignsList[i].length; j++) {
      //     const isActive =
      //       splicedSignsList[i][j].content &&
      //       splicedSignsList[i][j].data.length > 0;
      //     [...Array(splicedSignsList[i][j].mergedZonesCount)].map(() => {
      //       if (isActive) processRowActiveIndexes.push(processRowBoxIndex);
      //       processRowBoxIndex++;
      //     });
      //   }
      //   if (
      //     activeCheckIndexes.some((r) => processRowActiveIndexes.includes(r))
      //   ) {
      //     break;
      //   } else {
      //     hightCount++;
      //   }
      // }
    }

    // calculate return with padding
    if (hightCount == 1) return zoneInitialHeight;
    const marginVal = getMergeZoneMarginVal(hightCount);
    const boxHeightVal =
      zoneInitialHeight * hightCount + zonePadding * marginVal;
    return boxHeightVal;
  };
  const getBoxLeft = (
    item,
    rowIndex,
    columnIndex,
    splicedSignsList,
    finalInitialWidth
  ) => {
    if (columnIndex == 0) return 0;

    let prevBoxCount = 0;
    const currentRow = splicedSignsList[rowIndex];
    currentRow.map((mapItem, index) => {
      if (index < columnIndex) {
        prevBoxCount += mapItem.mergedZonesCount;
      }
    });
    const previousTotalWidth = prevBoxCount * finalInitialWidth;
    const prevPadding = prevBoxCount * zonePadding * 2;
    return previousTotalWidth + prevPadding;
  };
  const getBoxTop = (item, rowIndex, splicedSignsList, activeZonesIndexes) => {
    const rowStatsList = {};
    let totalTopMargin = 0;
    const currentRow = splicedSignsList[rowIndex];
    const boxIndex = item.initialId.split("");
    const boxColumnIndex = boxIndex[1];
    const boxIndexesDetails = getBoxInitialIndexes(
      currentRow,
      boxColumnIndex,
      true,
      true,
      splicedSignsList,
      activeZonesIndexes,
      item,
      rowIndex
    );
    let activeCheckIndexes = boxIndexesDetails.coveredIndexes;

    for (let i = 0; i < rowIndex; i++) {
      const processCurrentRow = splicedSignsList[i];
      for (let j = 0; j < splicedSignsList[i].length; j++) {
        const processItem = splicedSignsList[i][j];
        const currentBoxHeight = processItem.finalRenderHeight;
        const processBoxColumnIndex = processItem.initialId.split("")[1];
        const processBoxIndexesDetails = getBoxInitialIndexes(
          processCurrentRow,
          processBoxColumnIndex,
          true,
          true,
          splicedSignsList,
          activeZonesIndexes,
          processItem,
          i
        );

        processBoxIndexesDetails.coveredIndexes.map((item) => {
          const heightToAdd =
            processItem.content && processItem.data.length > 0
              ? currentBoxHeight
              : 0;

          const tempObj = {
            index: item,
            height: heightToAdd,
            count: heightToAdd > 0 ? 1 : 0,
            top: processItem.finalRenderTop,
          };
          if (rowStatsList[item]) {
            rowStatsList[item].push(tempObj);
          } else {
            rowStatsList[item] = [tempObj];
          }
        });
      }
    }

    if (!adjustToTop)
      activeCheckIndexes = [...Array(zonesDetailsObj.x)].map(
        (item, index) => index
      );

    activeCheckIndexes.map((activeMapItem) => {
      if (rowStatsList[activeMapItem]) {
        rowStatsList[activeMapItem].reverse().map((item) => {
          if (item.count > 0) {
            const tempTopTotalMargin = item.height + item.top;
            if (tempTopTotalMargin > totalTopMargin)
              totalTopMargin = tempTopTotalMargin;
          }
        });
      }
    });

    const paddingToAdd = zonePadding * 2;
    return totalTopMargin + paddingToAdd;
  };
  const getItemStyle = (
    width,
    height,
    item,
    opacity,
    zIndex,
    isHidden,
    top,
    left
  ) => ({
    ...(props.bandType == wristBandType.pitching
      ? {
          height,
          position: "absolute",
          top,
          left,
        }
      : { minHeight: height }),
    width,
    border: `2px solid #363940`,
    marginLeft: `${zonePadding}px`,
    marginRight: `${zonePadding}px`,
    textAlign: "center",
    opacity: isHidden ? 0 : 1, //opacity,
    zIndex,
  });
  const getLayoutLabel = () =>
    props.isReverseIndexes ? "Over, Then Down" : "Down, Then Over";

  const getPitchingSignSection = (
    backgroundColor,
    label,
    content,
    topBorder
  ) => {
    const showCondensed = true;
    // props.isCondensed == undefined ? false : props.isCondensed;
    const fontColor =
      calculateRatio(backgroundColor, "#ffffff") < 1 / 3
        ? "#ffffff"
        : "#000000";

    return showCondensed ? (
      <div
        style={{
          textAlign: "left",
          marginBottom: "2px",
          borderTop: `${topBorder}px solid black`,
          fontSize: zoneContentFontSize,
          position: "relative",
        }}
      >
        <span
          style={{
            width: 20,
            height: 22,
            textAlign: "center",
            display: "inline",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Text
            style={{
              color: fontColor,
              backgroundColor,
              fontWeight: "600",
              textTransform: "uppercase",
              minHeight: 10,
            }}
          >
            {label}
          </Text>
        </span>
        <span
          style={{
            position: "relative",
            zIndex: "9999",
          }}
        >
          <Text>
            {dummyContent}
            {content}
          </Text>
        </span>
      </div>
    ) : (
      <>
        <div
          style={{
            color: fontColor,
            backgroundColor,
            fontSize: "8px",
            fontWeight: "600",
            padding: "2px 0px",
            textTransform: "uppercase",
          }}
        >
          <Text>{label}</Text>
        </div>
        <div
          style={{
            fontSize: 8,
            color: "#363940",
            padding: "2px 0px",
          }}
        >
          <Text>{content}</Text>
        </div>
      </>
    );
  };
  const getPitchingBoxHeaderStyle = (bgColor) => {
    const headerStyle = {
      borderBottom: `2px solid #363940`,
      fontSize: 9,
      fontStyle: "normal",
      color: "#363940",
      marginBottom: 0,
      paddingTop: 2,
      paddingBottom: 2,
      fontWeight: 500,
      textTransform: "uppercase",
    };
    if (bgColor) {
      const textColor =
        calculateRatio(bgColor, "#ffffff") < 1 / 3 ? "#ffffff" : "#000000";
      return {
        ...headerStyle,
        backgroundColor: bgColor,
        color: textColor,
      };
    }
    return headerStyle;
  };
  const removeEmpty = (zonesArray) => {
    // handle empty row remove
    const arr = [];
    const coveredIndexes = [];
    let i = 0;
    while (i < zonesArray.length) {
      let { currentRowActive, currentRowHightCount } = getCurrentRowStatus(
        zonesArray[i]
      );

      if (currentRowActive == true) {
        for (let k = 0; k < currentRowHightCount; k++) {
          const value = i + k;
          if (!coveredIndexes.includes(value)) coveredIndexes.push(value);
        }
      }

      i++;
    }

    coveredIndexes.map((item) => {
      arr.push(zonesArray[item]);
    });

    // handle empty column remove
    const columnSplitArr = [];
    let removeBoxIndexes = [];
    let columnRemoveCount = 0;
    for (let i = 0; i < arr.length; i++) {
      if (i > 0) break;
      let j = 0;
      while (j < arr[i].length) {
        if (arr[i][j].content === "" && arr[i][j].data.length === 0) {
          const columnIndexes = getColumnRemoveBoxIndexes(arr, i, j);
          if (columnIndexes.length > 0) {
            columnRemoveCount++;
            removeBoxIndexes = [...removeBoxIndexes, ...columnIndexes];
          }
        }
        j++;
      }
    }

    removeBoxIndexes.sort(); //.reverse();
    for (let i = 0; i < arr.length; i++) {
      const currentRowToInsert = [];
      for (let j = 0; j < arr[i].length; j++) {
        const ind = `${i}${j}`;
        if (!removeBoxIndexes.includes(ind)) currentRowToInsert.push(arr[i][j]);
      }
      columnSplitArr.push(currentRowToInsert);
    }

    // for (const i in removeBoxIndexes) {
    //   const removeBoxPosition = removeBoxIndexes[i].split("");
    //   arr[removeBoxPosition[0]].splice(removeBoxPosition[1], 1);
    // }

    return {
      spliced: columnSplitArr,
      removed: zonesArray.length - columnSplitArr.length,
      removedColumn: columnRemoveCount,
    };
  };

  useEffect(() => {
    if (props.bandType == wristBandType.pitching) {
      const { spliced, removed, removedColumn } = removeEmpty([...props.zones]);
      let finalInitialWidth = zoneInitialWidth;
      if (removedColumn > 0) {
        setColumnRemovedCount(removedColumn);
        const tempWidth = getCoachCardInitialBoxWidth(
          zonesDetailsObj.x - removedColumn
        );
        setZoneInitialWidth(tempWidth);
        finalInitialWidth = tempWidth;
      }

      const activeZonesIndexes = getAllActiveZoneIndexes(spliced);
      for (let i = 0; i < spliced.length; i++) {
        for (let j = 0; j < spliced[i].length; j++) {
          const item = spliced[i][j];
          spliced[i][j].finalRenderWidth = getBoxWidth(
            item,
            i,
            j,
            spliced,
            activeZonesIndexes,
            finalInitialWidth
          );
          spliced[i][j].finalRenderHeight = getBoxHeight(
            item,
            i,
            spliced[i][j].finalRenderWidth
          );
          spliced[i][j].finalRenderLeft = getBoxLeft(
            item,
            i,
            j,
            spliced,
            finalInitialWidth
          );
          spliced[i][j].finalRenderTop =
            i == 0
              ? 0
              : item.content && item.data.length > 0
              ? getBoxTop(item, i, spliced, activeZonesIndexes)
              : 0;
        }
      }

      setSplicedSigns(spliced);
      setRemovedCount(removed);
      if (
        isShowNotes &&
        removed > 0 &&
        (props.children == null || props.children == undefined)
      ) {
        const splicedHight = zoneInitialHeight * removed;
        setPitchingZonesContainerHeight(
          pitchingZonesContainerHeight - splicedHight
        );
        setCardFooterHeight(cardFooterHeight + splicedHight);
        setNotesHeight(cardFooterHeight + splicedHight);
      }
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        position: "relative",
      }}
    >
      {/* Header */}
      <div style={{ height: cardHeaderHeight }}>
        {props.wristbandPitcherName != "" &&
          props.wristbandPitcherName != undefined && (
            <div style={{ textAlign: "center" }}>
              <Text style={{ fontSize: 10, marginBottom: "4px" }}>
                {props.wristbandPitcherName}
              </Text>
            </div>
          )}

        <div style={styles.CoachCardTitle}>
          <Text>{props.title}</Text>
        </div>
        {props.bandType == wristBandType.pitching ? (
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0px 50px 5px 50px",
            }}
          >
            <Text style={{ fontSize: 15, paddingLeft: 20 }}>
              {props.isMirrorView === true ? "L" : "R"}
            </Text>
            {props.isShowIndexDirection != undefined &&
            props.isShowIndexDirection == false ? (
              <></>
            ) : (
              <Text style={{ fontSize: 8 }}>({getLayoutLabel()})</Text>
            )}
            <Text style={{ fontSize: 15, paddingRight: 20 }}>
              {props.isMirrorView === true ? "R" : "L"}
            </Text>
          </div>
        ) : props.isShowIndexDirection != undefined &&
          props.isShowIndexDirection == false ? (
          <></>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Text style={{ fontSize: 8 }}>({getLayoutLabel()})</Text>
          </div>
        )}
      </div>

      {/* Zones area */}
      {props.bandType == wristBandType.pitching ? (
        <div
          style={{
            position: "relative",
            margin: "0px 10px",
            height:
              isShowNotes &&
              (props.children == null || props.children == undefined)
                ? pitchingZonesContainerHeight - zoneInitialHeight * 2
                : pitchingZonesContainerHeight,
          }}
        >
          {splicedSigns?.map((containerItem, containerIndex) => (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: zoneInitialHeight,
                flexDirection: "row",
                top: 0,
                // top:
                //   containerIndex == 0
                //     ? 0
                //     : containerIndex * zoneInitialHeight +
                //       containerIndex * zonePadding * 2,
              }}
            >
              {containerItem.map((item, index) => {
                return (
                  item.finalRenderWidth > 0 && (
                    <div
                      style={getItemStyle(
                        item.finalRenderWidth,
                        item.finalRenderHeight,
                        item,
                        item.opacity,
                        item.zIndex,
                        item.content && item.data.length > 0 ? false : true,
                        item.finalRenderTop,
                        item.finalRenderLeft
                      )}
                    >
                      <div
                        style={getPitchingBoxHeaderStyle(
                          item.color ? item.color : ""
                        )}
                      >
                        <Text>{item.content ? item.content : "OFF"}</Text>
                      </div>
                      <div>
                        {item.data?.map((signsItem, signsIndex) =>
                          getPitchingSignSection(
                            props.processedSigns[signsItem.label].color,
                            signsItem.label,
                            convertToCommaString(
                              props.isReverseIndexes
                                ? getReverseIndexes([...signsItem.arr])
                                : signsItem.arr
                            ),
                            signsIndex == 0 ? 0 : 1
                          )
                        )}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            padding: "20px 48px 0px 48px",
            height: zonesContainerHeight,
          }}
        >
          {props.selectedSigns?.map((item, index) => {
            const bgColor = props.processedSigns[item.label].color;
            const fontColor =
              calculateRatio(bgColor, "#ffffff") < 1 / 3
                ? "#ffffff"
                : "#000000";
            return (
              <div style={getItemStyle(120, 70, null, 1, 1, false, 0, 0)}>
                <div
                  style={{
                    color: fontColor,
                    backgroundColor: bgColor,
                    fontSize: 8,
                    fontWeight: "600",
                    padding: "2px 0px",
                    textTransform: "uppercase",
                  }}
                >
                  <Text>{props.processedSigns[item.label].name}</Text>
                </div>
                <div
                  style={{
                    fontSize: 7,
                    color: "#363940",
                    padding: "2px 0px",
                  }}
                >
                  <Text>
                    {convertToCommaString(
                      props.isReverseIndexes
                        ? getReverseIndexes([...item.arr])
                        : item.arr
                    )}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* footer */}
      <div style={{ height: cardFooterHeight }}>
        {/* Notes */}
        {isShowNotes &&
          (props.children == null || props.children == undefined) &&
          props.bandType == wristBandType.pitching && (
            <div
              style={{
                border: "2px solid #363940",
                width: props.isShowQR ? "83%" : "98%",
                height: notesHeight,
                marginLeft: 10,
              }}
            >
              <Text>Notes:</Text>
            </div>
          )}

        {/* QR  */}
        {props.isShowQR && (
          <Image
            style={{
              height: 60,
              width: 60,
              position: "absolute",
              bottom: 5,
              right: 20,
            }}
            src={props.QRUrl}
            fixed
          />
        )}

        {/* Grid Table */}
        {props.children}

        {/* App logo */}
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop:
              props.isShowQR &&
              (props.children == null || props.children == undefined)
                ? 20
                : 5,
            height: appLogoHeight,
          }}
        >
          <Image
            src={appLogo}
            style={{
              height: 40,
              width: 120,
              margin: "auto",
              // position: "absolute",
              // bottom: 0,
              // right: "50%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CoachCard;
