// types
import {
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT_USER,
  SAVE_USER_PERMISSIONS,
} from "../actions/types";

import jwtDecode from "jwt-decode";

// initial state
const initialState = {
  token: localStorage.getItem("token"),
  decodedToken: null,
  isAuthenticated: null,
  user: null,
  permissions: [],
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };

    case LOGIN_SUCCESS:
      const decodedToken = jwtDecode(payload.token);
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        decodedToken: decodedToken,
      };

    case LOGOUT_USER:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        decodedToken: null,
        isAuthenticated: null,
        user: null,
        data: null,
      };

    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false,
      };

    case SAVE_USER_PERMISSIONS:
      return {
        ...state,
        permissions: payload && payload != "" ? payload : [],
      };

    default:
      return state;
  }
}
