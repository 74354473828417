import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function PitchingModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="pitchingModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 style={{ fontSize: "25px" }} className="hire_us_head">
            Create WristBand
          </h2>
          <div className="shuffle_div">
            <form onSubmit={props.onFormSubmit}>
              <input type="text" placeholder="ENTER WRISTBAND NAME" className="login_input" value={props.bandName} onChange={(e)=>props.setbandName(e.target.value)} required autoFocus />
              <button type="submit" className="input_login_btn_mrgn">Create WristBand</button>
            </form>

          </div>
        </div>
      </Modal>
    </>
  );
}
