import API from "../shared/API";
import { APIUrls } from "../shared/constants";
import { getParamFromUrl } from "../shared/functions";
import setAuthToken from "../utils/setAuthToken";

export const getWristBands = async (paramString = "") => {
  setAuthToken(localStorage.token);
  return await API.get(`${APIUrls.GetWristbands}${paramString}`);
};

export const deleteWristBand = async (id = "") => {
  setAuthToken(localStorage.token);
  return await API.delete(`${APIUrls.DeleteWristband}/${id}`);
};

export const getWristBandDetail = async (id = "") => {
  setAuthToken(localStorage.token);
  return await API.get(`${APIUrls.GetWristbandDetail}/${id}`);
};

export const getSacnWristBandDetail = async (id = "", param) => {
  setAuthToken(localStorage.token);
  return await API.post(`${APIUrls.GetWristbandDetail}/${id}`, param);
};

export const createWristBand = async (param) => {
  setAuthToken(localStorage.token);
  return await API.post(`${APIUrls.SaveWristband}`, param);
};

export const updateWristBand = async (id, param) => {
  setAuthToken(localStorage.token);
  return await API.patch(`${APIUrls.UpdateWristband}/${id}`, param);
};

export const validateQRScannEmail = async (email) => {
  setAuthToken(localStorage.token);
  return await API.get(`${APIUrls.validateQRScannEmail}/${email}`);
};

export const validateEmailVerificationCode = async (
  email,
  verificationCode
) => {
  setAuthToken(localStorage.token);
  return await API.get(
    `${APIUrls.validateEmailVerificationCode}/${email}/${verificationCode}`
  );
};

export const checkPdfDownloadLimitExceeded = async (count = 1) => {
  const userId = window.localStorage.getItem("userId") ?? getParamFromUrl("id");
  const type = window.localStorage.getItem("mode") ?? getParamFromUrl("mode");

  return await API.get(
    `${APIUrls.validateDownloadLimit}?id=${userId}&type=${type}&count=${count}`
  );
};
