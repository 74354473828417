import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../assets/css/bootstrap.min.css";
import "../assets/fonts/css/all.min.css";
import "../assets/fonts/css/fontawesome.min.css";
import "../assets/css/main.css";
import Wristband from "./wristband";
import Landing from "./pages/landing/landing";
import ScanQRCode from "./pages/ScanQRCode";
import { useSelector, useDispatch } from "react-redux";
import AlertWrapper from "../alertWrapper";
import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions,
} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import LoadingOverlay from "react-loading-overlay";
import { useEffect } from "react";
import { loadUserPermissions } from "../actions/auth";
import { getLandingParams } from "../shared/functions";

const alertOptions = {
  offset: "25px",
  timeout: 4000,
  transition: transitions.SCALE,
};

export default function App() {
  const dispatch = useDispatch();
  let { id, mode } = getLandingParams();
  const isLoading = useSelector((state) => state.loader.isLoading);

  useEffect(() => {
    let foundMode = "";
    if (mode) foundMode = mode;
    else {
      let prevMode = localStorage.getItem("mode");
      if (prevMode) foundMode = prevMode;
    }
    if (foundMode) dispatch(loadUserPermissions(foundMode));
    else {
      console.log("No mode found to fetch permissions");
    }
  }, []);

  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <AlertWrapper />
      <LoadingOverlay
        active={isLoading}
        spinner
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.7)",
          }),
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/dashboard" component={Landing} />
            <Route exact path="/wristband/:type" component={Wristband} />
            <Route exact path="/scanqr/:PdfDetail" component={ScanQRCode} />
          </Switch>
        </Router>
      </LoadingOverlay>
    </AlertProvider>
  );
}
