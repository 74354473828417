import React, { useRef, useEffect } from "react";
var QRCode = require("qrcode.react");

export default function QRGenerator({ type, val, afterQRRender }) {
  useEffect(() => {
    const canvas = document.getElementById(`${type}-${val}-qr`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    afterQRRender(pngUrl, type);
  }, [type, val]);

  return (
    <div style={{ display: "none" }}>
      <QRCode
        id={`${type}-${val}-qr`}
        size={70}
        value={`${process.env.REACT_APP_WEB_URL}scanqr/${type}-${val}`}
      />
    </div>
  );
}
