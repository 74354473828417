import React from "react";
import { zonesDefaultColor } from "../../shared/constants";
import {
  calculateRatio,
  //  getGridBoxFontSize
} from "../../shared/functions";

export default function SelectAndMerge(props) {
  return (
    <>
      {props.zones?.map((containerItem, containerIndex) => (
        <div style={props.getListViewStyle(containerIndex)}>
          {
            // [
            //   ...(props.isMirrorView === true
            //     ? containerItem.reverse()
            //     : containerItem),
            // ]
            containerItem.map((item, index) => {
              const isPopulated = props.checkIsPopulated(item);
              const boxWidth = props.getboxWidth(item);
              const boxHeight = props.getboxHeight(item);
              const boxFont = props.getBoxFontSize(item);
              const boxFontColor =
                props.signObj && props.signObj.isColorOverride
                  ? calculateRatio(props.signObj.color, "#ffffff") < 1 / 3
                    ? "#ffffff"
                    : "#000000"
                  : item.textColor ?? zonesDefaultColor.font;

              const boxText =
                props.isVisualZones &&
                props.isVisualZones == true &&
                isPopulated
                  ? props.isModal == true
                    ? item.content
                    : props.zoneContainer_label + item.content
                  : item.content;

              const boxBorder =
                props.isVisualZones &&
                props.isVisualZones == true &&
                isPopulated
                  ? `2px solid ${zonesDefaultColor.activeBorder}`
                  : props.checkIsSelectedToMerge(item)
                  ? `4px solid ${zonesDefaultColor.activeBorder}`
                  : `1px solid ${item.borderColor ?? zonesDefaultColor.border}`;

              const boxBg = item.isActive
                ? props.signObj && props.signObj.isColorOverride
                  ? props.signObj.color
                  : item.color
                : props.diableColor;

              return (
                <div
                  {...props.getTitle(item)}
                  className="Zone_Container"
                  onClick={() => props.handleZoneClick(item)}
                  style={props.getItemStyle(
                    false,
                    null,
                    boxWidth,
                    boxHeight,
                    boxBg,
                    props.isVisualZones && props.isVisualZones == true
                      ? item.isActive
                        ? "pointer"
                        : "default"
                      : "pointer",
                    item.opacity,
                    item.zIndex,
                    boxFont,
                    null,
                    boxText.toString().length,
                    boxFontColor,
                    boxBorder
                  )}
                >
                  {boxText}
                </div>
              );
            })
          }
        </div>
      ))}
    </>
  );
}
