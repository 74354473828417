import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function EmailConfirmationModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="ZoneNameModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">
            {props.verificationStep === "email"
              ? "Enter Email"
              : "ENTER VERIFICATION CODE"}
          </h2>
          {props.verificationStep === "email" ? (
            <p style={{ textTransform: "none", textAlign: "center" }}>
              Please enter your .edu email below. You must verify this email to
              gain access to this page.
            </p>
          ) : (
            <p style={{ textTransform: "none", textAlign: "center" }}>
              The Verification code has been sent to your email. Please confirm
              and enter the code to view this page.
            </p>
          )}
          <div className="shuffle_div">
            <form onSubmit={props.onSubmit}>
              {props.verificationStep === "email" && (
                <input
                  type="email"
                  placeholder="ENTER YOUR EMAIL"
                  className="login_input"
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  autoFocus
                  required
                />
              )}
              {props.verificationStep === "code" && (
                <input
                  type="text"
                  placeholder="ENTER VERIFICATION CODE"
                  className="login_input"
                  value={props.code}
                  onChange={(e) => props.setCode(e.target.value)}
                  required
                />
              )}
              {props.verificationStep === "code" && (
                <div>
                  <span
                    onClick={props.resendVerificationCode}
                    className="resend_code_link"
                  >
                    Resend Code
                  </span>
                </div>
              )}
              <button type="submit" className="input_login_btn_mrgn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
