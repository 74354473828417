import {
  edit_icon,
  Ellipse_1,
  Ellipse_2,
  Ellipse_3,
  Ellipse_4,
  Ellipse_5,
  Ellipse_6,
  Ellipse_7,
  Ellipse_8,
  Ellipse_9,
  Ellipse_10,
  Ellipse_11,
  Ellipse_12,
  Ellipse_13,
  read_icon,
  deleteImg,
  Group_24145,
} from "../../../shared/images";
import React, { useState, useEffect } from "react";
import {
  getSigns,
  deleteSign,
  updateSign,
  createSign,
} from "../../../actions/signs";

import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_LOADING } from "../../../actions/types";
import { APP_ERROR_MSGS, wristBandType } from "../../../shared/constants";
import Table from "../../../shared/tableRendrer";
import SignCreateModal from "../../Modals/SignCreateModal";
import ViewsignsModal from "../../Modals/ViewsignsModal";
import EditsignsModal from "../../Modals/EditsignsModal";
import DeletesignModal from "../../Modals/DeletesignModal";
import {
  checkIfAbbreviationExists,
  filterListByAttribute,
} from "../../../shared/functions";
import DuplicateAbbreviationAlertModal from "../../Modals/DuplicateAbbreviationAlertModal";

export default function SignsSection() {
  const defaultUserId = localStorage.getItem("userId");
  const alert = useAlert();
  const dispatch = useDispatch();
  const [showSignModal, setShowSignModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tableProps, setTableProps] = useState({
    data: [],
    page: 1,
    sizePerPage: 15,
    totalSize: 0,
    loading: false,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [list, setList] = useState([]);
  const [filterlist, setfilterlist] = useState([]);
  const [selectedRowAbbreviation, setSelectedRowAbbreviation] = useState(null);
  const [selectedRowCategory, setSelectedRowCategory] = useState(null);
  const [abbreviationExistError, setAbbreviationExistError] = useState(false);

  const CellMenu = (cell, row) => {
    return (
      <>
        <a
          onClick={() => handleTableActionClick(row, "view")}
          className="icons_link"
        >
          <img src={read_icon} alt="Read Icon" className="icon_img" />
        </a>
        <a
          onClick={() => handleTableActionClick(row, "edit")}
          className="icons_link mx-3"
        >
          <img src={edit_icon} alt="Read Icon" className="icon_img" />
        </a>
        <a
          onClick={() => handleTableActionClick(row, "delete")}
          className="icons_link"
        >
          <img src={deleteImg} alt="Read Icon" className="icon_img" />
        </a>
      </>
    );
  };
  const columns = [
    {
      dataField: "id",
      text: "Id",
      headerStyle: { width: "50px" },
    },
    {
      dataField: "category",
      text: "Category",
      headerStyle: { width: "150px" },
    },
    {
      dataField: "abbreviation",
      text: "Abbreviation",
      headerStyle: { width: "150px" },
    },
    {
      dataField: "name",
      text: "Name",
      headerStyle: { width: "150px" },
    },
    {
      dataField: "color",
      text: "Color",
      formatter: (cell, row) => {
        return (
          <div style={{ backgroundColor: cell }} className="color_div_tb"></div>
        );
      },
      headerStyle: { width: "155px" },
    },
    {
      dataField: "",
      text: "Actions",
      formatter: (cell, row) => {
        return CellMenu(cell, row);
      },
      csvExport: false,
      headerStyle: { width: "150px" },
    },
  ];
  const handleTableActionClick = (row, type) => {
    setSelectedRow(row);
    setSelectedRowAbbreviation(row.abbreviation);
    setSelectedRowCategory(row.category);
    if (type == "view") return setShowViewModal(true);
    if (type == "edit") {
      setfilterlist(filterListByAttribute(list, "category", row.category));
      setAbbreviationExistError(false);
      setShowEditModal(true);
      return;
    }
    if (type == "delete") return setShowDeleteModal(true);
  };
  const createNewRecord = () => {
    setSelectedRow({
      id: "",
      name: "",
      abbreviation: "",
      color: "#FF0000",
      category: wristBandType.pitching,
    });
    setfilterlist(
      filterListByAttribute(list, "category", wristBandType.pitching)
    );
    setAbbreviationExistError(false);
    setShowEditModal(true);
  };
  const handleTableChange = (type, { page, sizePerPage }) => {
    setTableProps({ ...tableProps, page, sizePerPage });
  };
  const populateTable = async () => {
    setTableProps({ ...tableProps, loading: true, data: [] });
    let paramString = `?page=${tableProps.page}&limit=${tableProps.sizePerPage}&user_id=${defaultUserId}`;
    getSigns(paramString)
      .then((result) => {
        setTableProps({ ...tableProps, loading: false });
        if (result.data.status == true) {
          setTableProps({
            ...tableProps,
            data: result.data.data.data ? result.data.data.data : [],
            totalSize: result.data.data.total,
          });
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        setTableProps({ ...tableProps, loading: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const deleteRecord = async () => {
    setShowDeleteModal(false);
    dispatch({ type: UPDATE_LOADING, payload: true });
    deleteSign(selectedRow.id)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          alert.success(APP_ERROR_MSGS.DeleteMsg);
          populateTable();
          populateAbbriviations();
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const handleRecordSubmit = async (e) => {
    e.preventDefault();
    if (selectedRow.id) {
      updateRecord();
    } else {
      saveRecord();
    }
  };
  const getParam = () => {
    let param = {
      name: selectedRow.name,
      abbreviation: selectedRow.abbreviation,
      category: selectedRow.category,
      color: selectedRow.color,
      user_id: defaultUserId,
    };
    return param;
  };
  const saveRecord = () => {
    // if (checkIfAbbreviationExists(filterlist, selectedRow.abbreviation)) {
    //   setShowEditModal(false);
    //   setAbbreviationExistError(true);
    //   return;
    // }

    setAbbreviationExistError(false);
    setShowEditModal(false);
    dispatch({ type: UPDATE_LOADING, payload: true });
    createSign(getParam())
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          alert.success(APP_ERROR_MSGS.SaveMsg);
          populateTable();
          populateAbbriviations();
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const updateRecord = () => {
    // if (
    //   (selectedRow.category === selectedRowCategory &&
    //     selectedRow.abbreviation.toLowerCase() !=
    //       selectedRowAbbreviation.toLowerCase() &&
    //     checkIfAbbreviationExists(filterlist, selectedRow.abbreviation)) ||
    //   (selectedRow.category != selectedRowCategory &&
    //     checkIfAbbreviationExists(filterlist, selectedRow.abbreviation))
    // ) {
    //   setAbbreviationExistError(true);
    //   setShowEditModal(false);
    //   return;
    // }

    setAbbreviationExistError(false);
    setShowEditModal(false);
    dispatch({ type: UPDATE_LOADING, payload: true });
    updateSign(selectedRow.id, getParam())
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          alert.success(APP_ERROR_MSGS.SaveMsg);
          populateTable();
          populateAbbriviations();
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const handleSelectedDataChange = (field, val) => {
    if (field === "category") {
      setfilterlist(filterListByAttribute(list, "category", val));
      setSelectedRow({
        ...selectedRow,
        abbreviation: "",
        category: val,
      });
    } else {
      setSelectedRow({ ...selectedRow, [field]: val });
    }
  };
  useEffect(() => {
    populateTable();
  }, [tableProps.page, tableProps.sizePerPage]);

  const populateAbbriviations = async () => {
    // setTableProps({ ...tableProps, loading: true, data: [] });
    let paramString = `?user_id=${defaultUserId}`;
    getSigns(paramString)
      .then((result) => {
        if (result.data.status == true) {
          setList(result.data.data ? result.data.data : []);
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        // setTableProps({ ...tableProps, loading: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  useEffect(() => {
    populateAbbriviations();
  }, []);

  return (
    <>
      {/* Page Modal */}

      <DuplicateAbbreviationAlertModal
        show={abbreviationExistError}
        closeModal={() => {
          setAbbreviationExistError(false);
          setShowEditModal(true);
        }}
      />

      <SignCreateModal
        show={showSignModal}
        closeModal={() => setShowSignModal(false)}
      />
      <ViewsignsModal
        show={showViewModal}
        data={selectedRow}
        closeModal={() => setShowViewModal(false)}
      />
      <EditsignsModal
        isRadioDisabled={false}
        show={showEditModal}
        data={selectedRow}
        handleDataChange={handleSelectedDataChange}
        onFormSave={handleRecordSubmit}
        closeModal={() => setShowEditModal(false)}
        list={list}
        filteredList={filterlist}
        abbreviationExistError={abbreviationExistError}
      />
      <DeletesignModal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onDelete={deleteRecord}
      />

      <section className="table_section">
        <div className="container">
          <div className="main-head-div">
            <div className="head_div">
              <h2 className="heading_wrist">Listings of Wristbands</h2>
            </div>
            <div className="listing_btn_div">
              <button
                type="button"
                className="btn_list"
                onClick={createNewRecord}
              >
                Create New
              </button>
            </div>
          </div>

          <div className="col-12 p-0 pt-5">
            <Table
              {...tableProps}
              onTableChange={handleTableChange}
              columns={columns}
            />
          </div>
        </div>
      </section>
    </>
  );
}
