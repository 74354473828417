import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { coachCardLayoutType, wristBandType } from "../../shared/constants";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function PitcherCardOverViewModal(props) {
  return (
    <>
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel={"coach-card-pitching"}
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">Pitcher CARD</h2>
          <div className="coach-card-main-div">
            <div className="coach-card-left-div">
              <PDFViewer>{props.children}</PDFViewer>
            </div>
            <div className="coach-card-right-div">
              {/* <form onSubmit={props.downloadPdfFile}> */}
              <div className="coach-card-input-div">
                <input
                  type="number"
                  placeholder="PDF HIFHT"
                  className="login_input"
                  value={props.height}
                  onChange={(e) => {
                    if (e.target.value <= 400)
                      props.handleHeightChange(e.target.value);
                  }}
                  max={400}
                />
                <div className="Modal-Info-desc">
                  Height must be less than or equal to 400 (5 in)
                </div>
                <PDFDownloadLink
                  document={props.children}
                  fileName={`Pitcher_card.pdf`}
                >
                  {({ blob, url, loading, error }) => (
                    <button
                      type="button"
                      className="input_login_btn_mrgn disable_Class my-4"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Download"}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
