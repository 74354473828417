import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

export default function ShuffleModal(props) {
    return (
        <>
            {/* <button onClick={openModal}>Open Modal</button> */}
            <Modal
                isOpen={props.show}
                // onAfterOpen={afterOpenModal}
                onRequestClose={props.closeModal}
                style={customStyles}
                contentLabel="ShuffleModal"
                class="customemODAL"
            >
                <button type="button" className="close" onClick={props.closeModal}>
                    <i className="fas fa-times"></i>
                </button>
                <div className="modal_content">
                    <h2 className="hire_us_head">Wristband Name</h2>
                    <div className="shuffle_div">
                        <form onSubmit={props.shuffelWristband}>
                            <input required autoFocus value={props.shuffelWristbandName} onChange={(e)=>props.onNameChange(e.target.value)} type="text" placeholder="ENTER WRISTBAND NAME" className="login_input" />
                            <button type="submit" className="input_login_btn_mrgn">Save</button>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
}