import React, { useState, useEffect } from "react";
import PdfWrapper from "./PdfWrapper";
import PrintWistband from "./print_wistband";
import CoachCard from "./CoachCard";

export default function CollegeCard(props) {
  return (
    <PdfWrapper>
      <CoachCard
        title="College Coach Card"
        isShowIndexDirection={true}
        zones={props.zones}
        matrixType={props.matrixType}
        processedSigns={props.processedSigns}
        bandType={props.bandType}
        selectedSigns={props.selectedSigns}
        isReverseIndexes={true}
        isCondensed={true}
        QRUrl={props.QRUrl}
        isShowQR={props.isShowQR}
        wristbandPitcherName={props.wristbandPitcherName}
        isMirrorView={props.isMirrorView}
        fitToScreen={true}
      >
        <div style={{ marginHorizontal: "auto", marginVertical: "10px" }}>
          <PrintWistband
            height={176}
            width={360}
            wristbandPattern={props.wristbandPattern}
          />
        </div>
      </CoachCard>
    </PdfWrapper>
  );
}
