import API from "../shared/API";
import { APIUrls, CreateUserDefaultSignsUrl } from "../shared/constants";
import setAuthToken from "../utils/setAuthToken";

export const CreateUserDefaultSigns = async (id, param = "") => {
  setAuthToken(localStorage.token);
  return await API.post(`${CreateUserDefaultSignsUrl(id)}`, param);
};

export const getSigns = async (paramString = "") => {
  setAuthToken(localStorage.token);
  return await API.get(`${APIUrls.GetSigns}${paramString}`);
};

export const deleteSign = async (id = "") => {
  setAuthToken(localStorage.token);
  return await API.delete(`${APIUrls.DeleteSign}/${id}`);
};

// export const getSignDetail = async (id='') => {
//     setAuthToken(localStorage.token);
//     return await API.get(`${APIUrls.GetSignDetail}${id}`);
// }

export const createSign = async (param) => {
  setAuthToken(localStorage.token);
  return await API.post(`${APIUrls.SaveSign}`, param);
};

export const updateSign = async (id, param) => {
  setAuthToken(localStorage.token);
  return await API.patch(`${APIUrls.UpdateSign}/${id}`, param);
};
