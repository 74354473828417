import React from "react";
// import DragAndMerge from "../../ZonesMerging/DragAndMerge";
import SelectAndMerge from "../../ZonesMerging/SelectAndMerge";
import {
  getPopulatedCount,
  getMergeZoneMarginVal,
} from "../../../shared/functions";

export default function SelectZone(props) {
  const zoneHeaderHeight = 30;

  const getBasicBoxStyle = (
    isDragging,
    width,
    height,
    color,
    cursor,
    opacity,
    zIndex,
    fontSize,
    text,
    fontColor,
    boxBorder
  ) => ({
    userSelect: "none",
    width,
    height,
    margin: `${props.zonePadding}px`,
    background: isDragging ? "red" : color,
    color: fontColor,
    zIndex,
    overflowY: "hidden",
    cursor,
    border: boxBorder,
    fontSize:
      props.isVisualZones && props.isVisualZones == true
        ? text > 3
          ? text == 4
            ? props.fontSize - 1
            : props.fontSize - 3
          : props.fontSize
        : props.fontSize,
    // fontSize:  props.isVisualZones && props.isVisualZones == true
    //     ? props.fontSize - 7
    //     : props.fontSize,
    // fontSize:
    //   props.isVisualZones && props.isVisualZones == true
    //     ? text > 3
    //       ? fontSize / 2 + 1
    //       : fontSize
    //     : fontSize,
    // opacity: opacity,
  });
  function getItemStyle(
    isDragging,
    draggableStyle,
    width,
    height,
    color,
    cursor,
    opacity,
    zIndex,
    fontSize,
    snapshot,
    text,
    fontColor,
    boxBorder
  ) {
    const calculatedFontSize = fontSize >= height - 5 ? height - 10 : fontSize;
    const styles = getBasicBoxStyle(
      isDragging,
      width,
      height,
      color,
      cursor,
      opacity,
      zIndex,
      calculatedFontSize,
      text,
      fontColor,
      boxBorder
    );

    if (!isDragging) return styles;
    if (!snapshot?.isDropAnimating) {
      return {
        ...styles,
        ...draggableStyle,
      };
    }
    return {
      ...styles,
      ...draggableStyle,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  }
  const getListMergeStyle = (isDraggingOver, index) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: "flex",
    overflow: "auto",
    position: "absolute",
    top:
      index == 0
        ? 0 + zoneHeaderHeight
        : index * props.zoneInitialHeight +
          index * props.zonePadding * 2 +
          zoneHeaderHeight,
  });
  const getListViewStyle = (index) => ({
    display: "flex",
    overflow: "auto",
    position: "absolute",
    top:
      index == 0
        ? 0 + zoneHeaderHeight
        : index * props.zoneInitialHeight +
          index * props.zonePadding * 2 +
          zoneHeaderHeight,
  });
  const getboxWidth = (item) => {
    if (item.mergedZonesCount == 1) return props.zoneInitialWidth;
    let marginval = getMergeZoneMarginVal(item.mergedZonesCount);
    return (
      props.zoneInitialWidth * item.mergedZonesCount +
      props.zonePadding * marginval
    );
  };
  const getboxHeight = (item) => {
    if (item.verticalmergedZonesCount == 1) return props.zoneInitialHeight;
    let marginval = getMergeZoneMarginVal(item.verticalmergedZonesCount);
    return (
      props.zoneInitialHeight * item.verticalmergedZonesCount +
      props.zonePadding * marginval
    );
  };
  const getBoxFontSize = (item) => {
    let size = +Math.round(getboxWidth(item)) / 3;
    return size - 2;
  };
  const checkIsPopulated = (box) => {
    let tempData = box.data.filter(function (el) {
      return el.label == props.zoneContainer_label;
    });
    if (tempData.length > 0) return true;
    else return false;
  };
  const getTitle = (item) => {
    return props.isVisualZones && props.isVisualZones == true && item.isActive
      ? { title: getPopulatedCount(item, props.zoneContainer_label) }
      : { title: "" };
  };
  const getContainerHeight = () => {
    return (
      props.zoneInitialHeight * props.zones?.length +
      props.zones?.length * props.zonePadding * 2
    );
  };
  const getContainerStyle = () => {
    const styles = {
      position: "relative",
      overflowX: "overlay",
      minHeight: getContainerHeight() + zoneHeaderHeight,
      // minWidth: 200
    };
    return props.isModal && props.isModal == true
      ? {
          ...styles,
          display: "flex",
          justifyContent: "flex-end",
        }
      : styles;
  };
  const onDragUpdate = (result) => {
    let list = document.getElementsByClassName("Zone_Container");
    let current = null;
    for (let i = 0; i < list.length; i++) {
      if (
        list[i].attributes["data-rbd-draggable-id"].nodeValue ===
        result.draggableId
      ) {
        current = list[i];
        break;
      }
    }
    if (result.combine == null) {
      current.style.backgroundColor = "red";
    } else {
      current.style.backgroundColor = "green";
    }
  };
  const checkIsSelectedToMerge = (item) => {
    let selectedZonesToMerge = [...props.selectedZonesToMerge];
    let isSelected = false;
    for (let i in selectedZonesToMerge) {
      if (selectedZonesToMerge[i].id == item.id) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };

  return (
    <div style={getContainerStyle()}>
      {props.isModal == false ? (
        <div className="float-div">
          <p className="float-left-p">{props.isMirrorView ? "L" : "R"}</p>
          <p className="float-left-p">{props.isMirrorView ? "R" : "L"}</p>
        </div>
      ) : (
        <></>
      )}

      {/* <DragAndMerge
        zones={props.zones}
        handleZoneClick={props.handleZoneClick}
        onDragEnd={props.onDragEnd}
        onDragUpdate={onDragUpdate}
        getListMergeStyle={getListMergeStyle}
        getListViewStyle={getListViewStyle}
        getTitle={getTitle}
        checkIsPopulated={checkIsPopulated}
        getItemStyle={getItemStyle}
        getboxWidth={getboxWidth}
        getboxHeight={getboxHeight}
        getBoxFontSize={getBoxFontSize}
        diableColor={props.diableColor}
        activeColor={props.activeColor}
        isDragDisabled={props.isDragDisabled}
        isVisualZones={props.isVisualZones}
        zoneContainer_label={props.zoneContainer_label}
        isModal={props.isModal ? props.isModal : false}
      /> */}

      <SelectAndMerge
        zones={props.zones}
        handleZoneClick={props.handleZoneClick}
        onDragEnd={props.onDragEnd}
        onDragUpdate={onDragUpdate}
        getListMergeStyle={getListMergeStyle}
        getListViewStyle={getListViewStyle}
        getTitle={getTitle}
        checkIsPopulated={checkIsPopulated}
        getItemStyle={getItemStyle}
        getboxWidth={getboxWidth}
        getboxHeight={getboxHeight}
        getBoxFontSize={getBoxFontSize}
        diableColor={props.diableColor}
        activeColor={props.activeColor}
        isDragDisabled={props.isDragDisabled}
        isVisualZones={props.isVisualZones}
        zoneContainer_label={props.zoneContainer_label}
        checkIsSelectedToMerge={checkIsSelectedToMerge}
        isModal={props.isModal ? props.isModal : false}
        isMirrorView={props.isMirrorView}
        signObj={props.signObj ?? null}
      />
    </div>
  );
}
