import { logo } from "../../shared/images";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <></>
    // <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top top_nav">
    //   <div className="container">
    //     <Link to="/dashboard" className="navbar-brand">
    //       <img src={logo} alt="Alphas Logo" className="logo_img" />
    //     </Link>
    //     <button
    //       className="navbar-toggler"
    //       type="button"
    //       data-toggle="collapse"
    //       data-target="#navbarNavAltMarkup"
    //       aria-controls="navbarNavAltMarkup"
    //       aria-expanded="false"
    //       aria-label="Toggle navigation"
    //     >
    //       <span className="navbar-toggler-icon" />
    //     </button>

    //     <div
    //       className="collapse navbar-collapse justify-content-center"
    //       id="navbarNavAltMarkup"
    //     >
    //       <div className="navbar-nav justify-content-center">
    //         <a className="nav-item nav-link" href="#">
    //           Wristbands <span className="sr-only">(current)</span>
    //         </a>
    //         <a className="nav-item nav-link" href="#">
    //           Signs
    //         </a>
    //         <a className="nav-item nav-link active" href="#">
    //           Profile
    //         </a>
    //       </div>
    //     </div>

    //     <div className="d-flex">
    //       <div className="btn_login">
    //         <button type="button" className="login_btn">
    //           Login
    //         </button>
    //       </div>
    //       <div className="btn_signup">
    //         <button type="button" className="signup_btn">
    //           Sign Up
    //         </button>
    //       </div>
    //     </div>

    //   </div>
    // </nav>
  );
}

export default Navbar;
