import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {
  edit_icon
  , Ellipse_1
  , Ellipse_2
  , Ellipse_3
  , Ellipse_4
  , Ellipse_5
  , Ellipse_6
  , Ellipse_7
  , Ellipse_8
  , Ellipse_9
  , Ellipse_10
  , Ellipse_11
  , Ellipse_12
  , Ellipse_13
  , read_icon
  , deleteImg
  , Group_24145
} from '../../shared/images';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function ViewsignsModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="viewsignsModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">View Sign</h2>
          <form action>
            <div className="row">
              <div className="col-md-6">
                <h6 className="view_name_head">
                  Name
                                        </h6>
                <div className="View_name_selected">
                  {props.data?.name}
                </div>
              </div>
              <div className="col-md-6">
                <h6 className="view_name_head">Abbreviation</h6>
                <div className="View_name_selected">
                  {props.data?.abbreviation}
                </div>
              </div>
            </div>
            <div className="selected_clr">
              <h6 className="selected_clr_head">
                Selected Color
                                        </h6>
              <div className="color_img_div">
                <div className="div_clr" style={{backgroundColor:props.data?.color}}>
                  
                </div>
              </div>
            </div>
            <div className="btn_save_div">
              <button type="button" className="input_login_btn" onClick={props.closeModal}>Close</button>
            </div>
          </form>


        </div>
      </Modal>
    </>
  );
}
