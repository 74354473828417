import { zonesSizes } from "./constants";

export function convertToCommaString(array) {
  var temp = "";
  if (array == undefined) return temp;
  for (var i = 0; i < array.length; i++) {
    temp += ", " + array[i];
  }
  return temp.substr(1);
}

export function ProcessDataInArray(lst, fieldName = "Id") {
  const tempLst = [];
  for (const i in lst) {
    tempLst[lst[i][fieldName]] = lst[i];
  }
  return tempLst;
}

export const getWristbandPopulatedData = (
  filledWristbandPattern,
  signs,
  signsAppendedIterations
) => {
  const updatedWristbandPattern = [];
  const formattedData = [];
  const formattedData2 = [];
  const lengthEmptySigns = filledWristbandPattern.emptySize;
  const lengthSigns = signs.length;
  const gridAppendedIterations = [{}];
  let boxCount = 0;

  for (let i = 0; i < lengthEmptySigns; i++) {
    var abbreviation = "";
    var color = "";
    for (;;) {
      const randomRow = Math.floor(Math.random() * lengthSigns - 1 + 1);
      abbreviation = signs[randomRow].abbreviation;
      color = signs[randomRow].color;
      if (signs[randomRow].counts > signsAppendedIterations[abbreviation]) {
        abbreviation = abbreviation;
        color = color;
        signsAppendedIterations[abbreviation] =
          signsAppendedIterations[abbreviation] + 1;

        for (;;) {
          const randomIndex = Math.floor(
            Math.random() * lengthEmptySigns - 1 + 1
          );
          if (!(randomIndex in gridAppendedIterations[0])) {
            gridAppendedIterations[0][randomIndex] = {
              color,
              abbreviation,
            };
            break;
          }
        }
        break;
      }
    }
  }

  filledWristbandPattern.gridData.forEach(function (item1, index1) {
    updatedWristbandPattern[index1] = item1;
    item1.grid.forEach(function (item2, index2) {
      updatedWristbandPattern[index1].grid[index2] = item2;
      item2.grid.forEach(function (item3, index3) {
        updatedWristbandPattern[index1].grid[index2].grid[index3] = item3;
        item3.grid.forEach(function (item4, index4) {
          updatedWristbandPattern[index1].grid[index2].grid[index3].grid[
            index4
          ] = item4;
          if (item4.code != "") {
            updatedWristbandPattern[index1].grid[index2].grid[index3].grid[
              index4
            ].name = "";
            if (item4.name == "" && item4.code != "") {
              const randomSignBox = gridAppendedIterations[0][boxCount];
              const abbreviation = randomSignBox.abbreviation;
              const color = randomSignBox.color;

              if (formattedData[abbreviation]) {
                formattedData[abbreviation].push(item4.code);
                formattedData2[abbreviation].push(item4.code);
              } else {
                formattedData[abbreviation] = [item4.code];
                formattedData2[abbreviation] = [item4.code];
              }
              updatedWristbandPattern[index1].grid[index2].grid[index3].grid[
                index4
              ].color = color;
              updatedWristbandPattern[index1].grid[index2].grid[index3].grid[
                index4
              ].name = abbreviation;
              boxCount++;
            }
          }
        });
      });
    });
  });

  const updatedGridWithFontSize = updatedGridBoxFont(
    updatedWristbandPattern,
    filledWristbandPattern.size
  );

  return {
    formattedData2,
    formattedData,
    updatedWristbandPattern: updatedGridWithFontSize,
  };
};

export const resetZonesData = (tempItems) => {
  for (let i = 0; i <= tempItems.length; i++) {
    for (const j in tempItems[i]) {
      tempItems[i][j].data = [];
    }
  }
  return tempItems;
};

export const calPrePopulatedInfo = (zonesArray, label) => {
  const tempItems = [...zonesArray];
  let count = 0;
  const arr = [];
  const prevStats = {};
  let prevTotalPopulated = 0;
  for (let i = 0; i <= tempItems.length; i++) {
    for (const j in tempItems[i]) {
      const tempData = tempItems[i][j].data.find(function (el) {
        return el.label == label;
      });
      if (tempData) {
        count++;
        arr.push(tempItems[i][j].id);
        prevStats[tempItems[i][j].id] = tempData.arr;
        prevTotalPopulated += tempData.arr.length;
      }
    }
  }
  return { count, arr, prevStats, prevTotalPopulated };
};

export const reArrangeData = (
  countToPopulate,
  indexToPopulate,
  label,
  foundIndexes,
  items,
  prevStats,
  isUntouched
) => {
  foundIndexes.sort(() => (Math.random() > 0.5 ? 1 : -1));
  const tempItems = [...items];
  // const dividedLabelArr = [];
  const dividedIndexWithLabelsArr = [];
  for (let i = countToPopulate; i > 0; i--) {
    const zoneIndex = indexToPopulate[i - 1];
    const spliceCount =
      isUntouched == true
        ? prevStats[zoneIndex].length
        : Math.ceil(foundIndexes.length / i);
    const splicedArr = foundIndexes.splice(0, spliceCount);
    // dividedLabelArr.push(splicedArr);
    dividedIndexWithLabelsArr[zoneIndex] = splicedArr;
  }
  // assign array
  for (const i in indexToPopulate) {
    const indexArr = indexToPopulate[i].split("");
    const obj = {
      label,
      arr: dividedIndexWithLabelsArr[indexToPopulate[i]],
    };
    const updateIndex = tempItems[indexArr[0]][indexArr[1]].data.findIndex(
      (x) => x.label === label
    );
    // remove incase to greater selected boxes
    if (
      dividedIndexWithLabelsArr[indexToPopulate[i]].length == 0 &&
      updateIndex > -1
    ) {
      tempItems[indexArr[0]][indexArr[1]].data.splice(updateIndex, 1);
    } else if (tempItems[indexArr[0]][indexArr[1]].data.length == 0) {
      tempItems[indexArr[0]][indexArr[1]].data.push(obj);
    } else if (updateIndex > -1) {
      tempItems[indexArr[0]][indexArr[1]].data[updateIndex] = obj;
    } else {
      tempItems[indexArr[0]][indexArr[1]].data.push(obj);
    }
  }
  return tempItems;
};

export const getReverseIndexes = (arr) => {
  let temp = [];
  for (let i in arr) {
    let index = arr[i].split("");
    temp.push(`${index[1]}${index[2]}${index[0]}`);
  }
  return temp;
};

export const resetWristBandArray = (array) => {
  if (array) {
    let updatedWristbandPattern = [];
    array.gridData.forEach(function (item1, index1) {
      updatedWristbandPattern[index1] = item1;
      item1.grid.forEach(function (item2, index2) {
        updatedWristbandPattern[index1]["grid"][index2] = item2;
        item2.grid.forEach(function (item3, index3) {
          updatedWristbandPattern[index1]["grid"][index2]["grid"][index3] =
            item3;
          item3.grid.forEach(function (item4, index4) {
            updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
              "grid"
            ][index4] = item4;
            if (item4.code != "") {
              updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                "grid"
              ][index4]["color"] = "";
              updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                "grid"
              ][index4]["name"] = "";
              updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                "grid"
              ][index4]["zone_name"] = "";
              updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                "grid"
              ][index4].zoneColor = "";
              updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                "grid"
              ][index4].overrideColor = "";
            }
          });
        });
      });
    });
    return updatedWristbandPattern;
  }
};

export const resetAbbreviationOverrideColor = (array, abbreviation) => {
  if (array) {
    const updatedWristbandPattern = [];
    array.gridData.forEach(function (item1, index1) {
      updatedWristbandPattern[index1] = item1;
      item1.grid.forEach(function (item2, index2) {
        updatedWristbandPattern[index1].grid[index2] = item2;
        item2.grid.forEach(function (item3, index3) {
          updatedWristbandPattern[index1].grid[index2].grid[index3] = item3;
          item3.grid.forEach(function (item4, index4) {
            updatedWristbandPattern[index1].grid[index2].grid[index3].grid[
              index4
            ] = item4;
            if (item4.code != "") {
              const boxName =
                updatedWristbandPattern[index1].grid[index2].grid[index3].grid[
                  index4
                ].name;
              if (boxName.toLowerCase() == abbreviation.toLowerCase()) {
                updatedWristbandPattern[index1].grid[index2].grid[index3].grid[
                  index4
                ].overrideColor = "";
              }
            }
          });
        });
      });
    });
    return updatedWristbandPattern;
  }
};

export const ConvertToLocalDateTime = (DateTimeObj, DateFormat = null) => {
  const moment = require("moment-timezone");
  var DateTimeMmoment = moment.utc(moment(DateTimeObj)).local();
  return DateFormat == null || DateFormat == 1
    ? DateTimeMmoment.format("DD-MM-YYYY")
    : DateFormat === 2
    ? DateTimeMmoment.format("MMM DD, YYYY")
    : DateFormat === 3
    ? DateTimeMmoment.format("MM/DD/YYYY")
    : DateTimeMmoment.format("MM-DD-YYYY hh:mm A");
};

export const getZonesDataInfo = (zones) => {
  const zonesDataInfo = [];
  const colorArr = [];
  for (const i in zones) {
    for (const j in zones[i]) {
      const data = zones[i][j].data;
      if (data && data.length > 0) {
        const zoneName = zones[i][j].content;
        const zoneColor = zones[i][j].color;
        for (const k in data) {
          for (const l in data[k].arr) {
            zonesDataInfo[data[k].arr[l]] = zoneName;
            colorArr[data[k].arr[l]] = zoneColor;
          }
        }
      }
    }
  }
  return { zonesDataInfo, colorArr };
};

export const updatedGridConcatination = (
  zonesData,
  colorArr,
  array,
  activeSigns = []
) => {
  const updatedWristbandPattern = [];
  const processedSignsList =
    activeSigns.length > 0
      ? ProcessDataInArray(activeSigns, "abbreviation")
      : [];

  array.gridData.forEach(function (item1, index1) {
    updatedWristbandPattern[index1] = item1;
    item1.grid.forEach(function (item2, index2) {
      updatedWristbandPattern[index1]["grid"][index2] = item2;
      item2.grid.forEach(function (item3, index3) {
        updatedWristbandPattern[index1]["grid"][index2]["grid"][index3] = item3;
        item3.grid.forEach(function (item4, index4) {
          updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
            "grid"
          ][index4] = item4;
          const code =
            updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
              "grid"
            ][index4]["code"];
          const name =
            updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
              "grid"
            ][index4]["name"];

          updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
            "grid"
          ][index4]["zone_name"] = zonesData[code] ? zonesData[code] : "";

          // box color
          const overrideColor =
            code != "" &&
            processedSignsList[name] &&
            processedSignsList[name].isColorOverride == true
              ? processedSignsList[name].color
              : "";
          updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
            "grid"
          ][index4].overrideColor = overrideColor;
          updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
            "grid"
          ][index4].zoneColor = colorArr[code] ? colorArr[code] : "";
        });
      });
    });
  });
  return updatedGridBoxFont(updatedWristbandPattern, array.size);
};

export const getGridBoxFontSize = (boxWidth, boxHeight, text, gridSize) => {
  let fontSize = +Math.round(boxWidth) / 3;

  // dynamic according text letters
  // fontSize = fontSize >= boxHeight - 5 ? boxHeight - 10 : fontSize;
  // return text == 1 ? fontSize + 1 : text > 3 ? fontSize / 2 : fontSize - 2;

  // same size for 1 to 4
  let deductAmount =
    gridSize == 96 ? 5 : gridSize == 120 ? 4.5 : gridSize == 216 ? 4 : 3.5;
  fontSize = fontSize >= boxHeight - 3 ? boxHeight - 2 : fontSize;
  return text == 5 ? fontSize / 2 : fontSize - deductAmount;
};

export const updatedGridBoxFont = (array, gridSize) => {
  let boxHeight = 0;
  let boxWidth = 0;
  if (document.getElementsByClassName("grid_prnt_2")[0]) {
    const box = document.getElementsByClassName("grid_prnt_2")[0].children[0];
    boxHeight = box.offsetHeight;
    boxWidth = box.offsetWidth;
    const updatedWristbandPattern = [];
    array.forEach(function (item1, index1) {
      updatedWristbandPattern[index1] = item1;
      item1.grid.forEach(function (item2, index2) {
        updatedWristbandPattern[index1]["grid"][index2] = item2;
        item2.grid.forEach(function (item3, index3) {
          updatedWristbandPattern[index1]["grid"][index2]["grid"][index3] =
            item3;
          item3.grid.forEach(function (item4, index4) {
            if (index3 > 0 && index4 > 0) {
              const zoneName =
                updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                  "grid"
                ][index4]["zone_name"];
              const name =
                updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                  "grid"
                ][index4]["name"];
              const text = `${name ?? ""}${zoneName ?? ""}`.length;

              if (text > 0) {
                updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
                  "grid"
                ][index4]["fontSize"] = getGridBoxFontSize(
                  boxWidth,
                  boxHeight,
                  text,
                  gridSize
                );
              }
            }
          });
        });
      });
    });
    return updatedWristbandPattern;
  }
  return array;
};

export const getPopulatedCount = (box, signLabel) => {
  let tempData = box.data.filter(function (el) {
    return el.label == signLabel;
  });
  if (tempData.length > 0) return tempData[0].arr.length;
  else return 0;
};

export const updatedGridSign = (
  oldAbbreviation,
  abbreviation,
  color,
  array,
  activeSigns
) => {
  const updatedWristbandPattern = [];
  const processedSignsList =
    activeSigns.length > 0
      ? ProcessDataInArray(activeSigns, "abbreviation")
      : [];

  array.gridData.forEach(function (item1, index1) {
    updatedWristbandPattern[index1] = item1;
    item1.grid.forEach(function (item2, index2) {
      updatedWristbandPattern[index1]["grid"][index2] = item2;
      item2.grid.forEach(function (item3, index3) {
        updatedWristbandPattern[index1]["grid"][index2]["grid"][index3] = item3;
        item3.grid.forEach(function (item4, index4) {
          if (item4.code != "" && oldAbbreviation == item4.name) {
            updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
              "grid"
            ][index4]["color"] = color;
            updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
              "grid"
            ][index4]["name"] = abbreviation;

            // box color
            const overrideColor =
              processedSignsList[abbreviation] &&
              processedSignsList[abbreviation].isColorOverride == true
                ? processedSignsList[abbreviation].color
                : "";

            updatedWristbandPattern[index1]["grid"][index2]["grid"][index3][
              "grid"
            ][index4].overrideColor = overrideColor;
          }
        });
      });
    });
  });

  const updatedGridWithFontSize = updatedGridBoxFont(
    updatedWristbandPattern,
    array.size
  );

  return updatedGridWithFontSize;
};

export const updateZonesData = (Tempitems, oldAbbreviation, abbreviation) => {
  for (let i = 0; i <= Tempitems.length; i++) {
    for (let j in Tempitems[i]) {
      let ZoneDate = Tempitems[i][j].data;
      for (let k in ZoneDate) {
        if (ZoneDate[k].label == oldAbbreviation) {
          Tempitems[i][j].data[k].label = abbreviation;
        }
      }
    }
  }
  return Tempitems;
};

export const validateZonePopulation = (Tempitems, signs) => {
  let isValid = false;
  for (let s = 0; s < signs.length; s++) {
    if (s > 0 && isValid == false) break;
    isValid = false;
    for (let i = 0; i <= Tempitems.length; i++) {
      for (let j in Tempitems[i]) {
        if (Tempitems[i][j].data.length > 0) {
          for (let k in Tempitems[i][j].data) {
            if (Tempitems[i][j].data[k].label == signs[s].abbreviation) {
              isValid = true;
            }
          }
        }
      }
    }
  }
  return isValid;
};

export const validateZoneSelection = (Tempitems) => {
  let isValid = false;
  for (let i = 0; i <= Tempitems.length; i++) {
    if (isValid == true) break;
    for (let j in Tempitems[i]) {
      if (Tempitems[i][j].content) {
        isValid = true;
        break;
      }
    }
  }
  return isValid;
};

// function from https://stackoverflow.com/a/5624139/3695983
const hexToRgb = (hex) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

// function from https://stackoverflow.com/a/9733420/3695983
const luminance = (r, g, b) => {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const calculateRatio = (color1, color2) => {
  // read the colors and transform them into rgb format
  const color1rgb = hexToRgb(color1);
  const color2rgb = hexToRgb(color2);

  // calculate the relative luminance
  const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b);
  const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b);

  // calculate the color contrast ratio
  const ratio =
    color1luminance > color2luminance
      ? (color2luminance + 0.05) / (color1luminance + 0.05)
      : (color1luminance + 0.05) / (color2luminance + 0.05);

  return ratio;
};

export const checkIfAbbreviationExists = (list, abbreviation) => {
  return (
    list.findIndex(
      (item) => item.abbreviation.toLowerCase() === abbreviation.toLowerCase()
    ) > -1
  );
};
// filterList
export const filterListByAttribute = (list, attribute, val) => {
  return list.filter((obj) => obj[attribute] === val);
};

export const getLandingParams = () => {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var id = url.searchParams.get("id");
  var mode = url.searchParams.get("mode");
  return { id, mode };
};

export const isHavePermission = (list, permissionLabel) => {
  if (list === "" || list === null || list.length === 0) return false;
  return list.findIndex((item) => item === permissionLabel) > -1;
};

export const measureTextWidth = (str, fontSize) => {
  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875,
    0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125,
    0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625,
    0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625,
    0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875,
    0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875,
    0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875,
    0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5,
    0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625,
    0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5,
    0.3546875, 0.259375, 0.353125, 0.5890625,
  ];
  const avg = 0.5279276315789471;
  return (
    str
      .split("")
      .map((c) =>
        c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg
      )
      .reduce((cur, acc) => acc + cur) * fontSize
  );
};

// Zones
export const getSelectZoneSizeObj = (val) => {
  if (val) return zonesSizes.find((item) => item.selectValue == val);
  else return null;
};
export const getBoxDimention = (matrixVal, getType = "Creation") => {
  let obj = getSelectZoneSizeObj(matrixVal);
  let rowCount = obj ? obj.x : 0; // zonesSizes[matrixVal] ? zonesSizes[matrixVal].x : 0;
  let zoneHeight = 0;
  let zoneWidth = 0;
  let boxspace = 3;
  // let fontSize = 10;
  if (getType === "Creation") {
    if (window.matchMedia("(min-width:320px) and (max-width: 389px)").matches) {
      zoneHeight = parseInt(250 / rowCount); // rowCount == 4 ? 80 : 60;
      zoneWidth = parseInt(250 / rowCount); // rowCount == 4 ? 80 : 60;
      boxspace = 2;
      // if (rowCount == 4) fontSize = 18;
      // else if (rowCount == 5) fontSize = 15;
      // else if (rowCount == 6) fontSize = 12;
      // else if (rowCount == 7) fontSize = 11;
      // else if (rowCount == 8) fontSize = 9;
    } else if (
      window.matchMedia("(min-width:389px) and (max-width: 465px)").matches
    ) {
      zoneHeight = parseInt(320 / rowCount); // rowCount == 4 ? 100 : 70;
      zoneWidth = parseInt(320 / rowCount); // rowCount == 4 ? 100 : 70;
      boxspace = 2;
    } else if (
      window.matchMedia("(min-width:466px) and (max-width: 767px)").matches
    ) {
      zoneHeight = parseInt(390 / rowCount); // rowCount == 4 ? 125 : 100;
      zoneWidth = parseInt(390 / rowCount); // rowCount == 4 ? 125 : 100;
    } else if (
      window.matchMedia("(min-width:768px) and (max-width: 991px)").matches
    ) {
      zoneHeight = parseInt(280 / rowCount); // rowCount == 4 ? 100 : 70;
      zoneWidth = parseInt(280 / rowCount); // rowCount == 4 ? 100 : 70;
      // if (rowCount <= 6) fontSize = 14;
    } else if (
      window.matchMedia("(min-width:992px) and (max-width: 1199px)").matches
    ) {
      zoneHeight = parseInt(400 / rowCount); // rowCount == 4 ? 125 : 100;
      zoneWidth = parseInt(400 / rowCount); // rowCount == 4 ? 125 : 100;
      // if (rowCount <= 6) fontSize = 16;
    } else if (window.matchMedia("(min-width: 1200px)").matches) {
      zoneHeight = parseInt(485 / rowCount); // rowCount == 4 ? 125 : 100;
      zoneWidth = parseInt(485 / rowCount); // rowCount == 4 ? 125 : 100;
      // if (rowCount <= 6) fontSize = 16;
    }

    return {
      width: zoneWidth,
      height: zoneHeight,
      boxSpace: boxspace,
      fontSize: zoneWidth / 3 - 3,
    };
  }
};
export const getVisualdndZoneDetails = (matrixVal) => {
  let obj = getSelectZoneSizeObj(matrixVal);
  let rowCount = obj ? obj.x : 0; // zonesSizes[matrixVal] ? zonesSizes[matrixVal].x : 0;
  let fontSize = 0;
  let dimension = 0;

  if (rowCount === 4) {
    dimension = 37;
    fontSize = 9;
  } else if (rowCount === 5) {
    dimension = 32;
    fontSize = 8;
  } else if (rowCount === 6) {
    dimension = 27;
    fontSize = 7;
  } else if (rowCount === 7) {
    dimension = 22;
    fontSize = 5.5;
  } else {
    dimension = 20;
    fontSize = 5;
  }
  return {
    height: dimension,
    width: dimension,
    // fontSize: rowCount === 4 ? 18 : 12,
    fontSize: fontSize,
  };
};
export const getReArrangeModalVisualdndZoneDetails = (matrixVal) => {
  let obj = getSelectZoneSizeObj(matrixVal);
  let rowCount = obj ? obj.x : 0; // zonesSizes[matrixVal] ? zonesSizes[matrixVal].x : 0;
  let fontSize = 0;
  let dimension = 0; // rowCount === 4 ? 25 : 20;

  if (rowCount === 4) {
    dimension = 25;
    fontSize = 6;
  } else {
    dimension = 20;
    fontSize = 5;
  }
  return {
    height: dimension,
    width: dimension,
    fontSize: fontSize, // rowCount === 4 ? 16 : 12,
  };
};
export const getCoachCardInitialWidth = (matrixVal) => {
  let obj = getSelectZoneSizeObj(matrixVal);
  let rowCount = obj ? obj.x : 0; // zonesSizes[matrixVal] ? zonesSizes[matrixVal].x : 0;
  return rowCount === 4 ? 120 : 100;
};
export const getCoachCardInitialBoxWidth = (matrixVal) => {
  return matrixVal == 1
    ? 570
    : matrixVal == 2
    ? 285
    : matrixVal == 3
    ? 190
    : matrixVal == 4
    ? 140
    : matrixVal == 5
    ? 112
    : matrixVal == 6
    ? 92
    : matrixVal == 7
    ? 78
    : 68;
};
export const getCoachCardInitialBoxHeight = (
  matrixVal,
  fitToScreen,
  containerHeight,
  zones,
  zonePadding,
  isNotes
) => {
  if (zones == null || zones == undefined || zones.length == 0) return 30;
  const footer = isNotes ? 2 : 0;
  const rows = zones.length + footer;
  const boxHeight = containerHeight / rows;
  if (isNotes == false || fitToScreen == true) {
    // let activeRows = 0;
    const coveredIndexes = [];
    let i = 0;
    while (i < zones.length) {
      let currentRowActive = false;
      let currentRowHightCount = 0;
      let j = 0;
      while (j < zones[i].length) {
        const item = zones[i][j];
        if (item.content && item.data.length > 0) {
          currentRowActive = true;
          if (item.verticalmergedZonesCount > currentRowHightCount)
            currentRowHightCount = item.verticalmergedZonesCount;
        }
        j++;
      }

      if (currentRowActive == true) {
        for (let k = 0; k < currentRowHightCount; k++) {
          const value = i + k;
          if (!coveredIndexes.includes(value)) coveredIndexes.push(value);
        }
      }
      // activeRows += currentRowHightCount;
      i++; // i += currentRowHightCount;
    }
    const temHeight = containerHeight / coveredIndexes.length; // activeRows;
    return temHeight - zonePadding * 2;
  }
  return boxHeight - zonePadding * 2;
};
export const getCurrentRowStatus = (rowList) => {
  let currentRowActive = false;
  let currentRowHightCount = 1;
  for (const i in rowList) {
    if (rowList[i].content && rowList[i].data.length > 0) {
      currentRowActive = true;
      if (rowList[i].verticalmergedZonesCount > currentRowHightCount)
        currentRowHightCount = rowList[i].verticalmergedZonesCount;
    }
  }
  return { currentRowActive, currentRowHightCount };
};
export const getMergeZoneMarginVal = (zonesCount) => {
  const val =
    zonesCount == 2
      ? 2
      : zonesCount == 3
      ? 4
      : zonesCount == 4
      ? 6
      : zonesCount == 5
      ? 8
      : zonesCount == 6
      ? 10
      : zonesCount == 7
      ? 12
      : 14;
  return val;
};
export const getRowActiveIndexes = (row) => {
  const processRowActiveIndexes = [];
  let processRowBoxIndex = 0;
  for (const element of row) {
    const isActive = element.content && element.data.length > 0;
    [...Array(element.mergedZonesCount)].map(() => {
      if (isActive) processRowActiveIndexes.push(processRowBoxIndex);
      processRowBoxIndex++;
    });
  }
  return processRowActiveIndexes;
};
export const getAllActiveZoneIndexes = (zones) => {
  let list = [];
  for (const i in zones) {
    let processRowBoxIndex = 0;
    for (const j in zones[i]) {
      const isActive = zones[i][j].content && zones[i][j].data.length > 0;
      const rowIndex = zones[i][j].initialId.split("")[0];

      const currentRowIndexes = [];
      const indexes = [];

      [...Array(zones[i][j].mergedZonesCount)].map(() => {
        if (isActive) {
          currentRowIndexes.push(processRowBoxIndex);
          indexes.push(`${rowIndex}${processRowBoxIndex}`); // rowIndex to i
        }
        processRowBoxIndex++;
      });

      if (zones[i][j].verticalmergedZonesCount > 1) {
        const terminateIndex =
          parseInt(i) + zones[i][j].verticalmergedZonesCount;
        for (let k = parseInt(i) + 1; k < terminateIndex; k++) {
          const currentRowIndex = zones[k][0].initialId.split("")[0];
          currentRowIndexes.map((item) =>
            indexes.push(`${currentRowIndex}${item}`)
          ); // currentRowIndex to k
        }
      }
      list = [...list, ...indexes];
    }
  }
  return list;
};
export const validateNextBoxActive = (
  item,
  activeZonesIndexes,
  initialStartIndex,
  zones,
  validateBox,
  rowBaseIndex
) => {
  const rowIndex = validateBox.initialId.split("")[0];
  const compareRowIndexes = [rowIndex];
  if (validateBox.verticalmergedZonesCount > 1) {
    const terminateIndex =
      parseInt(rowIndex) + parseInt(validateBox.verticalmergedZonesCount);
    for (let k = parseInt(rowIndex) + 1; k < terminateIndex; k++) {
      compareRowIndexes.push(k);
    }
  }

  const compareColumnIndexes = [];
  compareRowIndexes.map((item) => {
    compareColumnIndexes.push(`${item}${initialStartIndex}`);
  });

  const isActive = compareColumnIndexes.some((r) =>
    activeZonesIndexes.includes(r)
  ); // item.content && item.data.length > 0;

  // if (!isActive) {
  //   const initialEndIndex = initialStartIndex + item.mergedZonesCount - 1;
  //   const coveredIndexes = [];
  //   const indexes = [];

  //   for (let i = initialStartIndex; i <= initialEndIndex; i++) {
  //     coveredIndexes.push(`${rowIndex}${i}`);
  //     indexes.push(i);
  //   }

  //   if (item.verticalmergedZonesCount > 1) {
  //     const terminateIndex = rowIndex + item.verticalmergedZonesCount;
  //     for (let k = rowIndex + 1; k < terminateIndex; k++) {
  //       const currentRowIndex = zones[k][0].initialId.split("")[0];
  //       indexes.map((mapItem) =>
  //         coveredIndexes.push(`${currentRowIndex}${mapItem}`)
  //       );
  //     }
  //   }

  //   return coveredIndexes.some((r) => activeZonesIndexes.includes(r));
  // }
  return isActive;
};
export const getBoxInitialIndexes = (
  row,
  column,
  compareInitialIndex = false,
  includeInactive = false,
  list = [],
  activeZonesIndexes = [],
  item = null,
  rowBaseIndex = -1
) => {
  let initialStartIndex = 0;
  let initialEndIndex = 0;
  const coveredIndexes = [];

  for (const i in row) {
    const mapItem = row[i];
    const indexToCompare =
      compareInitialIndex == true ? mapItem.initialId.split("")[1] : i;

    if (indexToCompare < column) {
      initialStartIndex += mapItem.mergedZonesCount;
      initialEndIndex += mapItem.mergedZonesCount;
    } else if (indexToCompare == column) {
      initialEndIndex += mapItem.mergedZonesCount - 1;
    } else if (includeInactive && indexToCompare > column) {
      if (
        validateNextBoxActive(
          mapItem,
          activeZonesIndexes,
          initialEndIndex + 1,
          list,
          item,
          rowBaseIndex
        )
      ) {
        break;
      } else {
        initialEndIndex += mapItem.mergedZonesCount;
      }
    }
  }

  for (let i = initialStartIndex; i <= initialEndIndex; i++) {
    coveredIndexes.push(i);
  }

  return {
    initialStartIndex,
    initialEndIndex,
    coveredIndexes,
  };
};
export const getColumnRemoveBoxIndexes = (zones, row, column) => {
  const removeIndexArr = [];
  const currentRow = zones[row];
  // const currentBox = currentRow[column];
  removeIndexArr.push(`${row}${column}`);
  let {
    // initialStartIndex,
    // initialEndIndex,
    coveredIndexes,
  } = getBoxInitialIndexes(currentRow, column);
  for (let i = 1; i < zones.length; i++) {
    let foundActive = false;
    let j = 0;
    while (j < zones[i].length) {
      const currentInitialIndexes = getBoxInitialIndexes(zones[i], j);
      if (
        coveredIndexes.some((r) =>
          currentInitialIndexes.coveredIndexes.includes(r)
        )
      ) {
        if (zones[i][j].content === "" && zones[i][j].data.length === 0) {
          removeIndexArr.push(`${i}${j}`);
        } else {
          foundActive = true;
          break;
        }
      }
      j++;
    }
    if (foundActive) return [];
  }
  return removeIndexArr;
};

/**
 * @param {string} name
 * @description Get parameter value from url get params
 * @returns {string | undefined}
 */
export const getParamFromUrl = (name) => {
  const url = new URL(window.location);
  return url.searchParams.get(name);
};
