import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

// redux
import { Provider } from "react-redux";
import storeConfig from "./store";
import { PersistGate } from "redux-persist/es/integration/react";

ReactDOM.render(
  <Provider store={storeConfig.store}>
    <PersistGate loading={null} persistor={storeConfig.persistor}>
      <App store={storeConfig.store} />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
