import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
// import "../assets/css/wristband_6.css";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";
import SelectZone from "./pages/wristband/SelectZone";
import WistbandGrid from "./pages/wristband/wistband_grid";
import PrintWistband from "./pages/wristband/print_wistband";
import {
  emptyWristbandPattern54,
  emptyWristbandPattern96,
  emptyWristbandPattern150,
  emptyWristbandPattern216,
  fillWristbandPattern54,
  fillWristbandPattern96,
  fillWristbandPattern150,
  fillWristbandPattern216,
} from "./pages/wristband/Grids";
import {
  Group_24145,
  Ellipse_1,
  Ellipse_2,
  Ellipse_3,
  Ellipse_4,
  Ellipse_5,
  Ellipse_6,
  Ellipse_7,
  Ellipse_8,
  Ellipse_9,
  Ellipse_10,
  Ellipse_11,
  Ellipse_12,
  Ellipse_13,
  // wrist_bg,
  MinusIcon,
  PlusIcon,
} from "../shared/images";
import Slider from "@material-ui/core/Slider";
import ZoneNameModal from "./Modals/ZoneNameModal";
import RemoveSignModel from "./Modals/removeSignModel";
import CoachCard from "./pages/wristband/CoachCard";
import CoachCard_V1 from "./pages/wristband/CoachCard_V1";
// import CoachCardPreview from "./pages/wristband/CoachCardPreview";
import PitcherCard from "./pages/wristband/PitcherCard";
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   PDFDownloadLink,
//   PDFViewer,
// } from "@react-pdf/renderer";
import {
  ProcessDataInArray,
  getWristbandPopulatedData,
  resetZonesData,
  calPrePopulatedInfo,
  reArrangeData,
  resetWristBandArray,
  resetWristBandArrayByZoneName,
  getZonesDataInfo,
  updatedGridConcatination,
  getPopulatedCount,
  updatedGridSign,
  updateZonesData,
  validateZonePopulation,
  calculateRatio,
  validateZoneSelection,
  checkIfAbbreviationExists,
  getBoxDimention,
  getVisualdndZoneDetails,
  getCoachCardInitialWidth,
  getReArrangeModalVisualdndZoneDetails,
  getSelectZoneSizeObj,
  filterListByAttribute,
  updatedGridBoxFont,
  resetAbbreviationOverrideColor,
} from "../shared/functions";
import {
  APP_ERROR_MSGS,
  wristBandType,
  downloadPrintDimensions,
  defaultPrintDimensionVal,
  defaultPrintDimensionWidth,
  defaultPrintDimensionHeight,
  coachCardLayoutType,
  zonesColorList,
  UserSubscriptionModes,
  zonesSizes,
  zonesDefaultColor,
  defaultZoneSizeLink,
} from "../shared/constants";
import { getSigns, createSign, updateSign } from "../actions/signs";
import {
  checkPdfDownloadLimitExceeded,
  createWristBand,
  getWristBandDetail,
  updateWristBand,
} from "../actions/wristBands";
import { saveUserDetails } from "../actions/auth";

import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { UPDATE_LOADING } from "../actions/types";
import { __esModule } from "redux-persist/lib/storage/createWebStorage";
// import useStateCallBack from "../shared/useStateCallBack";
import EditsignsModal from "./Modals/EditsignsModal";
import re_arange_icon from "../assets/images/re_arange_icon.svg";
import lock_close_icon from "../assets/images/lock_close_icon.svg";
import lock_opn_icon from "../assets/images/lock_opn_icon.svg";
import Re_arrangeModal from "./Modals/Re_arrangeModal";
import PDFNameModal from "./Modals/PDFNameModal";
import LimitExceedModal from "./Modals/LimitExceedModal";
import PrevBtnConfirmationModal from "./Modals/PrevBtnConfirmationModal";
import ZonesPopulationAlertModal from "./Modals/ZonesPopulationAlertModal";
import ZonesSelectionAlertModal from "./Modals/ZonesSelectionAlertModal";
// import PitcherCardOverViewModal from "./Modals/PitcherCardOverViewModal";
// import CardTypeRadio from "./CardTypeRadio";
import ZonesViewRadio from "./ZonesViewRadio";
import PdfWrapper from "./pages/wristband/PdfWrapper";
import CollegeCard from "./pages/wristband/CollegeCard";
import QRGenerator from "./QRGenerator";
import QRConfirmationModal from "./Modals/QRConfirmationModal";
import PDFDownloadLinkWrapper from "./pages/wristband/PDFDownloadLinkWrapper";
import DuplicateAbbreviationAlertModal from "./Modals/DuplicateAbbreviationAlertModal";
import MessageAlertModal from "./Modals/MessageAlertModal";
import DropDown from "./dropdownRendrer";
import ReplaceDuplicateSignModal from "./Modals/replaceDuplicateSignModal";

function Wristband(props) {
  const defaultUserId = localStorage.getItem("userId");
  const subscriptionMode = localStorage.getItem("mode");

  const alert = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pdfButtonRef = useRef();

  let clickTimeout = null;

  // const bandId = location?.state.id;
  // const bandName = location?.state?.bandName;
  // const bandType = location?.state?.bandType;
  const query = new URLSearchParams(props.location.search);
  const bandType = query.get("bandType");
  const bandName = query.get("bandName");
  const receivedBandId = query.get("id");

  const StartIndex = bandType == wristBandType.pitching ? 1 : 2;
  const progressSteps =
    bandType == wristBandType.pitching
      ? [
          { indexNo: 1, lable: 1 },
          { indexNo: 2, lable: 2 },
          { indexNo: 3, lable: 3 },
          { indexNo: 4, lable: 4 },
        ]
      : [
          { indexNo: 2, lable: 1 },
          { indexNo: 3, lable: 2 },
          { indexNo: 4, lable: 3 },
        ];

  const [emptyWristbandPattern, setemptyWristbandPattern] = useState(
    emptyWristbandPattern54
  );
  const [filledWristbandPattern, setfilledWristbandPattern] = useState(
    fillWristbandPattern54
  );
  const [bandId, setBandId] = useState(receivedBandId); //(location?.state?.id);
  const [activeSection, setactiveSection] = useState(StartIndex);
  const [gridBgColor, setgridBgColor] = useState("#197D3E");
  const [gridborderColor, setgridborderColor] = useState("#fff");
  const [signs, setSigns] = useState([]);
  const [showSlider, setshowSlider] = useState(false);
  const [activeSigns, setactiveSigns] = useState([]);
  const [filterlist, setfilterlist] = useState([]);
  const [printWristbandSec, setprintWristbandSec] = useState(false);
  const [removeSignModalProps, setRemoveSignModalProps] = useState({
    show: false,
    signName: "",
  });
  const [dimensionDropdownVal, setDimensionDropdownVal] = useState([
    400, 500, 600, 700, 800, 900, 1000,
  ]);
  const [selectedDimension, setSelectedDimension] = useState(
    defaultPrintDimensionVal
  );
  const [PrintWidth, setPrintWidth] = useState(defaultPrintDimensionWidth); //400
  const [PrintHeight, setPrintHeight] = useState(defaultPrintDimensionHeight); //400
  const [printPitcherHeight, setPrintPitcherHeight] = useState(0);
  const [isBandSaved, setIsBandSaved] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedAbbreviation, setSelectedAbbreviation] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReArrangeModal, setShowReArrangeModal] = useState(false);
  const [selectedPdfFileType, setSelectedPdfFileType] = useState(null);
  const [showPdfNameModal, setShowPdfNameModal] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [showPrevConfirmationModal, setShowPrevConfirmationModal] =
    useState(false);
  const [showZonesPopulationAlertModal, setShowZonesPopulationAlertModal] =
    useState(false);
  const [showZonesSelectionAlertModal, setShowZonesSelectionAlertModal] =
    useState(false);
  const [isOnlyColor, setIsOnlyColor] = useState(false);
  const [showLimitExceedModal, setShowLimitExceedModal] = useState(false);
  const [layoutType, setLayoutType] = useState(
    coachCardLayoutType.overThenDown
  );
  const [isReverseIndexes, setIsReverseIndexes] = useState(false);

  const [coachCradInitialWidth, setCoachCradInitialWidth] = useState(120);
  const [coachCradInitialHeight, setCoachCradInitialHeight] = useState(50);
  const [showPitcherCardOverViewModal, setShowPitcherCardOverViewModal] =
    useState(false);
  const [cardsViewType, setCardsViewType] = useState(0);
  const [coachCardQR, setCoachCardQR] = useState("");
  const [collegeCardQR, setCollegeCardQR] = useState("");
  const [isShowQR, setIsShowQR] = useState(false);
  const [showQRConfirmationModal, setShowQRConfirmationModal] = useState(false);
  const [alertMessageState, setAlertMessageState] = useState({
    label: "Success",
    message: "Saved Successfully",
    show: false,
  });
  const [isZoneColorOverride, setIsZoneColorOverride] = useState(false);
  const [replaceSignConfirm, setReplaceSignConfirm] = useState(false);
  const [signAbbreviationToReplace, setSignAbbreviationToReplace] =
    useState(null);

  // Zones State start
  const [zonesArray, setZonesArray] = useState([]);
  const [matrix, setMatrix] = useState(16); // useStateCallBack(null);
  const [primary, setPrimary] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneName, setSelectedZoneName] = useState("");
  const [selectedZoneColor, setSelectedZoneColor] = useState("");

  const [dndProps, setDndProps] = useState({
    containerHeight: 400,
    containerWidth: 320,
    zoneInitialHeight: 0,
    zoneInitialWidth: 0,
    zonePadding: 0,
    diableColor: zonesDefaultColor.diable,
    activeColor: zonesDefaultColor.active,
    fontSize: 10,
    isDragDisabled: false,
  });
  const [vidsualdndProps, setVidsualdndProps] = useState({
    containerHeight: 400,
    containerWidth: 320,
    zoneInitialHeight: 20,
    zoneInitialWidth: 20,
    zonePadding: 1,
    diableColor: zonesDefaultColor.diable,
    activeColor: zonesDefaultColor.active,
    fontSize: 8,
    isDragDisabled: true,
  });
  const [reArrangeModalVisualdndProps, setReArrangeModalVisualdndProps] =
    useState({
      containerHeight: 400,
      containerWidth: 320,
      zoneInitialHeight: 20,
      zoneInitialWidth: 20,
      zonePadding: 1,
      diableColor: zonesDefaultColor.diable,
      activeColor: zonesDefaultColor.active,
      fontSize: 20,
      isDragDisabled: true,
    });
  const [formattedwristbandData, setFormattedwristbandData] = useState([]);
  const [signsIndexesAarr, setSignsIndexesAarr] = useState([]);
  const [selectedZonesToMerge, setSelectedZonesToMerge] = useState([]);
  const [rearrangeModalProps, setRearrangeModalProps] = useState(null);
  const [zoneColorIndex, setZoneColorIndex] = useState(0);
  const [allAbbreviationsList, setAllAbbreviationsList] = useState([]);
  const [abbreviationExistError, setAbbreviationExistError] = useState(false);
  const [abbreviationExistErrorMsg, setAbbreviationExistErrorMsg] =
    useState(null);
  const [msgRedirectToSignForm, setMsgRedirectToSignForm] = useState(false);
  const [isMirrorView, setIsMirrorView] = useState(false);

  // Zones state end

  const [wristbandPitcherName, setWristbandPitcherName] = useState("");
  const [wristbandOpponent, setWristbandOpponent] = useState("");

  // Zones Methods
  const getBoxObject = (rowIndex, columnIndex, initialColumnIndex = null) => {
    return {
      id: `${rowIndex}${columnIndex}`,
      initialId: `${rowIndex}${initialColumnIndex ?? columnIndex}`,
      originalId: `${rowIndex}${initialColumnIndex ?? columnIndex}`,
      rowIndex: rowIndex,
      columnIndex: columnIndex,
      content: "",
      color: dndProps.diableColor,
      isActive: false,
      isCurrentMerged: false,
      mergedZonesCount: 1,
      isPopulate: false,
      data: [],
      isRemoved: false,
      verticalmergedZonesCount: 1,
      opacity: 1,
      zIndex: 1,
      borderColor: zonesDefaultColor.border,
      textColor: zonesDefaultColor.font,
    };
  };
  const formArray = (index, count) =>
    Array.from({ length: count }, (v, k) => k).map((k) =>
      getBoxObject(index, k)
    );
  const generateInitialZones = (val) => {
    let obj = getSelectZoneSizeObj(val);
    let x = obj ? obj.x : 0; // zonesSizes[val] ? zonesSizes[val].x : 0;
    let y = obj ? obj.y : 0; // zonesSizes[val] ? zonesSizes[val].y : 0;
    setZonesArray(getItems(x, y));
  };
  const getItems = (x, y) => {
    let Matrix = [];
    for (let i = 0; i < y; i++) {
      let arr = formArray(i, x);
      Matrix.push(arr);
    }
    return Matrix;
  };
  const handleDnDZoneClick = (item) => {
    setSelectedZone(item);
    setSelectedZoneName(item.content);
    setPrimary(true);
  };
  const handleZoneClick = (item) => {
    if (clickTimeout !== null) {
      clearTimeout(clickTimeout);
      clickTimeout = null;

      // double click
      setSelectedZone(item);
      setSelectedZoneName(item.content);
      setSelectedZoneColor(item.color ?? zonesDefaultColor.diable);

      setPrimary(true);
    } else {
      clickTimeout = setTimeout(() => {
        clearTimeout(clickTimeout);
        clickTimeout = null;

        // single click
        let tempArr = [...selectedZonesToMerge];
        let isExclude = false;
        let ExcludeIndex = -1;
        for (let i in tempArr) {
          if (tempArr[i].id == item.id) {
            ExcludeIndex = i;
            isExclude = true;
            break;
          }
        }

        if (isExclude) {
          tempArr.splice(ExcludeIndex, 1);
        } else {
          tempArr.push(item);
        }
        setSelectedZonesToMerge(tempArr);
      }, 400);
    }
  };
  const validateIsMergeAble = (zoneList) => {
    let zonesDirection = null;
    let isMergeAble = false;

    let rowIndexes = [];
    let columnIndexes = [];
    let verticalmergedZones = [];
    let horizontalmergedZones = [];
    let columnIndexesForVertical = [];

    for (let i in zoneList) {
      rowIndexes.push(zoneList[i].rowIndex);
      columnIndexes.push(zoneList[i].columnIndex);
      columnIndexesForVertical.push(zoneList[i].initialId.split("")[1]);
      verticalmergedZones.push(zoneList[i].verticalmergedZonesCount);
      horizontalmergedZones.push(zoneList[i].mergedZonesCount);
    }

    if (
      rowIndexes.every((val, i, arr) => val === arr[0]) &&
      verticalmergedZones.every((val, i, arr) => val === arr[0]) &&
      !validateIsScequenceMissing(columnIndexes)
    ) {
      zonesDirection = "horizontal";
      isMergeAble = true;
    }
    if (
      columnIndexesForVertical.every((val, i, arr) => val === arr[0]) &&
      horizontalmergedZones.every((val, i, arr) => val === arr[0]) &&
      !validateVerticalIsScequenceMissing(zoneList)
    ) {
      zonesDirection = "vertical";
      isMergeAble = true;
    }
    return { zonesDirection: zonesDirection, isMergeAble: isMergeAble };
  };
  const validateIsScequenceMissing = (list) => {
    let isMissing = false;
    list = list.sort(function (a, b) {
      return a - b;
    });
    for (var i = 1; i < list.length; i++) {
      if (list[i] - list[i - 1] != 1) {
        isMissing = true;
        break;
      }
    }
    return isMissing;
  };
  const validateVerticalIsScequenceMissing = (list) => {
    let isMissing = false;
    list = list.sort(function (a, b) {
      return a.rowIndex - b.rowIndex;
    });
    for (var i = 1; i < list.length; i++) {
      if (
        list[i].rowIndex - list[i - 1].rowIndex !=
        1 * list[i - 1].verticalmergedZonesCount
      ) {
        isMissing = true;
        break;
      }
    }
    return isMissing;
  };
  const getMergedZonesCount = (list, attribute) => {
    let count = 0;
    for (let i in list) {
      count = count + list[i][attribute];
    }
    return count;
  };
  const getMergedZoneName = (list) => {
    let name = "";
    for (let i in list) {
      if (list[i].content != "" && list[i].content != null) {
        name = list[i].content;
        break;
      }
    }
    return name;
  };
  const getMergedZoneColor = (list) => {
    let color = dndProps.diableColor;
    for (let i in list) {
      if (list[i].color != dndProps.diableColor) {
        color = list[i].color;
        break;
      }
    }
    return color;
  };
  const getMergedZIndex = (list) => {
    let val = 0;
    for (let i in list) {
      val = val + list[i].zIndex;
    }
    return val;
  };
  const resetWristbandZonesData = () => {
    const updated = resetWristBandArray(filledWristbandPattern);
    setfilledWristbandPattern({
      ...filledWristbandPattern,
      gridData: updated,
    });
    const tempSigns = [...signs];
    for (const i in tempSigns) {
      tempSigns[i].status = false;
      tempSigns[i].is_locked = false;
      tempSigns[i].isColorOverride = false;
    }
    setSigns(tempSigns);
    setactiveSigns([]);
  };
  const handleMergeBtnClick = () => {
    let selcetdZones = [...selectedZonesToMerge];
    let { zonesDirection, isMergeAble } = validateIsMergeAble(selcetdZones);
    if (!isMergeAble) return window.alert("Can't merge");

    let Tempitems = [...zonesArray];

    if (zonesDirection == "horizontal") {
      let sortedSelected = selcetdZones.sort(function (a, b) {
        return a.columnIndex - b.columnIndex;
      });
      const indexes = sortedSelected[0].id.split("");
      Tempitems[indexes[0]][indexes[1]].mergedZonesCount = getMergedZonesCount(
        sortedSelected,
        "mergedZonesCount"
      );
      let zoneName = getMergedZoneName(sortedSelected);
      let zoneColor = getMergedZoneColor(sortedSelected);
      const boxBGColor = zoneName
        ? getActiveZoneColor(zoneColor)
        : dndProps.diableColor;
      const boxFontColor = zoneName
        ? subscriptionMode === UserSubscriptionModes.gamming
          ? calculateRatio(boxBGColor, "#ffffff") < 1 / 3
            ? "#ffffff"
            : "#000000"
          : zonesDefaultColor.font
        : zonesDefaultColor.font;

      Tempitems[indexes[0]][indexes[1]].content = zoneName;
      Tempitems[indexes[0]][indexes[1]].isActive = zoneName ? true : false;
      Tempitems[indexes[0]][indexes[1]].color = boxBGColor;
      Tempitems[indexes[0]][indexes[1]].textColor = boxFontColor;

      for (let i = sortedSelected.length - 1; i >= 1; i--) {
        Tempitems[indexes[0]].splice(sortedSelected[i].id.split("")[1], 1);
      }

      Tempitems[indexes[0]].map(function (item, index) {
        item.id = `${indexes[0]}${index}`;
        item.rowIndex = indexes[0];
        item.columnIndex = index;
      });
    } else {
      let sortedSelected = selcetdZones.sort(function (a, b) {
        return a.rowIndex - b.rowIndex;
      });
      const indexes = sortedSelected[0].id.split("");
      Tempitems[indexes[0]][indexes[1]].verticalmergedZonesCount =
        getMergedZonesCount(sortedSelected, "verticalmergedZonesCount");

      let zoneName = getMergedZoneName(sortedSelected);
      let zoneColor = getMergedZoneColor(sortedSelected);
      const boxBGColor = zoneName
        ? getActiveZoneColor(zoneColor)
        : dndProps.diableColor;
      const boxFontColor = zoneName
        ? subscriptionMode === UserSubscriptionModes.gamming
          ? calculateRatio(boxBGColor, "#ffffff") < 1 / 3
            ? "#ffffff"
            : "#000000"
          : zonesDefaultColor.font
        : zonesDefaultColor.font;

      Tempitems[indexes[0]][indexes[1]].content = zoneName;
      Tempitems[indexes[0]][indexes[1]].isActive = zoneName ? true : false;
      Tempitems[indexes[0]][indexes[1]].color = boxBGColor;
      Tempitems[indexes[0]][indexes[1]].textColor = boxFontColor;
      Tempitems[indexes[0]][indexes[1]].zIndex =
        getMergedZIndex(sortedSelected);

      for (let i = 1; i <= sortedSelected.length - 1; i++) {
        let hideIndexes = sortedSelected[i].id.split("");
        Tempitems[hideIndexes[0]][hideIndexes[1]].opacity = 0;
        Tempitems[hideIndexes[0]][hideIndexes[1]].verticalmergedZonesCount = 1;
        Tempitems[hideIndexes[0]][hideIndexes[1]].content = "";
        Tempitems[hideIndexes[0]][hideIndexes[1]].isActive = false;
        Tempitems[hideIndexes[0]][hideIndexes[1]].color = dndProps.diableColor;
        Tempitems[hideIndexes[0]][hideIndexes[1]].textColor =
          zonesDefaultColor.font;
        Tempitems[hideIndexes[0]][hideIndexes[1]].zIndex = 1;
      }
    }

    // Reset zones data
    Tempitems = resetZonesData(Tempitems);
    setZonesArray(Tempitems);
    resetWristbandZonesData();
    setSelectedZonesToMerge([]);
  };
  const handleUnMergeBtnClick = () => {
    let selectedZonesArray = [...selectedZonesToMerge];
    let selectedZone = { ...selectedZonesArray[0] };
    let Tempitems = [...zonesArray];

    let horizontalMergedCount = selectedZone.mergedZonesCount;
    let verticalMergedCount = selectedZone.verticalmergedZonesCount;
    const indexes = selectedZone.id.split("");
    const selectedRowIndex = indexes[0];
    const totalrowsIndex = parseInt(selectedRowIndex) + verticalMergedCount;
    const mergeZonesDetails = zonesSizes.find(
      (item) => item.selectValue == matrix
    );

    if (horizontalMergedCount > 1) {
      for (let i = selectedRowIndex; i < totalrowsIndex; i++) {
        // Reset selected zones
        const zoneStartBoxIndex = parseInt(selectedZone.initialId.split("")[1]);
        const zoneLastBoxIndex = zoneStartBoxIndex + horizontalMergedCount;
        [...Tempitems[i]].map((item, index) => {
          const itemInitialColumn = parseInt(item.initialId.split("")[1]);
          if (
            zoneStartBoxIndex <= itemInitialColumn &&
            itemInitialColumn < zoneLastBoxIndex
          ) {
            Tempitems[i][index].mergedZonesCount = 1;
            Tempitems[i][index].content = "";
            Tempitems[i][index].isActive = false;
            Tempitems[i][index].color = dndProps.diableColor;
            Tempitems[i][index].textColor = zonesDefaultColor.font;
          }
        });
        Tempitems[i].sort((a, b) => (a.initialId > b.initialId ? 1 : -1));
        // Reset selected zones

        // Fill empty spaces
        let remainingIndexes = Array.from(Array(mergeZonesDetails.x).keys());
        [...Tempitems[i]].map((item, index) => {
          const itemInitialColumn = parseInt(item.initialId.split("")[1]);
          const itemIndex = remainingIndexes.indexOf(itemInitialColumn);
          if (itemIndex > -1) remainingIndexes.splice(itemIndex, 1);

          if (item.mergedZonesCount > 1) {
            for (let k = 1; k < item.mergedZonesCount; k++) {
              const mergeIndex = remainingIndexes.indexOf(
                itemInitialColumn + k
              );
              if (index > -1) remainingIndexes.splice(mergeIndex, 1);
            }
          }
        });
        let existingBoxCountInRow = [...Tempitems[i]].reduce(
          (partialSum, a) => partialSum + a.mergedZonesCount,
          0
        );
        let remainingBoxCount =
          mergeZonesDetails.x - parseInt(existingBoxCountInRow);
        if (remainingBoxCount != remainingIndexes.length) {
          console.log(remainingBoxCount, "remainingBoxCount");
          console.log(remainingIndexes, "remainingIndexes");
        }
        remainingIndexes.map((item) => {
          let object = getBoxObject(i, item);
          Tempitems[i].push(object);
        });
        Tempitems[i].sort((a, b) => (a.initialId > b.initialId ? 1 : -1));

        // let duplicateBoxes = [];
        // if (remainingBoxCount > 0) {
        //   for (let j = zoneStartBoxIndex + 1; j < zoneLastBoxIndex; j++) {
        //     let object = getBoxObject(i, j);
        //     let foundIndex = Tempitems[i].findIndex(
        //       (x) => x.initialId === object.initialId
        //     );

        //     if (foundIndex > -1) {
        //       duplicateBoxes.push(object);
        //     } else {
        //       Tempitems[i].splice(j, 0, object);
        //     }

        //     remainingBoxCount -= 1;
        //     if (remainingBoxCount <= 0) break;
        //   }
        //   Tempitems[i].sort((a, b) => (a.initialId > b.initialId ? 1 : -1));
        // }
        // Fill empty spaces

        // Insert duplicate
        // if (duplicateBoxes.length > 0) {
        //   let missingIndexes = [];
        //   let currentInd = 0;
        // }
        // Insert duplicate

        // // Reset selected box
        // Tempitems[i][indexes[1]].mergedZonesCount = 1;
        // Tempitems[i][indexes[1]].content = "";
        // Tempitems[i][indexes[1]].isActive = false;
        // Tempitems[i][indexes[1]].color = dndProps.diableColor;
        // Tempitems[i][indexes[1]].textColor = zonesDefaultColor.font;

        // // insert new boxes
        // let boxStartColumnIndex = parseInt(indexes[1]) + 1;
        // let boxEndColumnINdex = parseInt(indexes[1]) + horizontalMergedCount;
        // for (let j = boxStartColumnIndex; j < boxEndColumnINdex; j++) {
        //   let object = getBoxObject(i, j);
        //   let foundIndex = Tempitems[i].findIndex((x) => x.id === object.id);
        //   if (foundIndex > -1) {
        //     Tempitems[i][foundIndex].mergedZonesCount = 1;
        //   }
        //   Tempitems[i].splice(j, 0, object);
        // }

        // Remove duplication from array
        // let arr = [...Tempitems[i]];
        // Tempitems[i] = [
        //   ...new Map(arr.map((item) => [item["initialId"], item])).values(),
        // ];

        // re index the boxes
        Tempitems[i].map(function (item, index) {
          item.id = `${i}${index}`;
          item.rowIndex = i;
          item.columnIndex = index;
          item.opacity = 1;
        });
        console.log(Tempitems, "unmerged");
      }
    }

    if (verticalMergedCount > 1) {
      // Reset selected box
      Tempitems[selectedRowIndex][indexes[1]].verticalmergedZonesCount = 1;
      Tempitems[selectedRowIndex][indexes[1]].content = "";
      Tempitems[selectedRowIndex][indexes[1]].isActive = false;
      Tempitems[selectedRowIndex][indexes[1]].color = dndProps.diableColor;
      Tempitems[selectedRowIndex][indexes[1]].textColor =
        zonesDefaultColor.font;
      Tempitems[selectedRowIndex][indexes[1]].zIndex = 1;
    }

    // Reset zones data
    Tempitems = resetZonesData(Tempitems);
    setZonesArray(Tempitems);
    resetWristbandZonesData();
    setSelectedZonesToMerge([]);
  };
  const onDragEnd = (result) => {
    if (result.combine) {
      let Tempitems = [...zonesArray];
      const indexes = result.combine.draggableId.split("");
      const dragIndexes = result.draggableId.split("");
      const dragcontainer = { ...Tempitems[dragIndexes[0]][dragIndexes[1]] };
      const dropContainer = { ...Tempitems[indexes[0]][indexes[1]] };
      if (result.combine.droppableId === result.source.droppableId) {
        if (
          parseInt(dragIndexes[1]) + 1 == parseInt(indexes[1]) ||
          parseInt(dragIndexes[1]) - 1 == indexes[1]
        ) {
          if (
            dropContainer.verticalmergedZonesCount !=
            dragcontainer.verticalmergedZonesCount
          ) {
            return window.alert("Zones must be of same vertical dimension");
          }
          Tempitems[indexes[0]][indexes[1]].mergedZonesCount =
            dragcontainer.mergedZonesCount + dropContainer.mergedZonesCount;
          let zoneName = dropContainer.content
            ? dropContainer.content
            : dragcontainer.content
            ? dragcontainer.content
            : "";
          Tempitems[indexes[0]][indexes[1]].isActive = zoneName ? true : false;
          Tempitems[indexes[0]][indexes[1]].color = dndProps.activeColor;
          Tempitems[indexes[0]][indexes[1]].content = zoneName;
          Tempitems[indexes[0]][indexes[1]].isCurrentMerged = true;
          Tempitems[dragIndexes[0]].splice(dragIndexes[1], 1);
          Tempitems[dragIndexes[0]].map(function (item, index) {
            item.id = `${dragIndexes[0]}${index}`;
            // item.rowIndex = index
          });
          let updatedDropZone = null;
          for (let i in Tempitems[dragIndexes[0]]) {
            if (Tempitems[dragIndexes[0]][i].isCurrentMerged == true) {
              updatedDropZone = Tempitems[dragIndexes[0]][i];
              Tempitems[dragIndexes[0]][i].isCurrentMerged = false;
              break;
            }
          }

          // Reset zones data
          Tempitems = resetZonesData(Tempitems);
          setZonesArray(Tempitems);
          handleZoneClick(updatedDropZone);
          return;
        } else {
          return window.alert(
            "Zones can only be merged with most left and most right indexes horizontally"
          );
        }
      } else {
        // return window.alert("Zones can only be merged horizontally");
        let hightCount =
          dragIndexes[0] > indexes[0]
            ? dropContainer.verticalmergedZonesCount
            : dragcontainer.verticalmergedZonesCount;
        let dragPreviousBoxes = 0;
        let dropPreviousBoxes = 0;
        for (let i = 0; i < parseInt(dragIndexes[1]); i++) {
          dragPreviousBoxes =
            dragPreviousBoxes +
            Tempitems[parseInt(dragIndexes[0])][i].mergedZonesCount;
        }
        for (let i = 0; i < parseInt(indexes[1]); i++) {
          dropPreviousBoxes =
            dropPreviousBoxes +
            Tempitems[parseInt(indexes[0])][i].mergedZonesCount;
        }
        if (
          (parseInt(dragIndexes[1]) == parseInt(indexes[1]) ||
            dragPreviousBoxes == dropPreviousBoxes) &&
          (parseInt(dragIndexes[0]) + 1 == parseInt(indexes[0]) ||
            parseInt(dragIndexes[0]) + hightCount == parseInt(indexes[0]) ||
            (parseInt(dragIndexes[0]) - -1 > -1 &&
              parseInt(dragIndexes[0]) - 1 == parseInt(indexes[0])) ||
            (parseInt(dragIndexes[0]) - hightCount > -1 &&
              parseInt(dragIndexes[0]) - hightCount == parseInt(indexes[0])))
        ) {
          if (
            dropContainer.mergedZonesCount != dragcontainer.mergedZonesCount
          ) {
            return window.alert("Zones must be of same horizontal dimension");
          }
          let hideIndexes = dragIndexes[0] > indexes[0] ? dragIndexes : indexes;
          let showIndexes = dragIndexes[0] > indexes[0] ? indexes : dragIndexes;

          Tempitems[showIndexes[0]][showIndexes[1]].verticalmergedZonesCount =
            dragcontainer.verticalmergedZonesCount +
            dropContainer.verticalmergedZonesCount;
          let zoneName = dropContainer.content
            ? dropContainer.content
            : dragcontainer.content
            ? dragcontainer.content
            : "";
          Tempitems[showIndexes[0]][showIndexes[1]].isActive = zoneName
            ? true
            : false;
          Tempitems[showIndexes[0]][showIndexes[1]].color =
            dndProps.activeColor;
          Tempitems[showIndexes[0]][showIndexes[1]].content = zoneName;
          Tempitems[showIndexes[0]][showIndexes[1]].isCurrentMerged = true;
          Tempitems[showIndexes[0]][showIndexes[1]].zIndex =
            dragcontainer.zIndex + dropContainer.zIndex;

          // Tempitems[hideIndexes[0]][hideIndexes[1]].opacity = 0;
          let updatedDropZone = Tempitems[showIndexes[0]][showIndexes[1]];

          // Reset zones data
          Tempitems = resetZonesData(Tempitems);
          setZonesArray(Tempitems);
          handleZoneClick(updatedDropZone);
          return;
        } else {
          return window.alert(
            "Zones can only be merged with most top and most bottom indexes vertically having same dimensions"
          );
        }
      }
    }
  };
  const updateSelectedZone = (e) => {
    e.preventDefault();
    let tempItems = zonesArray;
    const indexes = selectedZone.id.split("");
    const boxBGColor = selectedZoneName
      ? subscriptionMode === UserSubscriptionModes.gamming
        ? selectedZoneColor
        : getActiveZoneColor(tempItems[indexes[0]][indexes[1]].color)
      : dndProps.diableColor;
    const boxFontColor = selectedZoneName
      ? subscriptionMode === UserSubscriptionModes.gamming
        ? calculateRatio(boxBGColor, "#ffffff") < 1 / 3
          ? "#ffffff"
          : "#000000"
        : zonesDefaultColor.font
      : zonesDefaultColor.font;

    tempItems[indexes[0]][indexes[1]].content = selectedZoneName;
    tempItems[indexes[0]][indexes[1]].color = boxBGColor;
    tempItems[indexes[0]][indexes[1]].textColor = boxFontColor;
    tempItems[indexes[0]][indexes[1]].isActive = selectedZoneName
      ? true
      : false;

    // reset zones data
    tempItems = resetZonesData(tempItems);
    resetWristbandZonesData();
    setZonesArray(tempItems);
    setPrimary(false);
  };
  const getActiveZoneColor = (prevColor) => {
    if (prevColor != dndProps.diableColor) return prevColor;

    // gamming color
    if (subscriptionMode === UserSubscriptionModes.gamming) {
      return prevColor;
      // return dndProps.activeColor;
    }

    // show mode zone color
    let color = "";
    if (zoneColorIndex >= zonesColorList.length) {
      color = zonesColorList[0];
      setZoneColorIndex(0);
    } else {
      color = zonesColorList[zoneColorIndex];
      setZoneColorIndex(zoneColorIndex + 1);
    }
    return color;
  };
  const handleMatrixChange = (formattedList) => {
    let tempItems = [...zonesArray];
    const data = Object.assign({}, formattedList);

    // Reset all
    if (data && Object.keys(data).length === 0 && data.constructor === Object) {
      tempItems = resetZonesData(tempItems);
      setZonesArray(tempItems);
      return;
    }

    // Remove deleted data
    for (let i = 0; i <= tempItems.length; i++) {
      for (const j in tempItems[i]) {
        for (const k in tempItems[i][j].data) {
          if (
            tempItems[i][j].data[k] &&
            formattedList[tempItems[i][j].data[k].label] == undefined
          ) {
            tempItems[i][j].data.splice(k, 1);
          }
        }
      }
    }

    // Re assign data
    const signArr = [];
    Object.entries(data).map((item, key) => {
      signArr.push({ label: item[0], arr: item[1] });
    });
    for (const i in signArr) {
      const prePopulatedInfo = calPrePopulatedInfo(
        [...zonesArray],
        signArr[i].label
      );
      const countToPopulate = prePopulatedInfo.count;
      const indexToPopulate = prePopulatedInfo.arr;
      const zonesPrevStats = prePopulatedInfo.prevStats;
      const zonesPrevTotalPopulated = prePopulatedInfo.prevTotalPopulated;
      tempItems = reArrangeData(
        countToPopulate,
        indexToPopulate,
        signArr[i].label,
        signArr[i].arr,
        tempItems,
        zonesPrevStats,
        zonesPrevTotalPopulated == signArr[i].arr.length
      );
    }
    setZonesArray(tempItems);

    let { zonesDataInfo, colorArr } = getZonesDataInfo([...tempItems]);
    const updatedWristbandWithZoneName = updatedGridConcatination(
      zonesDataInfo,
      colorArr,
      filledWristbandPattern,
      activeSigns
    );
    setfilledWristbandPattern({
      ...filledWristbandPattern,
      gridData: updatedWristbandWithZoneName,
    });
  };

  const updateboxMatrix = (dimention) => {
    let zonesViewSettings = getBoxDimention(dimention);
    let visualdndZonesViewSettings = getVisualdndZoneDetails(dimention);
    let reArrangeModalVisualdndZonesViewSettings =
      getReArrangeModalVisualdndZoneDetails(dimention);

    setDndProps({
      ...dndProps,
      zoneInitialHeight: zonesViewSettings.height,
      zoneInitialWidth: zonesViewSettings.width,
      zonePadding: zonesViewSettings.boxSpace,
      fontSize: zonesViewSettings.fontSize,
    });
    setVidsualdndProps({
      ...vidsualdndProps,
      zoneInitialHeight: visualdndZonesViewSettings.height,
      zoneInitialWidth: visualdndZonesViewSettings.width,
      fontSize: visualdndZonesViewSettings.fontSize,
    });
    setReArrangeModalVisualdndProps({
      ...reArrangeModalVisualdndProps,
      zoneInitialHeight: reArrangeModalVisualdndZonesViewSettings.height,
      zoneInitialWidth: reArrangeModalVisualdndZonesViewSettings.width,
      fontSize: reArrangeModalVisualdndZonesViewSettings.fontSize,
    });
    setMatrix(dimention);
    setCoachCradInitialWidth(getCoachCardInitialWidth(dimention));
    generateInitialZones(dimention);
    setSelectedZonesToMerge([]);
    resetWristbandZonesData();
    setZoneColorIndex(0);
  };

  const PopulateZoneData = (box, label) => {
    // in-active
    if (box.isActive == false) return;
    let tempFormattedwristbandData = formattedwristbandData;
    let foundIndexes = tempFormattedwristbandData[label]
      ? [...tempFormattedwristbandData[label]]
      : undefined;
    let fountIndexesCunt = 0;
    if (foundIndexes != undefined) {
      fountIndexesCunt = foundIndexes.length;
    }
    // dont update
    if (fountIndexesCunt == 0) return;

    let Tempitems = [...zonesArray];
    const indexes = box.id.split("");
    let tempData = Tempitems[indexes[0]][indexes[1]].data.filter(function (el) {
      return el.label == label;
    });

    // pre populated count
    const prePopulatedInfo = calPrePopulatedInfo([...zonesArray], label);
    let countToPopulate = prePopulatedInfo.count;
    let indexToPopulate = prePopulatedInfo.arr;

    // Remove
    if (tempData.length > 0) {
      countToPopulate -= 1;
      indexToPopulate = indexToPopulate.filter((e) => e !== box.id);
      const index = Tempitems[indexes[0]][indexes[1]].data.findIndex(
        (x) => x.label === label
      );
      Tempitems[indexes[0]][indexes[1]].data.splice(index, 1);
    }
    // Insert
    else {
      countToPopulate += 1;
      indexToPopulate.push(box.id);
      if (fountIndexesCunt < countToPopulate) {
        return;
      }
    }
    if (countToPopulate > 0 && fountIndexesCunt >= countToPopulate) {
      Tempitems = reArrangeData(
        countToPopulate,
        indexToPopulate,
        label,
        foundIndexes,
        Tempitems,
        null,
        false
      );
    }
    setZonesArray(Tempitems);

    let { zonesDataInfo, colorArr } = getZonesDataInfo([...Tempitems]);
    const updatedWristbandWithZoneName = updatedGridConcatination(
      zonesDataInfo,
      colorArr,
      filledWristbandPattern,
      activeSigns
    );
    setfilledWristbandPattern({
      ...filledWristbandPattern,
      gridData: updatedWristbandWithZoneName,
    });
  };
  const handleReArrangeModalBtnClick = (signDetail) => {
    const obj = {};
    obj.sign = signDetail;
    obj.zonesCount = { list: [], total: 0 };

    const tempZonesArray = [...zonesArray];
    for (let i = 0; i <= tempZonesArray.length; i++) {
      for (const j in tempZonesArray[i]) {
        if (tempZonesArray[i][j].content) {
          const count = getPopulatedCount(
            tempZonesArray[i][j],
            signDetail.abbreviation
          );
          obj.zonesCount.total = obj.zonesCount.total + parseInt(count);
          obj.zonesCount.list.push({
            index: `${i}${j}`,
            name: tempZonesArray[i][j].content,
            val: count,
          });
        }
      }
    }
    obj.remainingCount = obj.sign.counts - obj.zonesCount.total;
    obj.signObj = signDetail;
    setRearrangeModalProps(obj);
    setShowReArrangeModal(true);
  };
  const handleReArrangeInputChange = (value, index) => {
    let tempRearrangeModalProps = { ...rearrangeModalProps };
    tempRearrangeModalProps.zonesCount.list[index].val = value;
    tempRearrangeModalProps.zonesCount.total = 0;
    for (const i in tempRearrangeModalProps.zonesCount.list) {
      const currentVal =
        tempRearrangeModalProps.zonesCount.list[i].val == "" ||
        tempRearrangeModalProps.zonesCount.list[i].val == null
          ? 0
          : parseInt(tempRearrangeModalProps.zonesCount.list[i].val);
      tempRearrangeModalProps.zonesCount.total =
        tempRearrangeModalProps.zonesCount.total + currentVal;
    }
    tempRearrangeModalProps.remainingCount =
      tempRearrangeModalProps.sign.counts -
      tempRearrangeModalProps.zonesCount.total;
    setRearrangeModalProps(tempRearrangeModalProps);
  };
  const handleRearrangeModalSave = () => {
    let tempRearrangeModalProps = { ...rearrangeModalProps };
    let zonesCountAarray = tempRearrangeModalProps.zonesCount.list;
    let tempItems = [...zonesArray];
    let foundIndexes = [
      ...formattedwristbandData[tempRearrangeModalProps.sign.abbreviation],
    ];
    foundIndexes.sort(() => (Math.random() > 0.5 ? 1 : -1));
    for (let i in zonesCountAarray) {
      let dividedLabelArr =
        zonesCountAarray[i].val == "" ||
        zonesCountAarray[i].val == null ||
        zonesCountAarray[i].val == 0
          ? []
          : foundIndexes.splice(0, zonesCountAarray[i].val);
      let BoxPosition = zonesCountAarray[i].index.split("");
      let updateIndex = tempItems[BoxPosition[0]][
        BoxPosition[1]
      ].data.findIndex(
        (x) =>
          x.label.toLowerCase() ===
          tempRearrangeModalProps.sign.abbreviation.toLowerCase()
      );

      let obj = {
        label: tempRearrangeModalProps.sign.abbreviation,
        arr: dividedLabelArr,
      };

      // remove incase to greaterselected boxes
      if (dividedLabelArr.length == 0 && updateIndex > -1) {
        tempItems[BoxPosition[0]][BoxPosition[1]].data.splice(updateIndex, 1);
      } else if (dividedLabelArr.length > 0) {
        if (tempItems[BoxPosition[0]][BoxPosition[1]].data.length == 0) {
          tempItems[BoxPosition[0]][BoxPosition[1]].data.push(obj);
        } else {
          if (updateIndex > -1) {
            tempItems[BoxPosition[0]][BoxPosition[1]].data[updateIndex] = obj;
          } else {
            tempItems[BoxPosition[0]][BoxPosition[1]].data.push(obj);
          }
        }
      }
    }
    setZonesArray(tempItems);
    let { zonesDataInfo, colorArr } = getZonesDataInfo([...tempItems]);
    let updatedWristbandWithZoneName = updatedGridConcatination(
      zonesDataInfo,
      colorArr,
      filledWristbandPattern,
      activeSigns
    );
    setfilledWristbandPattern({
      ...filledWristbandPattern,
      gridData: updatedWristbandWithZoneName,
    });
    setShowReArrangeModal(false);
  };
  // Zones Methods end

  const getPrintDimension = (tableType) => {
    let arr = [];
    if (tableType == 54) arr = [400, 500, 600, 700, 800, 900, 1000];
    else if (tableType == 96) arr = [500, 600, 700, 800, 900, 1000];
    else arr = [600, 700, 800, 900, 1000];
    setDimensionDropdownVal(arr);
  };

  const PrettoSlider = withStyles({
    root: {
      color: "#6ee0de",
      height: 0,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

  const handleNavigate = (e) => {
    let elm = e.target;
    setSelectedZonesToMerge([]);
    if (elm.id == "btn-previous") {
      if (activeSection == 4) handlePrevNavigation();
      else setShowPrevConfirmationModal(true);
    } else if (elm.id == "btn-next") {
      let nextActive = activeSection + 1;
      // Validate ateast one zone activation
      if (nextActive == 2 && bandType == "pitching") {
        if (validateZoneSelection(zonesArray)) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          return setactiveSection(nextActive);
        } else {
          return setShowZonesSelectionAlertModal(true);
        }
      }

      // update edit grid font size
      if (nextActive == 3 && bandId) {
        let updatedWristbandWithFontSize = updatedGridBoxFont(
          filledWristbandPattern.gridData,
          filledWristbandPattern.size
        );
        setfilledWristbandPattern({
          ...filledWristbandPattern,
          gridData: updatedWristbandWithFontSize,
        });
      }

      // Signs population check on each active zone
      if (nextActive == 4 && bandType == "pitching" && activeSigns.length > 0) {
        if (validateZonePopulation(zonesArray, activeSigns)) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          return setactiveSection(nextActive);
        } else {
          return setShowZonesPopulationAlertModal(true);
        }
      }
      if (nextActive > 4) {
        nextActive = 4;
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      setactiveSection(nextActive);
    }
  };
  const handlePrevNavigation = () => {
    let previousActive = activeSection - 1;
    if (previousActive < StartIndex) {
      previousActive = StartIndex;
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    setactiveSection(previousActive);
    setShowPrevConfirmationModal(false);
  };

  const gridColorHandler = (e) => {
    let elm = e.target;
    let gridBgChoosedColor = elm.getAttribute("grid_bg_color");
    let borderColor = "";

    if (gridBgChoosedColor == "custom_color") {
      setgridBgColor(elm.value);
      borderColor =
        calculateRatio(elm.value, "#ffffff") < 1 / 3 ? "#ffffff" : "#000000";

      setemptyWristbandPattern({
        ...emptyWristbandPattern,
        bgColor: elm.value,
        border_color: borderColor,
      });
      setfilledWristbandPattern({
        ...filledWristbandPattern,
        bgColor: elm.value,
        border_color: borderColor,
      });
      setgridborderColor(borderColor);
    } else {
      setgridBgColor(gridBgChoosedColor);
      borderColor =
        calculateRatio(gridBgChoosedColor, "#ffffff") < 1 / 3
          ? "#ffffff"
          : "#000000";

      setemptyWristbandPattern({
        ...emptyWristbandPattern,
        bgColor: gridBgChoosedColor,
        border_color: borderColor,
      });
      setfilledWristbandPattern({
        ...filledWristbandPattern,
        bgColor: gridBgChoosedColor,
        border_color: borderColor,
      });
      setgridborderColor(borderColor);
    }
  };

  // const gridBorderColorHandler = (e) => {
  //   let elm = e.target;
  //   let gridBorderChoosedColor = elm.getAttribute("grid_border_color");

  //   if (gridBorderChoosedColor == "custom_border_color") {
  //     setgridborderColor(elm.value);
  //     setemptyWristbandPattern({
  //       ...emptyWristbandPattern,
  //       border_color: elm.value,
  //     });
  //     setfilledWristbandPattern({
  //       ...filledWristbandPattern,
  //       border_color: elm.value,
  //     });
  //   } else {
  //     setgridborderColor(gridBorderChoosedColor);
  //     setemptyWristbandPattern({
  //       ...emptyWristbandPattern,
  //       border_color: gridBorderChoosedColor,
  //     });
  //     setfilledWristbandPattern({
  //       ...filledWristbandPattern,
  //       border_color: gridBorderChoosedColor,
  //     });
  //   }
  // };

  const gridSizeHandler = (e) => {
    let elm = e.target;
    let elmVal = elm.value;

    if (elmVal == 54) {
      let gridData = resetWristBandArray({ ...fillWristbandPattern54 });
      setemptyWristbandPattern({
        ...emptyWristbandPattern54,
        bgColor: gridBgColor,
        border_color: gridborderColor,
      });
      setfilledWristbandPattern({
        ...fillWristbandPattern54,
        bgColor: gridBgColor,
        border_color: gridborderColor,
        gridData: gridData,
      });
    } else if (elmVal == 96) {
      let gridData = resetWristBandArray({ ...fillWristbandPattern96 });
      setemptyWristbandPattern({
        ...emptyWristbandPattern96,
        border_color: gridborderColor,
        bgColor: gridBgColor,
      });
      setfilledWristbandPattern({
        ...fillWristbandPattern96,
        bgColor: gridBgColor,
        border_color: gridborderColor,
        gridData: gridData,
      });
    } else if (elmVal == 150) {
      let gridData = resetWristBandArray({ ...fillWristbandPattern150 });
      setemptyWristbandPattern({
        ...emptyWristbandPattern150,
        border_color: gridborderColor,
        bgColor: gridBgColor,
      });
      setfilledWristbandPattern({
        ...fillWristbandPattern150,
        bgColor: gridBgColor,
        border_color: gridborderColor,
        gridData: gridData,
      });
    } else if (elmVal == 216) {
      let gridData = resetWristBandArray({ ...fillWristbandPattern216 });
      setemptyWristbandPattern({
        ...emptyWristbandPattern216,
        border_color: gridborderColor,
        bgColor: gridBgColor,
      });
      setfilledWristbandPattern({
        ...fillWristbandPattern216,
        bgColor: gridBgColor,
        border_color: gridborderColor,
        gridData: gridData,
      });
    }
    getPrintDimension(elmVal);
    const tempsigns = [...signs];
    for (const i in tempsigns) {
      tempsigns[i].status = false;
      tempsigns[i].is_locked = false;
      tempsigns[i].isColorOverride = false;
    }
    setSigns(tempsigns);
    setactiveSigns([]);
    setZonesArray(resetZonesData(zonesArray));
  };

  const fillWristbandPattern = (e) => {
    const elm = e.target;
    const [elementAbbr, elementId] = elm.id.split("-");

    // validate duplicate
    const duplicateIndex = activeSigns.findIndex(
      (x) =>
        x.abbreviation.toLowerCase() === elementAbbr.toLowerCase() &&
        x.id != elementId
    );
    if (duplicateIndex > -1) {
      setSignAbbreviationToReplace({
        abbreviation: elementAbbr,
        id: elementId,
      });
      return setReplaceSignConfirm(true);
    }
    // validate duplicate

    const tempSigns = [];
    const activeTempSigns = [];
    signs.forEach(function (params, index) {
      const currentParam = { ...params };
      if (elementId == params.id) {
        currentParam.status = elm.checked ? true : false;
        currentParam.is_locked = false;
        currentParam.isColorOverride = false;
      } else if (currentParam.status == false) {
        currentParam.is_locked = false;
        currentParam.isColorOverride = false;
      }
      tempSigns.push(currentParam);
      if (currentParam.status) activeTempSigns.push(currentParam);
      // if (elementId == params.id && elm.checked) {
      //   tempSigns[index].status = true;
      // } else if (elementId == params.id && !elm.checked) {
      //   tempSigns[index].status = false;
      //   tempSigns[index].is_locked = false;
      // }
    });

    setactiveSigns(activeTempSigns);
    setSigns(tempSigns);

    if (activeTempSigns.length) {
      RenderActiveSignsOnTable(activeTempSigns);
    } else {
      setZonesArray(resetZonesData(zonesArray));
      const updated = resetWristBandArray(filledWristbandPattern);
      setfilledWristbandPattern({
        ...filledWristbandPattern,
        gridData: updated,
      });
    }
  };

  const replaceSign = () => {
    const tempSigns = [];
    const activeTempSigns = [];
    signs.forEach(function (params, index) {
      const currentParam = { ...params };
      if (
        params.abbreviation.toLowerCase() ==
        signAbbreviationToReplace.abbreviation.toLowerCase()
      ) {
        currentParam.status = params.id == signAbbreviationToReplace.id;
        currentParam.is_locked = false;
        currentParam.isColorOverride = false;
      } else if (currentParam.status == false) {
        currentParam.is_locked = false;
        currentParam.isColorOverride = false;
      }
      tempSigns.push(currentParam);
      if (currentParam.status) activeTempSigns.push(currentParam);
    });
    setactiveSigns(activeTempSigns);
    setSigns(tempSigns);

    RenderActiveSignsOnTable(
      activeTempSigns,
      true,
      signAbbreviationToReplace.abbreviation
    );

    setSignAbbreviationToReplace(null);
    setReplaceSignConfirm(false);
  };

  const RenderActiveSignsOnTable = (
    activeTempSigns,
    isReplace = false,
    replaceAbbreviation = ""
  ) => {
    setshowSlider(true);
    const waritableWristbandPatternSize = filledWristbandPattern.emptySize;

    // locked details
    let lockedCounts = 0;
    let lockedSigns = 0;
    let allLocked = true;
    activeTempSigns.forEach((item) => {
      if (item.is_locked == true) {
        lockedCounts += item.counts;
        lockedSigns++;
      } else {
        allLocked = false;
      }
    });
    if (allLocked == true && lockedCounts == waritableWristbandPatternSize)
      allLocked = false;
    if (allLocked == true) {
      lockedCounts = 0;
      lockedSigns = 0;
    }

    const unlockedPatternSize = waritableWristbandPatternSize - lockedCounts;
    const unlockedSignsSize = activeTempSigns.length - lockedSigns;
    const signsPerSection = Math.floor(unlockedPatternSize / unlockedSignsSize);
    const unlockedSignsForSection = signsPerSection * unlockedSignsSize;
    let signDiff =
      waritableWristbandPatternSize - unlockedSignsForSection - lockedCounts;
    const signsAppendedIterations = [];

    activeTempSigns.forEach((item) => {
      signsAppendedIterations[item.abbreviation] = 0;
      if (allLocked == true || item.is_locked != true) {
        let counts = signsPerSection;
        if (signDiff != 0) {
          counts += 1;
          signDiff--;
        }
        item.counts = counts;
      }
      item.percentage = (
        (item.counts / waritableWristbandPatternSize) *
        100
      ).toFixed(2);
    });

    if (activeTempSigns.length === 1) {
      activeTempSigns[0].counts = waritableWristbandPatternSize;
      activeTempSigns[0].percentage = "100";
    }

    generateWristbandByActiveSigns(
      activeTempSigns,
      signsAppendedIterations,
      isReplace,
      replaceAbbreviation
    );
  };

  const changeWristbandPatternBySlider = (e, val, currentItem) => {
    if (activeSigns.length > 1) {
      let lockedPercentage = 0;
      let totalSignsLocked = 0;
      const gridEmptySize = filledWristbandPattern.emptySize;
      const abbr = currentItem.abbreviation;
      const isCurrentItemIsLocked = currentItem.is_locked;
      if (!isCurrentItemIsLocked) {
        for (const i in activeSigns) {
          const activeSignItem = activeSigns[i];
          if (activeSignItem.is_locked) {
            lockedPercentage += parseFloat(activeSignItem.percentage);
            totalSignsLocked++;
          }
        }
        const lockedCount = Math.round(
          (lockedPercentage * gridEmptySize) / 100
        );
        if (val <= 100 - lockedPercentage) {
          let currentSlideCounts = Math.round((val * gridEmptySize) / 100);
          const tempSigns = [];
          setshowSlider(true);
          let waritableWristbandPatternSize =
            gridEmptySize - (lockedCount ? lockedCount : 0);
          waritableWristbandPatternSize -= currentSlideCounts;
          let activeSignsSize = activeSigns.length - 1;
          if (totalSignsLocked) activeSignsSize -= totalSignsLocked;

          if (activeSignsSize == 0) {
            currentSlideCounts = Math.fround(
              currentSlideCounts + waritableWristbandPatternSize
            );
            activeSignsSize = 1;
            val = ((currentSlideCounts * 100) / gridEmptySize).toFixed(2);
          }

          const signsPerSection = Math.floor(
            waritableWristbandPatternSize / activeSignsSize
          );
          const totalSignForAllSection = signsPerSection * activeSignsSize;
          let signDiff = waritableWristbandPatternSize - totalSignForAllSection;

          // populate count in signs list
          let currentSignActiveIndex = -1;
          let totalPercentage = 0;
          let totalSignsCount = 0;
          const signsAppendedIterations = [];
          activeSigns.forEach((item, activeSignIndex) => {
            signsAppendedIterations[item.abbreviation] = 0;
            if (item.abbreviation.toLowerCase() == abbr.toLowerCase()) {
              currentSignActiveIndex = activeSignIndex;
              item.counts = currentSlideCounts;
              item.percentage = val;
            } else if (!item.is_locked) {
              let counts = signsPerSection;
              if (signDiff != 0) {
                counts += 1;
                signDiff--;
              }
              item.counts = counts;
              item.percentage = ((counts / gridEmptySize) * 100).toFixed(2);
            }
            totalPercentage += parseFloat(item.percentage);
            totalSignsCount += parseFloat(item.counts);
          });

          // console.log("totalPercentage", totalPercentage);
          // console.log("totalPlusCount", totalSignsCount);

          if (currentSignActiveIndex > -1) {
            if (totalSignsCount != gridEmptySize) {
              const remainingCount = gridEmptySize - totalSignsCount;
              const deductCount =
                parseFloat(activeSigns[currentSignActiveIndex].counts) +
                remainingCount;
              activeSigns[currentSignActiveIndex].counts =
                deductCount < 0 ? 0 : deductCount;
            }

            if (totalPercentage != 100) {
              const remainingPercentage = 100 - totalPercentage;
              const deductVal = (
                parseFloat(activeSigns[currentSignActiveIndex].percentage) +
                parseFloat(remainingPercentage)
              ).toFixed(2);
              activeSigns[currentSignActiveIndex].percentage =
                activeSigns[currentSignActiveIndex].counts > 0
                  ? deductVal < 0
                    ? -1 * deductVal
                    : deductVal
                  : 0;
            }
          }

          signs.forEach(function (signsItem, index) {
            tempSigns.push(signsItem);
            activeSigns.forEach(function (activeSignsItem) {
              if (
                signsItem.abbreviation.toLowerCase() ==
                activeSignsItem.abbreviation.toLowerCase()
              ) {
                tempSigns[index].counts = activeSignsItem.counts;
                tempSigns[index].percentage = activeSignsItem.percentage;
              }
            });
          });
          setSigns(tempSigns);
          generateWristbandByActiveSigns(activeSigns, signsAppendedIterations);
        } else {
          const getSigns = [...signs];
          setSigns(getSigns);
          setShowLimitExceedModal(true);
        }
      } else {
        const getSigns = [...signs];
        setSigns(getSigns);
      }
    } else {
      window.alert("At least 2 signs should be active");
    }
  };

  const close = () => {
    setRemoveSignModalProps({
      ...removeSignModalProps,
      show: false,
      signName: "",
    });
  };

  const openModal = (signName) => {
    setRemoveSignModalProps({
      ...removeSignModalProps,
      show: true,
      signName: signName,
    });
  };

  const removeSign = (signName) => {
    const tempSigns = [];
    const activeTempSigns = [];
    signs.forEach(function (params, index) {
      tempSigns.push(params);
      if (signName.toLowerCase() == params.abbreviation.toLowerCase()) {
        tempSigns[index].status = false;
        tempSigns[index].is_locked = false;
        tempSigns[index].isColorOverride = false;
      }

      if (params.status) {
        activeTempSigns.push(params);
      }
    });
    setactiveSigns(activeTempSigns);
    setSigns(tempSigns);

    if (activeTempSigns.length) {
      setshowSlider(true);
      const waritableWristbandPatternSize = filledWristbandPattern.emptySize;

      // locked details
      let lockedCounts = 0;
      let lockedSigns = 0;
      let allLocked = true;
      activeTempSigns.forEach((item) => {
        if (item.is_locked == true) {
          lockedCounts += item.counts;
          lockedSigns++;
        } else {
          allLocked = false;
        }
      });
      if (allLocked == true && lockedCounts == waritableWristbandPatternSize)
        allLocked = false;
      if (allLocked == true) {
        lockedCounts = 0;
        lockedSigns = 0;
      }

      const unlockedPatternSize = waritableWristbandPatternSize - lockedCounts;
      const unlockedSignsSize = activeTempSigns.length - lockedSigns;
      const signsPerSection = Math.floor(
        unlockedPatternSize / unlockedSignsSize
      );
      const unlockedSignsForSection = signsPerSection * unlockedSignsSize;
      let signDiff =
        waritableWristbandPatternSize - unlockedSignsForSection - lockedCounts;
      const signsAppendedIterations = [];

      activeTempSigns.forEach((item) => {
        signsAppendedIterations[item.abbreviation] = 0;
        if (allLocked == true || item.is_locked != true) {
          let counts = signsPerSection;
          if (signDiff != 0) {
            counts += 1;
            signDiff--;
          }
          item.counts = counts;
        }
        item.percentage = (
          (item.counts / waritableWristbandPatternSize) *
          100
        ).toFixed(2);
      });

      if (activeTempSigns.length === 1) {
        activeTempSigns[0].counts = waritableWristbandPatternSize;
        activeTempSigns[0].percentage = "100";
      }
      generateWristbandByActiveSigns(activeTempSigns, signsAppendedIterations);
    } else {
      setZonesArray(resetZonesData(zonesArray));
      const updated = resetWristBandArray(filledWristbandPattern);
      setfilledWristbandPattern({
        ...filledWristbandPattern,
        gridData: updated,
      });
    }
    setRemoveSignModalProps({
      ...removeSignModalProps,
      show: false,
      signName: "",
    });
  };

  const generateWristbandByActiveSigns = (
    signsList,
    signsAppendedIterations,
    isReplace = false,
    replaceAbbreviation = ""
  ) => {
    const resp = getWristbandPopulatedData(
      filledWristbandPattern,
      signsList,
      signsAppendedIterations
    );
    handleMatrixChange(resp.formattedData2);
    setFormattedwristbandData(resp.formattedData);
    const signArr = [];
    Object.entries(resp.formattedData).map((item, key) => {
      signArr.push({ label: item[0], arr: item[1] });
    });
    setSignsIndexesAarr(signArr);
    let resultGrid = resp.updatedWristbandPattern;
    if (isReplace) {
      resultGrid = resetAbbreviationOverrideColor(
        { ...filledWristbandPattern, gridData: resultGrid },
        replaceAbbreviation
      );
    }

    setfilledWristbandPattern({
      ...filledWristbandPattern,
      gridData: resultGrid,
    });
  };

  const populateSigns = async () => {
    // setTableProps({ ...tableProps, loading: true, data: [] })
    getSigns(`?category=${bandType}&user_id=${defaultUserId}`)
      .then((result) => {
        // setTableProps({ ...tableProps, loading: false, })
        if (result.data.status == true) {
          const list = result.data.data;
          for (const i in list) {
            list[i].is_locked = false;
            list[i].isColorOverride = false;
          }
          setSigns(list);
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        // setTableProps({ ...tableProps, loading: false })
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };

  const saveRecord = (success_callback) => {
    let wristbandJson = {};
    wristbandJson.type = bandType;
    wristbandJson.wristBandDetails = filledWristbandPattern;
    wristbandJson.signs = signs;
    wristbandJson.opponent = wristbandOpponent;

    let formattedList = [];
    Object.entries(formattedwristbandData).map((item, key) => {
      formattedList.push({ label: item[0], arr: item[1] });
    });

    wristbandJson.signsIndexesArr = formattedList;
    if (bandType == wristBandType.pitching) {
      wristbandJson.zonesDetails = {
        type: matrix,
        data: zonesArray,
        isMirrorView: isMirrorView,
        formattedwristbandData: formattedList,
      };
    }
    let param = {
      name: bandName,
      color: filledWristbandPattern.bgColor,
      wristband_json: JSON.stringify(wristbandJson),
      user_id: defaultUserId,
      mode: subscriptionMode,
    };
    dispatch({ type: UPDATE_LOADING, payload: true });

    if (bandId) {
      updateWristBand(bandId, param)
        .then((result) => {
          dispatch({ type: UPDATE_LOADING, payload: false });
          if (result.data.status == true) {
            alert.success(APP_ERROR_MSGS.SaveMsg);
            // history.push("/dashboard")
          } else {
            alert.error(
              result.data.message
                ? result.data.message
                : APP_ERROR_MSGS.StandardErrorMsg
            );
          }
        })
        .catch((error) => {
          dispatch({ type: UPDATE_LOADING, payload: false });
          alert.error(
            error?.response?.data?.error
              ? error?.response?.data?.error
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        });
    } else {
      createWristBand(param)
        .then((result) => {
          dispatch({ type: UPDATE_LOADING, payload: false });
          if (result.data.status == true) {
            alert.success(APP_ERROR_MSGS.SaveMsg);
            setIsBandSaved(true);
            setBandId(result.data.data.id);
            success_callback(result.data.data.id);
            // history.push("/dashboard")
          } else {
            alert.error(
              result.data.message
                ? result.data.message
                : APP_ERROR_MSGS.StandardErrorMsg
            );
          }
        })
        .catch((error) => {
          dispatch({ type: UPDATE_LOADING, payload: false });
          alert.error(
            error?.response?.data?.error
              ? error?.response?.data?.error
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        });
    }
  };

  const getBandDetails = async () => {
    dispatch({ type: UPDATE_LOADING, payload: true });
    getWristBandDetail(bandId)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          let bandDetail = JSON.parse(result.data.data.wristband_json);

          if (bandDetail.type == wristBandType.pitching) {
            setMatrix(bandDetail.zonesDetails.type);
            setCoachCradInitialWidth(
              getCoachCardInitialWidth(bandDetail.zonesDetails.type)
            );
            updateboxMatrix(bandDetail.zonesDetails.type);
            setIsMirrorView(
              bandDetail.zonesDetails.isMirrorView
                ? bandDetail.zonesDetails.isMirrorView
                : false
            );
          }
          setWristbandOpponent(bandDetail.opponent ? bandDetail.opponent : "");
          setSigns(bandDetail.signs);
          let activeSignsList = [...bandDetail.signs];
          // filter active
          activeSignsList = activeSignsList.filter(function (el) {
            return el.status == true;
          });
          setactiveSigns(activeSignsList);
          setfilledWristbandPattern(bandDetail.wristBandDetails);
          let emptySize = bandDetail.wristBandDetails.emptySize;
          let emptyDefaultColor = bandDetail.wristBandDetails.bgColor;
          if (emptySize == 54) {
            setemptyWristbandPattern({
              ...emptyWristbandPattern54,
              bgColor: emptyDefaultColor,
            });
          } else if (emptySize == 96) {
            setemptyWristbandPattern({
              ...emptyWristbandPattern96,
              bgColor: emptyDefaultColor,
            });
          } else if (emptySize == 150) {
            setemptyWristbandPattern({
              ...emptyWristbandPattern150,
              bgColor: emptyDefaultColor,
            });
          } else if (emptySize == 216) {
            setemptyWristbandPattern({
              ...emptyWristbandPattern216,
              bgColor: emptyDefaultColor,
            });
          }
          getPrintDimension(emptySize);
          setSignsIndexesAarr(bandDetail.signsIndexesArr);
          let formattedlist = bandDetail.signsIndexesArr;
          let tempArr = [];
          for (let i in formattedlist) {
            for (let j in formattedlist[i].arr) {
              if (tempArr[formattedlist[i].label]) {
                tempArr[formattedlist[i].label].push(formattedlist[i].arr[j]);
              } else {
                tempArr[formattedlist[i].label] = [formattedlist[i].arr[j]];
              }
            }
          }
          setFormattedwristbandData(tempArr);
          if (bandDetail.type == wristBandType.pitching) {
            setZonesArray(bandDetail.zonesDetails.data);
          }
        } else {
          history.push("/dashboard");
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        history.push("/dashboard");
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };

  const setPrintDimension = (val) => {
    setSelectedDimension(val);
    setPrintWidth(downloadPrintDimensions[val].width);
    setPrintHeight(downloadPrintDimensions[val].height);
  };
  const AddNewSign = () => {
    setSelectedRow({
      id: "",
      name: "",
      abbreviation: "",
      color: "#FF0000",
      category: bandType,
    });
    setSelectedAbbreviation("");
    setIsOnlyColor(false);
    setAbbreviationExistError(false);
    setIsZoneColorOverride(false);
    setShowEditModal(true);
  };
  const handleSelectedDataChange = (field, val) => {
    setSelectedRow({ ...selectedRow, [field]: val });
  };
  const getParam = () => {
    let param = {
      name: selectedRow.name,
      abbreviation: selectedRow.abbreviation,
      category: selectedRow.category,
      color: selectedRow.color,
      user_id: defaultUserId,
    };
    return param;
  };
  const handleRecordSubmit = async (e) => {
    e.preventDefault();
    const param = getParam();
    if (selectedRow.id) {
      // if (
      //   selectedRow.abbreviation.toLowerCase() !=
      //     selectedAbbreviation.toLowerCase() &&
      //   checkIfAbbreviationExists(filterlist, selectedRow.abbreviation)
      // ) {
      //   setShowEditModal(false);
      //   setAbbreviationExistErrorMsg(null);
      //   setMsgRedirectToSignForm(true);
      //   setAbbreviationExistError(true);
      //   return;
      // }

      // validate duplicate
      const duplicateIndex = activeSigns.findIndex(
        (x) =>
          x.abbreviation.toLowerCase() === param.abbreviation.toLowerCase() &&
          x.id != selectedRow.id
      );
      if (duplicateIndex > -1) {
        setShowEditModal(false);
        setAbbreviationExistErrorMsg(
          "The abbreviation you have entered already selected, Please unselect to update sign with duplicate abbreviation."
        );
        setMsgRedirectToSignForm(true);
        setAbbreviationExistError(true);
        return;
      }
      // validate duplicate

      setAbbreviationExistError(false);
      setShowEditModal(false);
      dispatch({ type: UPDATE_LOADING, payload: true });
      handleUpdateSign(param);
    } else {
      // if (checkIfAbbreviationExists(filterlist, selectedRow.abbreviation)) {
      //   setShowEditModal(false);
      //   setAbbreviationExistErrorMsg(null);
      //   setMsgRedirectToSignForm(true);
      //   setAbbreviationExistError(true);
      //   return;
      // }

      setAbbreviationExistError(false);
      setShowEditModal(false);
      dispatch({ type: UPDATE_LOADING, payload: true });
      handleCreateSign(param);
    }
  };
  const handleUpdateSign = (param) => {
    updateSign(selectedRow.id, param)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          populateAbbriviations();
          triggerAlertMessage("Success", APP_ERROR_MSGS.SaveMsg);
          // alert.success(APP_ERROR_MSGS.SaveMsg);
        }
        // else {
        // const errorMessage = result.data.message
        //   ? result.data.message
        //   : APP_ERROR_MSGS.StandardErrorMsg;
        // // alert.error(errorMessage);
        // triggerAlertMessage("Error", errorMessage);
        // }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        // alert.error(
        //   error?.response?.data?.error
        //     ? error?.response?.data?.error
        //     : APP_ERROR_MSGS.StandardErrorMsg
        // );
      });

    // update signs array
    const previousSigns = [...signs];
    for (const i in previousSigns) {
      if (previousSigns[i].id == selectedRow.id) {
        previousSigns[i].name = param.name;
        previousSigns[i].abbreviation = param.abbreviation;
        previousSigns[i].color = param.color;
        previousSigns[i].isColorOverride = isZoneColorOverride;
        break;
      }
    }
    setSigns(previousSigns);

    // update active signs array
    const previousActiveSigns = [...activeSigns];
    for (const i in previousActiveSigns) {
      if (previousActiveSigns[i].id == selectedRow.id) {
        previousActiveSigns[i].name = param.name;
        previousActiveSigns[i].abbreviation = param.abbreviation;
        previousActiveSigns[i].color = param.color;
        previousActiveSigns[i].isColorOverride = isZoneColorOverride;
        break;
      }
    }
    setactiveSigns(previousActiveSigns);

    // update grid array
    const updatedWristbandWithZoneName = updatedGridSign(
      selectedAbbreviation,
      param.abbreviation,
      param.color,
      filledWristbandPattern,
      previousActiveSigns
    );
    setfilledWristbandPattern({
      ...filledWristbandPattern,
      gridData: updatedWristbandWithZoneName,
    });

    // update formatted array
    const formattedList = [];
    Object.entries(formattedwristbandData).map((item, key) => {
      const label =
        item[0].toLocaleLowerCase() == selectedAbbreviation.toLocaleLowerCase()
          ? param.abbreviation
          : item[0];
      formattedList.push({ label, arr: item[1] });
    });
    const processedList = [];
    for (const i in formattedList) {
      processedList[formattedList[i]["label"]] = formattedList[i].arr;
    }
    setFormattedwristbandData(processedList);
    setSignsIndexesAarr(formattedList);

    // update zones array
    let Tempitems = [...zonesArray];
    Tempitems = updateZonesData(
      Tempitems,
      selectedAbbreviation,
      param.abbreviation
    );
    setZonesArray(Tempitems);
  };
  const handleCreateSign = (param) => {
    createSign(param)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          populateAbbriviations();
          // alert.success(APP_ERROR_MSGS.SaveMsg);
          triggerAlertMessage("Success", APP_ERROR_MSGS.SaveMsg);
          const obj = result.data.data;

          // validate duplicate
          const duplicateIndex = activeSigns.findIndex(
            (x) =>
              x.abbreviation.toLowerCase() === obj.abbreviation.toLowerCase()
          );
          // validate duplicate

          // insert new record in array
          obj.status = duplicateIndex > -1 ? false : true;
          obj.counts = 0;
          obj.percentage = "0";
          obj.isColorOverride = isZoneColorOverride;

          const previousSigns = [...signs];
          previousSigns.push(obj);
          setSigns(previousSigns);

          if (duplicateIndex === -1) {
            const previousActiveSigns = [...activeSigns];
            previousActiveSigns.push(obj);
            setactiveSigns(previousActiveSigns);
            RenderActiveSignsOnTable(previousActiveSigns);
          }
        } else {
          const errorMessage = result.data.message
            ? result.data.message
            : APP_ERROR_MSGS.StandardErrorMsg;
          // alert.error(errorMessage);
          triggerAlertMessage("Error", errorMessage);
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        const errorMessage = error?.response?.data?.error
          ? error?.response?.data?.error
          : APP_ERROR_MSGS.StandardErrorMsg;
        // alert.error(errorMessage);
        triggerAlertMessage("Error", errorMessage);
      });
  };

  const editSignColor = (signDetail) => {
    setSelectedRow(signDetail);
    setSelectedAbbreviation(signDetail.abbreviation);
    setIsOnlyColor(false);
    setAbbreviationExistError(false);
    setIsZoneColorOverride(
      signDetail.isColorOverride ? signDetail.isColorOverride : false
    );
    setShowEditModal(true);
  };

  const validateDownload = async (count = 1) => {
    try {
      const isLimitExceeded = await checkPdfDownloadLimitExceeded(count);
      const { message, status } = isLimitExceeded.data;
      if (!status) alert.info(message);
      return status;
    } catch (error) {
      alert.error("Something went wrong while validating download limit");
      return false;
    }
  };

  const handlePdfDownloadClick = async (type) => {
    const downloadAllowed =
      type === "coachCard" || type === "collegeCard"
        ? true
        : await validateDownload();

    if (!downloadAllowed) return;

    if (bandId) {
      validatePdfDownload(type);
    } else {
      saveRecord((id) => {
        validatePdfDownload(type);
      });
    }
  };
  const validatePdfDownload = (type) => {
    if (type == "playerCard") {
      dispatch({ type: UPDATE_LOADING, payload: true });
      setTimeout(() => {
        setSelectedPdfFileType(type);
      }, 200);
      // processPdf();
    }
    if (type == "coachCard") {
      setIsShowQR(false);
      setPdfName("");
      setLayoutType(coachCardLayoutType.overThenDown);
      setShowPdfNameModal(true);
    }
    if (type == "pitcherCard") {
      dispatch({ type: UPDATE_LOADING, payload: true });
      setTimeout(() => {
        setSelectedPdfFileType(type);
      }, 200);
      // processPdf();
    }
    if (type == "catcherCard") {
      dispatch({ type: UPDATE_LOADING, payload: true });
      setTimeout(() => {
        setSelectedPdfFileType(type);
      }, 200);
      // processPdf();
    }
    if (type == "collegeCard") {
      setIsShowQR(false);
      setShowQRConfirmationModal(true);
    }
  };
  const onPdfRender = () => {
    if (pdfButtonRef.current) {
      pdfButtonRef.current.click();
      if (selectedPdfFileType === "coachCard") {
        if (layoutType == coachCardLayoutType.both) {
          setLayoutType(coachCardLayoutType.overThenDown);
          setIsReverseIndexes(true);
        } else {
          setSelectedPdfFileType(null);
          dispatch({ type: UPDATE_LOADING, payload: false });
        }
      } else {
        setSelectedPdfFileType(null);
        dispatch({ type: UPDATE_LOADING, payload: false });
      }
    }
  };

  // const processPdf = () => {
  //   setTimeout(() => {
  //     pdfButtonRef.current.click();
  //     dispatch({ type: UPDATE_LOADING, payload: false });
  //     setSelectedPdfFileType(null);
  //   }, 3500);
  // };

  const handleCoachCardSubmit = async (e) => {
    e.preventDefault();

    const downloadAllowed = await validateDownload(
      layoutType == coachCardLayoutType.both ? 2 : 1
    );
    if (!downloadAllowed) return;

    setIsReverseIndexes(
      layoutType == coachCardLayoutType.overThenDown ? true : false
    );
    dispatch({ type: UPDATE_LOADING, payload: true });
    setTimeout(() => {
      setSelectedPdfFileType("coachCard");
    }, 200);

    // setTimeout(() => {
    //   pdfButtonRef.current.click();
    //   if (layoutType != coachCardLayoutType.both) {
    //     setSelectedPdfFileType(null);
    //     dispatch({ type: UPDATE_LOADING, payload: false });
    //   }
    //   if (layoutType == coachCardLayoutType.both) {
    //     setLayoutType(coachCardLayoutType.overThenDown);
    //     setIsReverseIndexes(true);
    //     setTimeout(() => {
    //       pdfButtonRef.current.click();
    //       dispatch({ type: UPDATE_LOADING, payload: false });
    //       setSelectedPdfFileType(null);
    //     }, 4000);
    //   }
    // }, 4000);

    setShowPdfNameModal(false);
  };

  const downloadCollegeCardPdf = async () => {
    const downloadAllowed = await validateDownload();
    if (!downloadAllowed) return;

    dispatch({ type: UPDATE_LOADING, payload: true });
    setTimeout(() => {
      setSelectedPdfFileType("collegeCard");
    }, 200);
    // setTimeout(() => {
    //   pdfButtonRef.current.click();
    //   dispatch({ type: UPDATE_LOADING, payload: false });
    //   setSelectedPdfFileType(null);
    // }, 4500);
    setShowQRConfirmationModal(false);
  };

  const signLockHandler = (Selectedsign) => {
    const tmeSigns = [...signs];
    for (const i in tmeSigns) {
      if (
        tmeSigns[i].abbreviation.toLowerCase() ==
        Selectedsign.abbreviation.toLowerCase()
      ) {
        let is_lock = true;
        if (tmeSigns[i].is_locked) {
          is_lock = false;
        }
        tmeSigns[i].is_locked = is_lock;
      }
    }
    setSigns([...tmeSigns]);
  };
  const afterQRRender = (base64, type) => {
    if (type == "coach") {
      setCoachCardQR(base64);
    }
    if (type == "college") {
      setCollegeCardQR(base64);
    }
  };

  useEffect(() => {
    updateboxMatrix(16);
    setMatrix(16);
    generateInitialZones(16);
    setSelectedZonesToMerge([]);

    let updated = resetWristBandArray(filledWristbandPattern);
    setfilledWristbandPattern({
      ...filledWristbandPattern,
      gridData: updated,
    });

    if (bandId) {
      getBandDetails();
      setUserDefaultDimensions();
    } else {
      populateSigns();
      setUserDefaultSettings();
    }
  }, []);

  const populateAbbriviations = async () => {
    // setTableProps({ ...tableProps, loading: true, data: [] });
    let paramString = `?user_id=${defaultUserId}`;
    getSigns(paramString)
      .then((result) => {
        // setTableProps({ ...tableProps, loading: false });
        if (result.data.status == true) {
          let responseData = result.data.data ? result.data.data : [];
          setAllAbbreviationsList(responseData);
          setfilterlist(
            filterListByAttribute(responseData, "category", bandType)
          );
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        // setTableProps({ ...tableProps, loading: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  useEffect(() => {
    populateAbbriviations();
  }, []);

  const triggerAlertMessage = (label, message) => {
    setAlertMessageState({ ...alertMessageState, show: true, label, message });
  };

  // User default settings
  const setUserDefaultSettings = () => {
    // set zones
    let foundDefaultZones = getDefaultStorageZone();
    if (foundDefaultZones && bandType == wristBandType.pitching) {
      let zonesDetails = JSON.parse(foundDefaultZones);
      setMatrix(zonesDetails.type);
      setCoachCradInitialWidth(getCoachCardInitialWidth(zonesDetails.type));
      updateboxMatrix(zonesDetails.type);
      setZonesArray(resetZonesData(zonesDetails.data));
    }

    //  set Grid
    let foundDefaultColor = getDefaultStorageColor();
    if (foundDefaultColor) {
      let defaultbgColor = foundDefaultColor.split("-")[0];
      let defaultborderColor = foundDefaultColor.split("-")[1];
      let defaultGridSize = foundDefaultColor.split("-")[2];

      setgridBgColor(defaultbgColor);
      setgridborderColor(defaultborderColor);
      if (defaultGridSize) {
        getPrintDimension(defaultGridSize);

        if (defaultGridSize == 54) {
          setemptyWristbandPattern({
            ...emptyWristbandPattern54,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
          setfilledWristbandPattern({
            ...fillWristbandPattern54,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
        } else if (defaultGridSize == 96) {
          setemptyWristbandPattern({
            ...emptyWristbandPattern96,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
          setfilledWristbandPattern({
            ...fillWristbandPattern96,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
        } else if (defaultGridSize == 150) {
          setemptyWristbandPattern({
            ...emptyWristbandPattern150,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
          setfilledWristbandPattern({
            ...fillWristbandPattern150,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
        } else if (defaultGridSize == 216) {
          setemptyWristbandPattern({
            ...emptyWristbandPattern216,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
          setfilledWristbandPattern({
            ...fillWristbandPattern216,
            bgColor: defaultbgColor,
            border_color: defaultborderColor,
          });
        }
      }
    }

    // set Dimension
    setUserDefaultDimensions();
  };
  const setUserDefaultDimensions = () => {
    let userDefaultSettings = JSON.parse(
      localStorage.getItem("userDefaultSettings")
    );

    let foundDefaultDimension =
      bandType == wristBandType.pitching
        ? userDefaultSettings?.defaultPitchingDimension
        : bandType == wristBandType.offensive
        ? userDefaultSettings?.defaultOffensiveDimension
        : bandType == wristBandType.defensive
        ? userDefaultSettings?.defaultDefensiveDimension
        : "";
    if (foundDefaultDimension) {
      setSelectedDimension(parseInt(foundDefaultDimension));
      setPrintWidth(
        downloadPrintDimensions[parseInt(foundDefaultDimension)].width
      );
      setPrintHeight(
        downloadPrintDimensions[parseInt(foundDefaultDimension)].height
      );
    }
  };
  const saveUserDefaultSettings = (type, data, call_back) => {
    let prevSettings = JSON.parse(localStorage.getItem("userDefaultSettings"));
    if (
      prevSettings == undefined ||
      prevSettings == null ||
      prevSettings === ""
    ) {
      prevSettings = {
        userId: defaultUserId,
        defaultShowCaseZone: "",
        defaultGamingZone: "",
        defaultPitchingColor: "",
        defaultOffensiveColor: "",
        defaultDefensiveColor: "",
        defaultPitchingDimension: "",
        defaultOffensiveDimension: "",
        defaultDefensiveDimension: "",
      };
    }
    prevSettings[type] = data;
    dispatch({ type: UPDATE_LOADING, payload: true });
    saveUserDetails(prevSettings)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          alert.success(APP_ERROR_MSGS.SaveMsg);
          localStorage.setItem(
            "userDefaultSettings",
            JSON.stringify(prevSettings)
          );
          call_back();
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const onDefaultZoneSave = () => {
    const temArr = [...zonesArray];
    const data = {
      type: matrix,
      data: temArr,
    };
    saveUserDefaultSettings(
      subscriptionMode == "show" ? "defaultShowCaseZone" : "defaultGamingZone",
      JSON.stringify(data),
      () => {}
    );
  };
  const onDefaultColorSave = () => {
    let attr =
      bandType == wristBandType.pitching
        ? "defaultPitchingColor"
        : bandType == wristBandType.offensive
        ? "defaultOffensiveColor"
        : bandType == wristBandType.defensive
        ? "defaultDefensiveColor"
        : "";
    saveUserDefaultSettings(
      attr,
      `${emptyWristbandPattern.bgColor}-${emptyWristbandPattern.border_color}-${emptyWristbandPattern.emptySize}`,
      () => {}
    );
  };
  const onDefaultDimensionSave = () => {
    let attr =
      bandType == wristBandType.pitching
        ? "defaultPitchingDimension"
        : bandType == wristBandType.offensive
        ? "defaultOffensiveDimension"
        : bandType == wristBandType.defensive
        ? "defaultDefensiveDimension"
        : "";
    saveUserDefaultSettings(attr, selectedDimension, () => {});
  };
  // ---- geters
  const getDefaultStorageZone = () => {
    let userDefaultSettings = JSON.parse(
      localStorage.getItem("userDefaultSettings")
    );
    let foundDefaultZones =
      subscriptionMode == "show"
        ? userDefaultSettings?.defaultShowCaseZone
        : userDefaultSettings?.defaultGamingZone;
    return foundDefaultZones;
  };
  const getDefaultStorageColor = () => {
    let userDefaultSettings = JSON.parse(
      localStorage.getItem("userDefaultSettings")
    );
    let foundDefaultColor =
      bandType == wristBandType.pitching
        ? userDefaultSettings?.defaultPitchingColor
        : bandType == wristBandType.offensive
        ? userDefaultSettings?.defaultOffensiveColor
        : bandType == wristBandType.defensive
        ? userDefaultSettings?.defaultDefensiveColor
        : "";
    return foundDefaultColor;
  };

  const onAbbreviationDuplicationMsgClose = () => {
    setAbbreviationExistError(false);
    if (msgRedirectToSignForm) setShowEditModal(true);
  };

  return (
    <div>
      <MessageAlertModal
        {...alertMessageState}
        closeModal={() =>
          setAlertMessageState({ ...alertMessageState, show: false })
        }
      />

      <DuplicateAbbreviationAlertModal
        show={abbreviationExistError}
        message={abbreviationExistErrorMsg}
        closeModal={onAbbreviationDuplicationMsgClose}
      />

      <ReplaceDuplicateSignModal
        show={replaceSignConfirm}
        replaceSign={replaceSign}
        closeModal={() => setReplaceSignConfirm(false)}
      />

      <PrevBtnConfirmationModal
        show={showPrevConfirmationModal}
        closeModal={() => setShowPrevConfirmationModal(false)}
        handleContinueClick={handlePrevNavigation}
      />
      <ZonesPopulationAlertModal
        show={showZonesPopulationAlertModal}
        closeModal={() => setShowZonesPopulationAlertModal(false)}
      />
      <ZonesSelectionAlertModal
        show={showZonesSelectionAlertModal}
        closeModal={() => setShowZonesSelectionAlertModal(false)}
      />
      {printWristbandSec == false ? (
        <>
          <Navbar />
          <section className="banner">
            <div className="home_btn">
              <Link to="/dashboard">
                <button type="button">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                  Dashboard
                </button>
              </Link>
            </div>
            <div className="steps-container">
              <div className="progress-container">
                {progressSteps.map(function (item, index) {
                  return (
                    <>
                      <div
                        className={
                          activeSection >= item.indexNo
                            ? "circle active"
                            : "circle"
                        }
                      >
                        <p>
                          <span className="span-p">step</span> {item.lable}
                        </p>
                      </div>
                      {index != progressSteps.length - 1 && (
                        <div
                          className={
                            activeSection > item.indexNo
                              ? "progress-line active"
                              : "progress-line"
                          }
                        ></div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
          {activeSection == 1 ? (
            <section className="gnerate section_spacing">
              <div className="container">
                <h2 className="generat_heading">Generate Wristbands</h2>
                <span className="generate-heading-span">
                  (Please note this is the{" "}
                  {isMirrorView ? "pitchers" : "catchers"} view)
                </span>

                <div className="row">
                  <div className="col-md-6 order_div">
                    <ZoneNameModal
                      show={primary}
                      closeModal={() => setPrimary(false)}
                      updateZone={updateSelectedZone}
                      selectedZoneName={selectedZoneName}
                      setSelectedZoneName={(val) => setSelectedZoneName(val)}
                      isGammingMode={
                        subscriptionMode === UserSubscriptionModes.gamming
                      }
                      zoneColor={selectedZoneColor}
                      handleColorSelect={setSelectedZoneColor}
                    />
                    <div className="pb-3">
                      <ZonesViewRadio
                        handleOptionChange={setIsMirrorView}
                        value={isMirrorView}
                      />
                    </div>
                    <SelectZone
                      {...dndProps}
                      zones={zonesArray}
                      handleZoneClick={handleZoneClick}
                      onDragEnd={onDragEnd}
                      selectedZonesToMerge={selectedZonesToMerge}
                      isModal={false}
                      isMirrorView={isMirrorView}
                    />
                  </div>
                  <div className="col-md-6 order_div_2">
                    {/* <div className="row mb-3"> */}
                    {/* <div className=" col-lg-6 col-md-6 col-sm-12 col-12"> */}
                    <h2 className="select_col">Select Zones</h2>
                    {/* </div> */}
                    {/* <div className=" col-lg-6 col-md-6 col-sm-12 col-12">
                        <button
                          className="btn_list disable_Class"
                          onClick={onDefaultZoneSave}
                        >
                          Set zone as default
                        </button>
                      </div> */}
                    {/* </div> */}

                    <div className="row mb-4">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form__item">
                          <select
                            value={matrix}
                            className="select_input_download"
                            onChange={(e) => updateboxMatrix(e.target.value)}
                          >
                            {zonesSizes.map(function (item, index) {
                              return (
                                <option value={item.selectValue}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="instruction">
                      <h4 className="instruction-head">Instructions</h4>
                      <ol className="instruction-list">
                        <li>
                          you can merge cells horizontally as well as vertically
                        </li>
                        <li>
                          Select cells you want to merge and click "merge".
                        </li>
                        <li>
                          Only cells with same number of “merged cells” can be
                          merged
                        </li>
                        <div className="ul-list">
                          <p>
                            <span>A.</span>2 horizontally merged cells can be
                            merged with 2 adjacent horizontally merged cells and
                            vice versa
                          </p>
                        </div>
                        <li>
                          Double click on a cell or group of cells to give label
                        </li>
                        <li>
                          You must label each cell to use it in the Pitch
                          Selection Process.
                        </li>
                        <li>
                          Select cells you want to unmerge and click "Un Merge".
                        </li>
                      </ol>
                    </div>
                    <div className="row mt-4 ml-0">
                      <div className="listing_btn_div">
                        <button
                          className="btn_list disable_Class"
                          onClick={handleMergeBtnClick}
                          disabled={
                            selectedZonesToMerge.length <= 1 ? true : false
                          }
                        >
                          Merge
                        </button>
                        <button
                          className="btn_list disable_Class"
                          onClick={handleUnMergeBtnClick}
                          disabled={
                            selectedZonesToMerge.length == 1 &&
                            (selectedZonesToMerge[0].mergedZonesCount > 1 ||
                              selectedZonesToMerge[0].verticalmergedZonesCount >
                                1)
                              ? false
                              : true
                          }
                        >
                          Un Merge
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : activeSection == 2 ? (
            <section className="generate_section_2 section_spacing">
              <div className="container">
                <h2 className="generat_heading">Generate Wristbands</h2>
                <div className="row">
                  <div className="col-lg-7 col-md-7 fr_order">
                    <WistbandGrid wristbandPattern={emptyWristbandPattern} />
                  </div>
                  <div className="col-lg-5 col-md-5 fr_order_1">
                    <div className="row mb-3 mt-3">
                      <div className=" col-lg-6 col-md-6 col-sm-12 col-12">
                        <h2 className="select_clr">Select the Grid Size</h2>
                      </div>
                      <div className=" col-lg-6 col-md-6 col-sm-12 col-12">
                        <button
                          className="btn_list disable_Class"
                          onClick={onDefaultColorSave}
                        >
                          set Grid as default
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form__item">
                          <div className="custom-radio">
                            <input
                              onClick={gridSizeHandler}
                              className="custom-radio__control"
                              id="custom-radio-box"
                              name="selectGrid"
                              type="radio"
                              checked={
                                emptyWristbandPattern.emptySize == 54
                                  ? true
                                  : false
                              }
                              value="54"
                            />
                            <label
                              className="custom-radio__label"
                              htmlFor="custom-radio-box"
                            >
                              3x3 (54 Boxes)
                            </label>
                          </div>
                          <div className="custom-radio">
                            <input
                              onClick={gridSizeHandler}
                              className="custom-radio__control"
                              id="custom-radio-box_2"
                              name="selectGrid"
                              type="radio"
                              checked={
                                emptyWristbandPattern.emptySize == 150
                                  ? true
                                  : false
                              }
                              value="150"
                            />
                            <label
                              className="custom-radio__label"
                              htmlFor="custom-radio-box_2"
                            >
                              5x5 (150 Boxes)
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form__item">
                          <div className="custom-radio">
                            <input
                              onClick={gridSizeHandler}
                              className="custom-radio__control"
                              id="custom-radio-box_3"
                              name="selectGrid"
                              type="radio"
                              checked={
                                emptyWristbandPattern.emptySize == 96
                                  ? true
                                  : false
                              }
                              value="96"
                            />
                            <label
                              className="custom-radio__label"
                              htmlFor="custom-radio-box_3"
                            >
                              4x4 (96 Boxes)
                            </label>
                          </div>
                          <div className="custom-radio">
                            <input
                              onClick={gridSizeHandler}
                              className="custom-radio__control"
                              id="custom-radio-box_4"
                              name="selectGrid"
                              type="radio"
                              checked={
                                emptyWristbandPattern.emptySize == 216
                                  ? true
                                  : false
                              }
                              value="216"
                            />
                            <label
                              className="custom-radio__label"
                              htmlFor="custom-radio-box_4"
                            >
                              6x6 (216 Boxes)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 className="select_clr">Select the Color</h2>

                    <div className="d-flex flex-wrap">
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#FF0000"
                          src={Ellipse_1}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#FF6200"
                          src={Ellipse_2}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#6EE0DE"
                          src={Ellipse_3}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#000000"
                          src={Ellipse_4}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#00F83B"
                          src={Ellipse_5}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#BF1B1C"
                          src={Ellipse_6}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#0046FE"
                          src={Ellipse_7}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#FFC800"
                          src={Ellipse_8}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#CFCFCF"
                          src={Ellipse_9}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#007F42"
                          src={Ellipse_10}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#844707"
                          src={Ellipse_11}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#FE5BB7"
                          src={Ellipse_12}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridColorHandler}
                          grid_bg_color="#5600FD"
                          src={Ellipse_13}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <div className="input_div">
                        <input
                          onChange={gridColorHandler}
                          type="color"
                          grid_bg_color="custom_color"
                          className="color_picker"
                        />
                        <img
                          src={Group_24145}
                          alt=""
                          className="color_img_picker_1"
                        />
                      </div>
                    </div>

                    {/* <h2 className="select_clr">Select the Border Color</h2>
                    <div className="d-flex flex-wrap">
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#FF0000"
                          src={Ellipse_1}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#FF6200"
                          src={Ellipse_2}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#6EE0DE"
                          src={Ellipse_3}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#000000"
                          src={Ellipse_4}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#00F83B"
                          src={Ellipse_5}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#BF1B1C"
                          src={Ellipse_6}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#0046FE"
                          src={Ellipse_7}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#FFC800"
                          src={Ellipse_8}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#CFCFCF"
                          src={Ellipse_9}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#007F42"
                          src={Ellipse_10}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#844707"
                          src={Ellipse_11}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#FE5BB7"
                          src={Ellipse_12}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <span className="color_link">
                        <img
                          onClick={gridBorderColorHandler}
                          grid_border_color="#5600FD"
                          src={Ellipse_13}
                          alt="Color Image"
                          className="color_img"
                        />
                      </span>
                      <div className="input_div">
                        <input
                          onChange={gridBorderColorHandler}
                          type="color"
                          grid_border_color="custom_border_color"
                          className="color_picker"
                        />
                        <img
                          src={Group_24145}
                          alt=""
                          className="color_img_picker_1"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          ) : activeSection == 3 ? (
            <section className="generate_section_3 section_spacing">
              <LimitExceedModal
                show={showLimitExceedModal}
                closeModal={() => setShowLimitExceedModal(false)}
              />

              <RemoveSignModel
                {...removeSignModalProps}
                closeModal={() => close()}
                removeSign={(val) => removeSign(val)}
              />

              <EditsignsModal
                isOnlyColor={isOnlyColor}
                isRadioDisabled={true}
                show={showEditModal}
                data={selectedRow}
                handleDataChange={handleSelectedDataChange}
                onFormSave={handleRecordSubmit}
                closeModal={() => setShowEditModal(false)}
                list={allAbbreviationsList}
                abbreviationExistError={abbreviationExistError}
                filteredList={filterlist}
                isZoneColorOverride={isZoneColorOverride}
                onOverrideColorChange={() =>
                  setIsZoneColorOverride(!isZoneColorOverride)
                }
                showOverrideOption={bandType == wristBandType.pitching}
              />

              <Re_arrangeModal
                {...rearrangeModalProps}
                show={showReArrangeModal}
                closeModal={() => setShowReArrangeModal(false)}
                handleInputChange={handleReArrangeInputChange}
                handleSave={handleRearrangeModalSave}
                zonesArray={zonesArray}
                vidsualdndProps={reArrangeModalVisualdndProps}
              />

              <div className="container">
                <h2 className="generat_heading">Generate Wristbands</h2>
                <div className="row">
                  <div className="col-lg-7 col-md-7 fr_order">
                    <WistbandGrid wristbandPattern={filledWristbandPattern} />
                  </div>
                  <div className="col-lg-5 col-md-5 fr_order_1 ">
                    <div className="row">
                      <div className="instruction-2">
                        <h4 className="instruction-head">Instructions</h4>
                        <ol className="instruction-list abc">
                          <li>Click "+" Icon below to create your own sign.</li>
                          <li>
                            Click Lock button to lock your pitches count of a
                            specific sign.
                          </li>
                          <li>
                            Click "edit" icon to customize pitches count in
                            zones.
                          </li>
                          {bandType == wristBandType.pitching && (
                            <li>
                              Click color / abbreviation to change color /
                              abbreviation of a sign.
                            </li>
                          )}
                        </ol>
                      </div>
                      {signs.map(function (item, index) {
                        return (
                          <div className="col-md-4 p-lg-0 col-sm-4 col-6">
                            <div className="new">
                              <div className="form-group">
                                <input
                                  onChange={fillWristbandPattern}
                                  type="checkbox"
                                  id={`${item.abbreviation}-${item.id}`}
                                  name={item.name}
                                  checked={item.status == true ? true : false}
                                />
                                <label
                                  htmlFor={`${item.abbreviation}-${item.id}`}
                                >
                                  {item.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="zone_div">
                      {signs.map(function (item, index) {
                        return item.status ? (
                          <div className="main_zone_div">
                            {bandType != wristBandType.pitching && (
                              <div className="zone-child-div-0">
                                <button
                                  className="lock-btn"
                                  onClick={() => {
                                    signLockHandler(item);
                                  }}
                                >
                                  {item.is_locked ? (
                                    <img
                                      src={lock_close_icon}
                                      alt="minus icon"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={lock_opn_icon}
                                      alt="minus icon"
                                      className="img-fluid"
                                    />
                                  )}
                                </button>
                              </div>
                            )}

                            {bandType == wristBandType.pitching && (
                              <div className="zone-child-div-1">
                                <SelectZone
                                  isVisualZones={true}
                                  zoneContainer_label={item.abbreviation}
                                  {...vidsualdndProps}
                                  zones={zonesArray}
                                  handleZoneClick={(prop) =>
                                    PopulateZoneData(prop, item.abbreviation)
                                  }
                                  onDragEnd={() => {}}
                                  selectedZonesToMerge={[]}
                                  isModal={false}
                                  isMirrorView={isMirrorView}
                                  signObj={item}
                                />
                              </div>
                            )}
                            <div
                              className={
                                bandType == wristBandType.pitching
                                  ? "zone-child-div-2"
                                  : "zone-child-2-z"
                              }
                              key={index}
                            >
                              <ul className="sign_list">
                                <li className="list_item_2">{item.name}</li>
                                <li className="list_item_2">
                                  {item.abbreviation}
                                </li>
                                <li className="list_item_2">
                                  <div
                                    onClick={() => editSignColor(item)}
                                    style={{
                                      width: "60px",
                                      height: "20px",
                                      backgroundColor: item.color,
                                      cursor: "pointer",
                                      border: "2px solid #9A999B",
                                    }}
                                  ></div>
                                </li>
                              </ul>
                              <div className="range_div">
                                <div className="range-slider">
                                  <PrettoSlider
                                    onChangeCommitted={(e, val) =>
                                      changeWristbandPatternBySlider(
                                        e,
                                        val,
                                        item
                                      )
                                    }
                                    valueLabelDisplay="auto"
                                    aria-label="pretto slider"
                                    value={item.percentage}
                                    min={0}
                                    max={100}
                                  />
                                </div>
                                <div className="range_div_2">
                                  <button className="minus_btn">
                                    <span
                                      onClick={() =>
                                        openModal(item.abbreviation)
                                      }
                                      className="minus_icon"
                                    >
                                      <img
                                        src={MinusIcon}
                                        alt="minus icon"
                                        className="img-fluid"
                                      />
                                    </span>
                                  </button>
                                  <div
                                    className="modal fade"
                                    id={"#contactModalSlider" + index}
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="myModalLabel"
                                    aria-hidden="true"
                                  ></div>
                                </div>
                              </div>
                              <div className="re-arange-parent-div">
                                <div
                                  className={
                                    bandType == wristBandType.pitching
                                      ? "re-arange-pitching-child-1"
                                      : "re-arange-child-1"
                                  }
                                >
                                  <p className="value">
                                    {item.percentage}% of signs or about{" "}
                                    {item.counts} times
                                  </p>
                                </div>
                                {bandType == wristBandType.pitching && (
                                  <div className="re-arange-child-2">
                                    <button
                                      className="lock-btn mr-3"
                                      onClick={() => {
                                        signLockHandler(item);
                                      }}
                                    >
                                      {item.is_locked ? (
                                        <img
                                          src={lock_close_icon}
                                          alt="minus icon"
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={lock_opn_icon}
                                          alt="minus icon"
                                          className="img-fluid"
                                        />
                                      )}
                                    </button>
                                    <button
                                      type="button"
                                      className="rearange-btn"
                                      onClick={() =>
                                        handleReArrangeModalBtnClick(item)
                                      }
                                    >
                                      <img src={re_arange_icon} />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })}
                    </div>

                    <div className="plus_icon_div">
                      <img
                        onClick={AddNewSign}
                        src={PlusIcon}
                        alt="plus_img"
                        className="plusImg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : activeSection == 4 ? (
            <section className="generate_section_4 section_spacing">
              {/* <PitcherCardOverViewModal
                show={showPitcherCardOverViewModal}
                closeModal={() => setShowPitcherCardOverViewModal(false)}
                height={printPitcherHeight}
                handleHeightChange={setPrintPitcherHeight}
              >
                <PitcherCard
                  height={printPitcherHeight}
                  width={PrintWidth}
                  bandType={bandType}
                  matrixType={matrix}
                  zones={[...zonesArray]}
                  processedSigns={ProcessDataInArray(activeSigns, "abbreviation")}
                  selectedSigns={signsIndexesAarr}
                />
              </PitcherCardOverViewModal> */}

              <PDFNameModal
                show={showPdfNameModal}
                closeModal={() => setShowPdfNameModal(false)}
                downloadPdfFile={handleCoachCardSubmit}
                pdfName={pdfName}
                setPdfName={(val) => setPdfName(val)}
                layoutType={layoutType}
                handleLayoutChange={setLayoutType}
                coachCradInitialHeight={coachCradInitialHeight}
                setCoachCradInitialHeight={setCoachCradInitialHeight}
                bandType={bandType}
                isShowQR={isShowQR}
                setIsShowQR={setIsShowQR}
              >
                {bandType == wristBandType.pitching ? (
                  <CoachCard_V1
                    title={pdfName}
                    zones={zonesArray}
                    matrixType={matrix}
                    processedSigns={ProcessDataInArray(
                      activeSigns,
                      "abbreviation"
                    )}
                    bandType={bandType}
                    selectedSigns={signsIndexesAarr}
                    isReverseIndexes={isReverseIndexes}
                    zoneInitialWidth={coachCradInitialWidth}
                    zoneInitialHeight={coachCradInitialHeight}
                  />
                ) : (
                  <></>
                )}
              </PDFNameModal>

              <QRConfirmationModal
                show={showQRConfirmationModal}
                closeModal={() => setShowQRConfirmationModal(false)}
                downloadPdfFile={downloadCollegeCardPdf}
                isShowQR={isShowQR}
                setIsShowQR={setIsShowQR}
              />

              <QRGenerator
                type="college"
                val={bandId}
                afterQRRender={afterQRRender}
              />
              <QRGenerator
                type="coach"
                val={bandId}
                afterQRRender={afterQRRender}
              />

              <div className="container">
                <h2 className="generat_heading">Generate Wristbands</h2>
                <div className="row">
                  <div
                    className="col-lg-7 col-md-7 fr_order"
                    id="print_player_card"
                  >
                    <WistbandGrid wristbandPattern={filledWristbandPattern} />
                  </div>
                  <div className="col-lg-5 col-md-5 fr_order_1">
                    <div className="div-center">
                      <h2 className="print">Download/Print Wristband</h2>

                      <div className="row mb-2 pt-2">
                        <div className=" col-lg-6 col-md-6 col-sm-12 col-12">
                          <p className="prnt_2 pt-2">Select Dimension</p>
                        </div>

                        <div className=" col-lg-6 col-md-6 col-sm-12 col-12">
                          <button
                            className="btn_list disable_Class"
                            onClick={onDefaultDimensionSave}
                          >
                            set dimension as default
                          </button>
                        </div>
                      </div>

                      {selectedDimension === defaultPrintDimensionVal && (
                        <a
                          href={defaultZoneSizeLink}
                          target="_blank"
                          className="ancher_link"
                        >
                          View details
                        </a>
                      )}

                      <DropDown
                        multi={false}
                        dropdownRenderer={false}
                        contentRenderer={false}
                        closeOnSelect={true}
                        create={false}
                        onChange={(lable, val) => setPrintDimension(val)}
                        Type="selectedDimension"
                        options={downloadPrintDimensions}
                        val={selectedDimension}
                        loading={false}
                        searchBy="label"
                        labelField="label"
                        valueField="val"
                        placeholder="Select"
                        clearable={false}
                        searchable={false}
                      />

                      {/* <select
                        value={selectedDimension}
                        className="select_input_download"
                        onChange={(e) => setPrintDimension(e.target.value)}
                      >
                        {downloadPrintDimensions.map(function (item, index) {
                          return (
                            <option value={item.val} title={item.label}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}

                      <div className="pt-2">
                        <p className="prnt_2">Enter Card Name</p>
                        <input
                          type="text"
                          className="login_input"
                          value={wristbandPitcherName}
                          onChange={(e) =>
                            setWristbandPitcherName(e.target.value)
                          }
                        />
                      </div>

                      <div>
                        <p className="prnt_2">Opponent Name (Optional)</p>
                        <input
                          type="text"
                          className="login_input"
                          value={wristbandOpponent}
                          onChange={(e) => setWristbandOpponent(e.target.value)}
                        />
                      </div>

                      {/* <CardTypeRadio
                        handleOptionChange={setCardsViewType}
                        CardsType={cardsViewType}
                      /> */}

                      {selectedPdfFileType && (
                        <PDFDownloadLinkWrapper
                          pdfName={
                            selectedPdfFileType === "playerCard"
                              ? `${bandName}_PlayerCard.pdf`
                              : selectedPdfFileType === "coachCard"
                              ? `${bandName}_CoachCard.pdf`
                              : selectedPdfFileType === "pitcherCard"
                              ? `${bandName}_PitcherCard.pdf`
                              : selectedPdfFileType === "catcherCard"
                              ? `${bandName}_CatcherCard.pdf`
                              : selectedPdfFileType === "collegeCard"
                              ? `${bandName}_CollegeCoachCard.pdf`
                              : ""
                          }
                          buttonRef={pdfButtonRef}
                          onPdfRender={onPdfRender}
                        >
                          {selectedPdfFileType === "playerCard" ? (
                            <PdfWrapper>
                              <PrintWistband
                                height={PrintHeight}
                                width={PrintWidth}
                                wristbandPattern={filledWristbandPattern}
                                wristbandPitcherName={wristbandPitcherName}
                                printCount={
                                  cardsViewType === 0
                                    ? 3 //1
                                    : PrintHeight > 180
                                    ? 3
                                    : 3 //4
                                }
                              />
                            </PdfWrapper>
                          ) : selectedPdfFileType === "coachCard" ? (
                            <PdfWrapper>
                              <CoachCard
                                title={pdfName}
                                zones={zonesArray}
                                matrixType={matrix}
                                processedSigns={ProcessDataInArray(
                                  activeSigns,
                                  "abbreviation"
                                )}
                                bandType={bandType}
                                selectedSigns={signsIndexesAarr}
                                isReverseIndexes={isReverseIndexes}
                                zoneInitialWidth={coachCradInitialWidth}
                                zoneInitialHeight={coachCradInitialHeight}
                                QRUrl={coachCardQR}
                                isShowQR={isShowQR}
                                isMirrorView={isMirrorView}
                                fitToScreen={true}
                              />
                            </PdfWrapper>
                          ) : selectedPdfFileType === "pitcherCard" ? (
                            <PitcherCard
                              height={PrintHeight}
                              width={PrintWidth}
                              bandType={bandType}
                              matrixType={matrix}
                              zones={[...zonesArray]}
                              processedSigns={ProcessDataInArray(
                                activeSigns,
                                "abbreviation"
                              )}
                              selectedSigns={signsIndexesAarr}
                              wristbandPitcherName={wristbandPitcherName}
                              // printCount={
                              //   cardsViewType === 0
                              //     ? 1
                              //     : PrintHeight > 180
                              //     ? 3
                              //     : 3 // 4
                              // }
                            />
                          ) : selectedPdfFileType === "catcherCard" ? (
                            <PdfWrapper>
                              <PrintWistband
                                height={PrintHeight}
                                width={PrintWidth}
                                wristbandPattern={filledWristbandPattern}
                                wristbandPitcherName={wristbandPitcherName}
                                // printCount={
                                //   cardsViewType === 0
                                //     ? 1
                                //     : PrintHeight > 180
                                //     ? 3
                                //     : 3 //4
                                // }
                              />
                            </PdfWrapper>
                          ) : selectedPdfFileType === "collegeCard" ? (
                            <CollegeCard
                              wristbandPattern={filledWristbandPattern}
                              zones={zonesArray}
                              matrixType={matrix}
                              processedSigns={ProcessDataInArray(
                                activeSigns,
                                "abbreviation"
                              )}
                              bandType={bandType}
                              selectedSigns={signsIndexesAarr}
                              QRUrl={collegeCardQR}
                              isShowQR={isShowQR}
                              wristbandPitcherName={wristbandPitcherName}
                              isMirrorView={isMirrorView}
                            />
                          ) : (
                            <></>
                          )}
                        </PDFDownloadLinkWrapper>
                      )}

                      {/* Game mode */}
                      {subscriptionMode === UserSubscriptionModes.gamming && (
                        <>
                          {/* hidden={cardsViewType == 0 ? false : true} */}
                          {/* Player Card */}
                          <button
                            type="button"
                            className="dnld_btn_1 mt-3 disable_Class"
                            onClick={() => handlePdfDownloadClick("playerCard")}
                          >
                            Download Player Card
                          </button>
                          {/* Coach Card */}
                          <button
                            type="button"
                            className="dnld_btn_1 mt-3 disable_Class"
                            onClick={() => handlePdfDownloadClick("coachCard")}
                          >
                            Download Coach Card
                          </button>
                          {/* College Coach Card */}
                          <button
                            type="button"
                            className="dnld_btn_1 mt-3 disable_Class"
                            onClick={() =>
                              handlePdfDownloadClick("collegeCard")
                            }
                          >
                            Download College Coach Card
                          </button>
                        </>
                      )}

                      {/* Show mode */}
                      {subscriptionMode === UserSubscriptionModes.showCase && (
                        <>
                          {/* hidden={cardsViewType == 1 ? false : true} */}
                          {/* Pitcher Card */}
                          <button
                            type="button"
                            className="dnld_btn_1 mt-3 disable_Class"
                            onClick={() =>
                              handlePdfDownloadClick("pitcherCard")
                            }
                          >
                            Download Pitcher Card
                          </button>
                          {/* Catcher Card */}
                          <button
                            type="button"
                            className="dnld_btn_1 mt-3 disable_Class"
                            onClick={() =>
                              handlePdfDownloadClick("catcherCard")
                            }
                          >
                            Download Catcher Card
                          </button>
                          {/* College Coach Card */}
                          <button
                            type="button"
                            className="dnld_btn_1 mt-3 disable_Class"
                            onClick={() =>
                              handlePdfDownloadClick("collegeCard")
                            }
                          >
                            Download College Coach Card
                          </button>
                        </>
                      )}

                      <button
                        disabled={
                          // (bandId == "" || bandId == null) &&
                          isBandSaved ? true : false
                        }
                        onClick={() => saveRecord(() => {})}
                        type="button"
                        className="dnld_btn_1 mt-3 disable_Class"
                      >
                        save
                      </button>

                      <Link to="/dashboard">
                        <button type="button" className="dnld_btn_1  mt-3">
                          Back to Homepage
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}

          <div className="container mb-5">
            <div className="row">
              {activeSection - 1 >= StartIndex && (
                <div className="col-md-6 col-6 text-left">
                  <button
                    onClick={handleNavigate}
                    className="btn-pre-next"
                    id="btn-previous"
                  >
                    <i
                      id="btn-previous"
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>{" "}
                    Previous
                  </button>
                </div>
              )}
              <div
                className={
                  activeSection - 1 >= StartIndex
                    ? "col-md-6 col-6 text-right"
                    : "col-md-12 col-12 text-right"
                }
              >
                {activeSection === 1 && (
                  <button
                    className="btn_list btn_list_responsive"
                    onClick={onDefaultZoneSave}
                  >
                    Set zone as default
                  </button>
                )}

                {activeSection + 1 <= 4 && (
                  <button
                    onClick={handleNavigate}
                    className="btn-pre-next"
                    id="btn-next"
                  >
                    Next{" "}
                    <i
                      id="btn-next"
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </button>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <section className="generate_section_4 section_spacing print_player_card">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-7 col-md-7 fr_order"
                style={{ margin: "0 auto" }}
              >
                <h2 className="generat_heading">Player Card</h2>
                <WistbandGrid wristbandPattern={filledWristbandPattern} />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Wristband;
