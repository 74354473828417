import { calculateRatio } from "../../../shared/functions";
import { zonesDefaultColor } from "../../../shared/constants";

function WistbandGrid(props) {
  return props.wristbandPattern.gridData.map(function (item, index) {
    return (
      <>
        <div className={props.wristbandPattern.class}>
          <div className="grid_prnt_div">
            {item.grid.map(function (item, index) {
              return (
                <div key={index} className="child_div">
                  {item.grid.map(function (item, index) {
                    return (
                      <div className="grid_prnt_2">
                        {item.grid.map(function (item, index) {
                          const boxBackgroundColor = item.overrideColor
                            ? item.overrideColor
                            : item.zoneColor &&
                              item.zoneColor != "" &&
                              item.zoneColor != zonesDefaultColor.diable
                            ? item.zoneColor
                            : item.color != ""
                            ? item.color
                            : props.wristbandPattern.bgColor;
                          return (
                            <div
                              style={{
                                background: boxBackgroundColor,
                                border:
                                  "1px solid " +
                                  props.wristbandPattern.border_color,
                              }}
                              className="box"
                              key={index}
                            >
                              <p
                                className="number"
                                style={{
                                  color:
                                    calculateRatio(
                                      boxBackgroundColor,
                                      "#ffffff"
                                    ) <
                                    1 / 3
                                      ? "#ffffff"
                                      : "#000000",
                                  ...(item.fontSize
                                    ? {
                                        fontSize: item.fontSize
                                          ? item.fontSize
                                          : 2,
                                      }
                                    : {}),
                                }}
                              >
                                {item.name}
                                {item.zone_name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        {/* {index == 0 ? (
          <h2 className="wrist_app">Wristband Software SignsApp</h2>
        ) : null} */}
      </>
    );
  });
}

export default WistbandGrid;
