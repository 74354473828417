import React, { useState, useEffect, useRef } from "react";

function CardTypeRadio(props) {
  return (
    <div className="row mt-3">
      <div className="col-md-6 col-12">
        <div className="form__item">
          <div className="custom-radio">
            <input
              className="custom-radio__control"
              type="radio"
              id="game-CardsTypeRadio"
              name="CardsTypeRadio"
              onChange={(e) => props.handleOptionChange(0)}
              checked={props.CardsType == 0}
            />
            <label
              className="custom-radio__label"
              htmlFor="game-CardsTypeRadio"
            >
              single card
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-12">
        <div className="form__item">
          <div className="custom-radio">
            <input
              className="custom-radio__control"
              type="radio"
              id="showCase-CardsTypeRadio"
              name="CardsTypeRadio"
              onChange={(e) => props.handleOptionChange(1)}
              checked={props.CardsType == 1}
            />
            <label
              className="custom-radio__label"
              htmlFor="showCase-CardsTypeRadio"
            >
              Multiple cards
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardTypeRadio;
