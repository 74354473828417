import React from "react";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import LanidngBanner from "../../layouts/LandingBanner";
import { landingBannerBtn } from "../../../shared/constants";
import { useState } from "react";
import SignsSection from "./SignsSection";
import WristBandsSection from "./WristBandsSection";
import { CreateUserDefaultSigns } from "../../../actions/signs";
import { APP_ERROR_MSGS } from "../../../shared/constants";
import { loadUserDetails } from "../../../actions/auth";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

export default function Lanidng(props) {
  const userPermissions = useSelector((state) => state.auth.permissions);

  const userId = localStorage.getItem("userId");

  const alert = useAlert();
  const query = new URLSearchParams(props.location.search);
  const userParamid = query.get("id");
  const userMode = query.get("mode");
  const limit = query.get("limit");
  // if (!limit) limit = 50;

  const [activeTab, setActiveTab] = useState(landingBannerBtn.wristbands);
  const userDefaultSignsCreator = async (userParamid) => {
    CreateUserDefaultSigns(userParamid)
      .then((result) => {
        // setTableProps({ ...tableProps, loading: false, })
        if (result.data.status == true) {
          console.log(result.data.message);
        } else {
          console.log(APP_ERROR_MSGS.StandardErrorMsg);
          // alert.error(
          //   result.data.message
          //     ? result.data.message
          //     : APP_ERROR_MSGS.StandardErrorMsg
          // );
        }
      })
      .catch((error) => {
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const getUserDefaultSettings = (id, limit) => {
    loadUserDetails(id, limit)
      .then((result) => {
        if (result.data.status == true) {
          localStorage.setItem(
            "userDefaultSettings",
            JSON.stringify({
              userId: result.data.data?.userId,
              defaultShowCaseZone: result.data.data?.defaultShowCaseZone ?? "",
              defaultGamingZone: result.data.data?.defaultGamingZone ?? "",
              defaultPitchingColor:
                result.data.data?.defaultPitchingColor ?? "",
              defaultOffensiveColor:
                result.data.data?.defaultOffensiveColor ?? "",
              defaultDefensiveColor:
                result.data.data?.defaultDefensiveColor ?? "",
              defaultPitchingDimension:
                result.data.data?.defaultPitchingDimension ?? "",
              defaultOffensiveDimension:
                result.data.data?.defaultOffensiveDimension ?? "",
              defaultDefensiveDimension:
                result.data.data?.defaultDefensiveDimension ?? "",
            })
          );
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };

  if (limit) localStorage.setItem("limit", limit);
  if (userMode) localStorage.setItem("mode", userMode);

  if (userParamid) {
    if (userId === undefined || userId !== userParamid)
      userDefaultSignsCreator(userParamid);
    localStorage.setItem("userId", userParamid);
    getUserDefaultSettings(userParamid, limit);
  }

  return (
    <>
      <Navbar />
      <LanidngBanner activeTab={activeTab} handleOnClick={setActiveTab} />
      {activeTab == landingBannerBtn.wristbands ? (
        <WristBandsSection userPermissions={userPermissions} />
      ) : activeTab == landingBannerBtn.signs ? (
        <SignsSection />
      ) : (
        ""
      )}
      <Footer />
    </>
  );
}
