import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function ZonesSelectionAlertModal(props) {
  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="looseModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="zone_selection_modal_head">
            you must label at least one cell to use it in the pitch selection
            process. If a zone is NOT labeled it will not appear in the next
            steps.
          </h2>
          <div className="shuffle_div">
            <button
              type="button"
              className="input_login_btn_mrgn"
              onClick={props.closeModal}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
