import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

export default function EditshufflModal(props) {
    return (
        <>
            <Modal
                isOpen={props.show}
                // onAfterOpen={afterOpenModal}
                onRequestClose={props.closeModal}
                style={customStyles}
                contentLabel="editshufflModal"
                class="customemODAL fade"
            >
                <button type="button" className="close" onClick={props.closeModal}>
                    <i className="fas fa-times"></i>
                </button>
                <div className="modal_content">
                    <h2 className="hire_us_head">edit / shuffle wristband</h2>
                    <div className="show_btn">
                        <button onClick={props.onEdit} type="button" className="input_login_btn mrgn">
                            edit wristband
                        </button>
                        <button className="input_login_btn" onClick={props.onShuffelClick}>
                            shuffle wristband
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
