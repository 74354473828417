import { edit_icon, read_icon, deleteImg } from "../../../shared/images";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import {
  wristBandType,
  APP_ERROR_MSGS,
  downloadPrintDimensions,
  defaultPrintDimensionVal,
  defaultPrintDimensionWidth,
  defaultPrintDimensionHeight,
  coachCardLayoutType,
} from "../../../shared/constants";
// import OffensiveModal from "../../Modals/offensiveModal";
// import DeffensiveModal from "../../Modals/deffensiveModal";
import PitchingModal from "../../Modals/pitchingModal";
import ViewModal from "../../Modals/viewModal";
import EditshufflModal from "../../Modals/EditshufflModal";
// import DeleteModal from "../../Modals/DeleteModal";
import ShuffleModal from "../../Modals/ShuffleModal";
import { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_LOADING } from "../../../actions/types";
import {
  getWristBands,
  deleteWristBand,
  createWristBand,
  checkPdfDownloadLimitExceeded,
} from "../../../actions/wristBands";
import DeletesignModal from "../../Modals/DeletesignModal";
import Table from "../../../shared/tableRendrer";
import {
  getWristbandPopulatedData,
  calPrePopulatedInfo,
  reArrangeData,
  resetWristBandArray,
  ConvertToLocalDateTime,
  updatedGridConcatination,
  getZonesDataInfo,
  ProcessDataInArray,
  getCoachCardInitialWidth,
  isHavePermission,
} from "../../../shared/functions";
import PDFNameModal from "../../Modals/PDFNameModal";
import PitcherCardOverViewModal from "../../Modals/PitcherCardOverViewModal";
import CoachCard_V1 from "../../pages/wristband/CoachCard_V1";
import PitcherCard from "../../pages/wristband/PitcherCard";
import QRGenerator from "../../QRGenerator";
import QRConfirmationModal from "../../Modals/QRConfirmationModal";

function WristBandsSection(props) {
  const defaultUserId = localStorage.getItem("userId");
  const subscriptionMode = localStorage.getItem("mode");

  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  const pdfButtonRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [showDeffensiveModal, setShowDeffensiveModal] = useState(false);
  const [showPitchingModal, setShowPitchingModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showEditshufflModal, setShowEditshufflModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShuffleModal, setShowShuffleModal] = useState(false);
  const [bandName, setbandName] = useState("");
  const [selectedWristbandType, setSelectedWristbandType] = useState("");
  const [shuffelWristbandName, setShuffelWristbandName] = useState("");
  const [selectedPdfFileType, setSelectedPdfFileType] = useState(null);
  const [showPdfNameModal, setShowPdfNameModal] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [coachCradInitialWidth, setCoachCradInitialWidth] = useState(120);
  const [coachCradInitialHeight, setCoachCradInitialHeight] = useState(50);
  const [cardsViewType, setCardsViewType] = useState(0);

  const [tableProps, setTableProps] = useState({
    data: [],
    page: 1,
    sizePerPage: 15,
    totalSize: 0,
    loading: false,
    sortName: "created_at",
    sortOrder: "desc",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowJson, setSelectedJson] = useState(null);
  const [selectedDimension, setSelectedDimension] = useState(
    defaultPrintDimensionVal
  );
  const [PrintWidth, setPrintWidth] = useState(defaultPrintDimensionWidth); //400
  const [PrintHeight, setPrintHeight] = useState(defaultPrintDimensionHeight); //400
  const [printPitcherHeight, setPrintPitcherHeight] = useState(0);
  const [layoutType, setLayoutType] = useState(
    coachCardLayoutType.overThenDown
  );
  const [isReverseIndexes, setIsReverseIndexes] = useState(false);
  const [showPitcherCardOverViewModal, setShowPitcherCardOverViewModal] =
    useState(false);

  const [bandId, setBandId] = useState(null);
  const [coachCardQR, setCoachCardQR] = useState("");
  const [collegeCardQR, setCollegeCardQR] = useState("");
  const [isShowQR, setIsShowQR] = useState(false);
  const [showQRConfirmationModal, setShowQRConfirmationModal] = useState(false);
  const [wristbandPitcherName, setWristbandPitcherName] = useState("");
  const [activeSigns, setActiveSigns] = useState([]);

  const setPrintDimension = (val) => {
    setSelectedDimension(val);
    setPrintWidth(downloadPrintDimensions[val].width);
    setPrintHeight(downloadPrintDimensions[val].height);
  };

  const CellMenu = (cell, row) => {
    return (
      <>
        <a
          onClick={() => handleTableActionClick(row, "view")}
          className="icons_link"
        >
          <img src={read_icon} alt="Read Icon" className="icon_img" />
        </a>
        <a
          onClick={() => handleTableActionClick(row, "edit")}
          className="icons_link mx-3"
        >
          <img src={edit_icon} alt="Read Icon" className="icon_img" />
        </a>
        <a
          onClick={() => handleTableActionClick(row, "delete")}
          className="icons_link"
        >
          <img src={deleteImg} alt="Read Icon" className="icon_img" />
        </a>
      </>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: { width: "170px" },
      sort: true,
    },
    {
      dataField: "id",
      text: "Opponent",
      headerStyle: { width: "150px" },
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {row.opponentName && row.opponentName != ""
              ? row.opponentName
              : "N/A"}
          </div>
        );
      },
    },
    // {
    //   dataField: "color",
    //   text: "Color",
    //   formatter: (cell, row) => {
    //     return (
    //       <div style={{ backgroundColor: cell }} className="color_div_tb"></div>
    //     );
    //   },
    //   headerStyle: { width: "150px" },
    // },
    {
      dataField: "created_at",
      text: "Date",
      headerStyle: { width: "150px" },
      formatter: (cell, row) => {
        return ConvertToLocalDateTime(cell, 3);
      },
      csvFormatter: (created_at) => ConvertToLocalDateTime(created_at, 3),
      sort: true,
    },
    {
      dataField: "",
      text: "Actions",
      formatter: (cell, row) => {
        return CellMenu(cell, row);
      },
      csvExport: false,
      headerStyle: { width: "150px" },
    },
  ];
  const handleTableActionClick = (row, type) => {
    setSelectedRow(row);
    setBandId(row.id);
    let selectedjson = JSON.parse(row.wristband_json);
    setSelectedJson(selectedjson);

    // active signs
    const activeSignsList = [...selectedjson.signs].filter(
      (el) => el.status == true
    );
    setActiveSigns(activeSignsList);

    setCoachCradInitialWidth(
      getCoachCardInitialWidth(selectedjson?.zonesDetails?.type)
    );

    if (type == "view") {
      setSelectedDimension(defaultPrintDimensionVal);
      setPrintWidth(defaultPrintDimensionWidth);
      setPrintHeight(defaultPrintDimensionHeight);
      setUserDefaultDimensions(selectedjson.type);
      setWristbandPitcherName("");
      setShowPreviewModal(true);
      setCardsViewType(0);
      return;
    }
    if (type == "edit") {
      setSelectedWristbandType(selectedjson.type);
      setbandName(row.name);
      setShowEditshufflModal(true);
      return;
    }
    if (type == "delete") return setShowDeleteModal(true);
  };
  const handleTableChange = (type, newState) => {
    if (type == "sort") {
      let sortOrder =
        newState.sortField == tableProps.sortName
          ? tableProps.sortOrder == "" || tableProps.sortOrder == "asc"
            ? "desc"
            : "asc"
          : "desc";

      setTableProps({
        ...tableProps,
        page: 1,
        sortName: newState.sortField,
        sortOrder: sortOrder,
      });
    } else if (type == "pagination") {
      setTableProps({
        ...tableProps,
        page: newState.page,
        sizePerPage: newState.sizePerPage,
      });
    }
  };
  const populateTable = async () => {
    setTableProps({ ...tableProps, loading: true, data: [] });

    let paramString = `?page=${tableProps.page}&limit=${tableProps.sizePerPage}&user_id=${defaultUserId}&mode=${subscriptionMode}`;
    if (tableProps.sortName != "")
      paramString = `${paramString}&sort=${tableProps.sortName}&order=${tableProps.sortOrder}`;

    getWristBands(paramString)
      .then((result) => {
        setTableProps({ ...tableProps, loading: false });
        if (result.data.status == true) {
          let WristbandList = result.data.data.data
            ? result.data.data.data
            : [];
          for (let i in WristbandList) {
            let bandDetail = JSON.parse(WristbandList[i].wristband_json);
            WristbandList[i].opponentName = bandDetail.opponent
              ? bandDetail.opponent
              : "";
          }

          setTableProps({
            ...tableProps,
            data: WristbandList,
            totalSize: result.data.data.total,
          });
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        setTableProps({ ...tableProps, loading: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const deleteRecord = async () => {
    setShowDeleteModal(false);
    dispatch({ type: UPDATE_LOADING, payload: true });
    deleteWristBand(selectedRow.id)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          alert.success(APP_ERROR_MSGS.DeleteMsg);
          populateTable();
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };

  useEffect(() => {
    populateTable();
  }, [
    tableProps.page,
    tableProps.sizePerPage,
    tableProps.sortName,
    tableProps.sortOrder,
  ]);
  const handleSuffelWristband = () => {
    setShowEditshufflModal(false);
    setShuffelWristbandName("");
    setShowShuffleModal(true);
  };
  const handleCreateBand = (type) => {
    setSelectedWristbandType(type);
    setbandName("");
    setShowPitchingModal(true);
  };
  const handleEditWristband = () => {
    let selectedjson = JSON.parse(selectedRow.wristband_json);
    setSelectedWristbandType(selectedjson.type);
    setbandName(selectedRow.name);
    onCreateSubmit(selectedRow.id);
  };
  const onCreateSubmit = (id = "") => {
    setShowPitchingModal(false);
    setShowEditshufflModal(false);
    history.push(
      `/wristband/${wristBandType[selectedWristbandType]}?bandType=${selectedWristbandType}&bandName=${bandName}&id=${id}`
    );
    // history.push(`/wristband/${wristBandType[selectedWristbandType]}`, {
    //   bandType: selectedWristbandType,
    //   bandName: bandName,
    //   id: id,
    // });
  };
  const convertFormatedToList = (ArrayList) => {
    let list = [];
    Object.entries(ArrayList).map((item, key) => {
      list.push({ label: item[0], arr: item[1] });
    });
    return list;
  };
  const shuffelWristband = (e) => {
    e.preventDefault();
    setShowShuffleModal(false);
    dispatch({ type: UPDATE_LOADING, payload: true });
    let data = { ...selectedRow };
    let wristbandJson = JSON.parse(data.wristband_json);

    // get active signs
    let activeSignsList = [...wristbandJson.signs];
    activeSignsList = activeSignsList.filter(function (el) {
      return el.status == true;
    });

    // get sign iteration
    const signsAppendedIterations = [];
    activeSignsList.forEach((item) => {
      signsAppendedIterations[item.abbreviation] = 0;
    });

    // Get initial Grid
    let initialGrid = { ...wristbandJson.wristBandDetails };
    let updated = resetWristBandArray({ ...initialGrid });
    initialGrid.gridData = updated;

    // shuffle data
    let resp = getWristbandPopulatedData(
      initialGrid,
      activeSignsList,
      signsAppendedIterations
    );
    initialGrid.gridData = resp.updatedWristbandPattern;
    wristbandJson.wristBandDetails = initialGrid;
    const formattedList = convertFormatedToList(resp.formattedData);
    const foundLabelIndexes = convertFormatedToList(resp.formattedData2);
    wristbandJson.signsIndexesArr = formattedList;
    if (wristbandJson.type == wristBandType.pitching) {
      wristbandJson.zonesDetails.formattedwristbandData = [...formattedList];
      let tempItems = [...wristbandJson.zonesDetails.data];
      for (const i in foundLabelIndexes) {
        const prePopulatedInfo = calPrePopulatedInfo(
          [...wristbandJson.zonesDetails.data],
          foundLabelIndexes[i].label
        );
        const countToPopulate = prePopulatedInfo.count;
        const indexToPopulate = prePopulatedInfo.arr;
        const zonesPrevStats = prePopulatedInfo.prevStats;
        const zonesPrevTotalPopulated = prePopulatedInfo.prevTotalPopulated;

        tempItems = reArrangeData(
          countToPopulate,
          indexToPopulate,
          foundLabelIndexes[i].label,
          foundLabelIndexes[i].arr,
          tempItems,
          zonesPrevStats,
          zonesPrevTotalPopulated == foundLabelIndexes[i].arr.length
        );
      }
      wristbandJson.zonesDetails.data = tempItems;

      let { zonesDataInfo, colorArr } = getZonesDataInfo([...tempItems]);
      const updatedWristbandWithZoneName = updatedGridConcatination(
        zonesDataInfo,
        colorArr,
        wristbandJson.wristBandDetails,
        activeSignsList
      );
      wristbandJson.wristBandDetails.gridData = updatedWristbandWithZoneName;
    }

    wristbandJson.opponent = "";
    let param = {
      name: shuffelWristbandName,
      color: data.color,
      wristband_json: JSON.stringify(wristbandJson),
      user_id: defaultUserId,
      mode: subscriptionMode,
    };
    createWristBand(param)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          alert.success("shuffled successfully"); // APP_ERROR_MSGS.SaveMsg
          populateTable();
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const afterQRRender = (base64, type) => {
    if (type == "coach") {
      setCoachCardQR(base64);
    }
    if (type == "college") {
      setCollegeCardQR(base64);
    }
  };

  // PDF
  const validateDownload = async (count = 1) => {
    try {
      const isLimitExceeded = await checkPdfDownloadLimitExceeded(count);
      const { message, status } = isLimitExceeded.data;
      if (!status) alert.info(message);
      return status;
    } catch (error) {
      alert.error("Something went wrong while validating download limit");
      return false;
    }
  };
  const handlePdfDownloadClick = async (type) => {
    const downloadAllowed =
      type === "coachCard" || type === "collegeCard"
        ? true
        : await validateDownload();

    if (!downloadAllowed) return;

    if (type == "playerCard") {
      dispatch({ type: UPDATE_LOADING, payload: true });
      setTimeout(() => {
        setSelectedPdfFileType(type);
      }, 200);
      // processPdf();
    }
    if (type == "coachCard") {
      // setShowPreviewModal(false);
      setIsShowQR(false);
      setPdfName("");
      setLayoutType(coachCardLayoutType.overThenDown);
      setShowPdfNameModal(true);
    }
    if (type == "pitcherCard") {
      dispatch({ type: UPDATE_LOADING, payload: true });
      setTimeout(() => {
        setSelectedPdfFileType(type);
      }, 200);
      // processPdf();
    }
    if (type == "catcherCard") {
      dispatch({ type: UPDATE_LOADING, payload: true });
      setTimeout(() => {
        setSelectedPdfFileType(type);
      }, 200);
      // processPdf();
    }
    if (type == "collegeCard") {
      setIsShowQR(false);
      setShowQRConfirmationModal(true);
    }
  };
  const onPdfRender = () => {
    if (pdfButtonRef.current) {
      pdfButtonRef.current.click();
      if (selectedPdfFileType === "coachCard") {
        if (layoutType == coachCardLayoutType.both) {
          setLayoutType(coachCardLayoutType.overThenDown);
          setIsReverseIndexes(true);
        } else {
          setSelectedPdfFileType(null);
          dispatch({ type: UPDATE_LOADING, payload: false });
        }
      } else {
        setSelectedPdfFileType(null);
        dispatch({ type: UPDATE_LOADING, payload: false });
      }
    }
  };
  // const processPdf = () => {
  //   setTimeout(() => {
  //     pdfButtonRef.current.click();
  //     dispatch({ type: UPDATE_LOADING, payload: false });
  //     setSelectedPdfFileType(null);
  //   }, 3000);
  // };
  const handleCoachCardSubmit = async (e) => {
    e.preventDefault();

    const downloadAllowed = await validateDownload(
      layoutType == coachCardLayoutType.both ? 2 : 1
    );
    if (!downloadAllowed) return;

    setIsReverseIndexes(
      layoutType == coachCardLayoutType.overThenDown ? true : false
    );
    dispatch({ type: UPDATE_LOADING, payload: true });
    setTimeout(() => {
      setSelectedPdfFileType("coachCard");
    }, 200);

    // setTimeout(() => {
    //   pdfButtonRef.current.click();
    //   if (layoutType != coachCardLayoutType.both) {
    //     setSelectedPdfFileType(null);
    //     dispatch({ type: UPDATE_LOADING, payload: false });
    //   }
    //   if (layoutType == coachCardLayoutType.both) {
    //     setLayoutType(coachCardLayoutType.overThenDown);
    //     setIsReverseIndexes(true);
    //     setTimeout(() => {
    //       pdfButtonRef.current.click();
    //       dispatch({ type: UPDATE_LOADING, payload: false });
    //       setSelectedPdfFileType(null);
    //     }, 4000);
    //   }
    // }, 4000);

    setShowPdfNameModal(false);
    // setShowPreviewModal(true);
  };
  const downloadCollegeCardPdf = async () => {
    const downloadAllowed = await validateDownload();
    if (!downloadAllowed) return;

    dispatch({ type: UPDATE_LOADING, payload: true });
    setTimeout(() => {
      setSelectedPdfFileType("collegeCard");
    }, 200);
    // setTimeout(() => {
    //   pdfButtonRef.current.click();
    //   dispatch({ type: UPDATE_LOADING, payload: false });
    //   setSelectedPdfFileType(null);
    // }, 4500);
    setShowQRConfirmationModal(false);
  };

  // User Default settings
  const setUserDefaultDimensions = (selectedBandType) => {
    let userDefaultSettings = JSON.parse(
      localStorage.getItem("userDefaultSettings")
    );

    let foundDefaultDimension =
      selectedBandType == wristBandType.pitching
        ? userDefaultSettings?.defaultPitchingDimension
        : selectedBandType == wristBandType.offensive
        ? userDefaultSettings?.defaultOffensiveDimension
        : selectedBandType == wristBandType.defensive
        ? userDefaultSettings?.defaultDefensiveDimension
        : "";
    if (foundDefaultDimension) {
      setSelectedDimension(parseInt(foundDefaultDimension));
      setPrintWidth(
        downloadPrintDimensions[parseInt(foundDefaultDimension)].width
      );
      setPrintHeight(
        downloadPrintDimensions[parseInt(foundDefaultDimension)].height
      );
    }
  };

  // Permissions start
  const validatePermission = (permission) =>
    isHavePermission(props.userPermissions, permission);

  const alertPermissionDenied = () =>
    alert.error(APP_ERROR_MSGS.AlertPermissionDenied);
  // Permissions start

  return (
    <>
      <QRGenerator type="college" val={bandId} afterQRRender={afterQRRender} />
      <QRGenerator type="coach" val={bandId} afterQRRender={afterQRRender} />

      <PitchingModal
        bandName={bandName}
        setbandName={setbandName}
        show={showPitchingModal}
        closeModal={() => setShowPitchingModal(false)}
        onFormSubmit={(e) => {
          e.preventDefault();
          onCreateSubmit();
        }}
      />
      <DeletesignModal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onDelete={deleteRecord}
      />

      <ViewModal
        wristBandDetails={selectedRow}
        show={showPreviewModal}
        closeModal={() => setShowPreviewModal(false)}
        singleDimensionOptions={downloadPrintDimensions}
        selectedDimension={selectedDimension}
        setPrintDimension={setPrintDimension}
        PrintHeight={PrintHeight}
        PrintWidth={PrintWidth}
        pdfName={pdfName}
        handlePdfDownloadClick={handlePdfDownloadClick}
        isReverseIndexes={isReverseIndexes}
        zoneInitialWidth={coachCradInitialWidth}
        zoneInitialHeight={coachCradInitialHeight}
        cardsViewType={cardsViewType}
        setCardsViewType={setCardsViewType}
        coachCardQR={coachCardQR}
        isShowQR={isShowQR}
        collegeCardQR={collegeCardQR}
        selectedPdfFileType={selectedPdfFileType}
        pdfButtonRef={pdfButtonRef}
        wristbandPitcherName={wristbandPitcherName}
        setWristbandPitcherName={setWristbandPitcherName}
        onPdfRender={onPdfRender}
        activeSigns={activeSigns}
      />

      <QRConfirmationModal
        show={showQRConfirmationModal}
        closeModal={() => setShowQRConfirmationModal(false)}
        downloadPdfFile={downloadCollegeCardPdf}
        isShowQR={isShowQR}
        setIsShowQR={setIsShowQR}
      />

      <EditshufflModal
        onEdit={handleEditWristband}
        show={showEditshufflModal}
        closeModal={() => setShowEditshufflModal(false)}
        onShuffelClick={handleSuffelWristband}
      />
      <ShuffleModal
        show={showShuffleModal}
        closeModal={() => setShowShuffleModal(false)}
        shuffelWristband={shuffelWristband}
        shuffelWristbandName={shuffelWristbandName}
        onNameChange={setShuffelWristbandName}
      />
      <PDFNameModal
        show={showPdfNameModal}
        closeModal={() => setShowPdfNameModal(false)}
        downloadPdfFile={handleCoachCardSubmit}
        pdfName={pdfName}
        setPdfName={(val) => setPdfName(val)}
        layoutType={layoutType}
        handleLayoutChange={setLayoutType}
        coachCradInitialHeight={coachCradInitialHeight}
        setCoachCradInitialHeight={setCoachCradInitialHeight}
        bandType={selectedRowJson?.type}
        isShowQR={isShowQR}
        setIsShowQR={setIsShowQR}
      >
        {selectedRowJson?.type == wristBandType.pitching ? (
          <CoachCard_V1
            title={pdfName}
            zones={selectedRowJson?.zonesDetails?.data}
            matrixType={selectedRowJson?.zonesDetails?.type}
            processedSigns={ProcessDataInArray(activeSigns, "abbreviation")}
            bandType={selectedRowJson?.type}
            selectedSigns={selectedRowJson?.signsIndexesArr}
            isReverseIndexes={isReverseIndexes}
            zoneInitialWidth={coachCradInitialWidth}
            zoneInitialHeight={coachCradInitialHeight}
          />
        ) : (
          <></>
        )}
      </PDFNameModal>

      <PitcherCardOverViewModal
        show={showPitcherCardOverViewModal}
        closeModal={() => setShowPitcherCardOverViewModal(false)}
        height={printPitcherHeight}
        handleHeightChange={setPrintPitcherHeight}
      >
        <PitcherCard
          height={printPitcherHeight}
          width={PrintWidth}
          bandType={selectedRowJson?.type}
          matrixType={selectedRowJson?.zonesDetails?.type}
          zones={selectedRowJson?.zonesDetails?.data}
          processedSigns={ProcessDataInArray(activeSigns, "abbreviation")}
          selectedSigns={selectedRowJson?.signsIndexesArr}
        />
      </PitcherCardOverViewModal>

      {/* <OffensiveModal show={showModal} closeModal={() => setShowModal(false)} />
        <DeffensiveModal show={showDeffensiveModal} closeModal={() => setShowDeffensiveModal(false)} /> */}
      {/* <DeleteModal show={showDeleteModal} closeModal={() => setShowDeleteModal(false)} /> */}

      <section className="table_section">
        <div className="container">
          <div className="prnt_head_div">
            <div className="head_div">
              <h2 className="heading_wrist">Listings of Wristbands</h2>
            </div>
            <div className="listing_btn_div">
              <button
                onClick={() => handleCreateBand(wristBandType.pitching)}
                className="btn_list"
              >
                Create New pitching Band
              </button>
              <button
                onClick={() => handleCreateBand(wristBandType.offensive)}
                className="btn_list"
              >
                Create New Offensive Band
              </button>
              <button
                onClick={() => handleCreateBand(wristBandType.defensive)}
                className="btn_list"
              >
                Create New Defensive Band
              </button>
            </div>
          </div>
          <div className="col-12 p-0 pt-5">
            <Table
              {...tableProps}
              onTableChange={handleTableChange}
              columns={columns}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(WristBandsSection);
