import { APIUrls, APP_ERROR_MSGS } from "../shared/constants";
import {
  APP_CONFIGS_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  SAVE_USER_PERMISSIONS,
  SHOW_ALERT,
  UPDATE_LOADING,
  USER_LOADED,
} from "./types";

import API from "../shared/API";
import setAuthToken from "../utils/setAuthToken";

export const loadUser = () => async (dispatch) => {
  //set header
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    try {
      await API.get(APIUrls.GetMe).then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.role == "admin") {
          dispatch({ type: USER_LOADED, payload: result.data });
        } else {
          dispatch({ type: AUTH_ERROR });
          dispatch({
            type: SHOW_ALERT,
            payload: { type: 0, message: "Unauthorized role." },
          });
        }
      });
    } catch (error) {
      dispatch({ type: AUTH_ERROR });
      dispatch({ type: UPDATE_LOADING, payload: false });
      dispatch({
        type: SHOW_ALERT,
        payload: {
          type: 0,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg,
        },
      });
    }
  } else {
    dispatch({ type: UPDATE_LOADING, payload: false });
  }
};
export const loginUser =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_LOADING, payload: true });
    // set header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // set body
    const body = JSON.stringify({ email, password });
    try {
      // login user
      const res = await API.post(APIUrls.Login, body, config);
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      dispatch(loadUser());
      // dispatch(loadAppConfigs());
    } catch (error) {
      dispatch({ type: UPDATE_LOADING, payload: false });
      dispatch({
        type: SHOW_ALERT,
        payload: {
          type: 0,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg,
        },
      });
    }
  };
export const logoutUser = () => async (dispatch) => {
  setAuthToken();
  dispatch({ type: LOGOUT_USER });
};

export const loadUserDetails = async (id = "", limit = 0) => {
  setAuthToken(localStorage.token);
  return await API.get(`${APIUrls.LoadUserDetails}/${id}/${limit}`);
};

export const saveUserDetails = async (param) => {
  setAuthToken(localStorage.token);
  return await API.post(`${APIUrls.SaveUserDetails}`, param);
};

export const loadUserPermissions =
  (mode = "") =>
  async (dispatch) => {
    dispatch({ type: UPDATE_LOADING, payload: true });
    try {
      setAuthToken(localStorage.token);
      await API.get(`${APIUrls.LoadUserPermissions}/${mode}`).then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          let permissions =
            result.data.data && result.data.data.length > 0
              ? result.data.data
              : "";
          dispatch({ type: SAVE_USER_PERMISSIONS, payload: permissions });
        } else {
          dispatch({
            type: SHOW_ALERT,
            payload: {
              type: 0,
              message: result.data.message
                ? result.data.message
                : "Error while permission fetch",
            },
          });
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_LOADING, payload: false });
      dispatch({
        type: SHOW_ALERT,
        payload: {
          type: 0,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : "Error while permission fetch",
        },
      });
    }
  };
