import React from "react";
import Modal from "react-modal";
import {
  gamingZonesColorList,
  zonesDefaultColor,
} from "../../shared/constants";
import { Group_24145 } from "../../shared/images";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function ZoneNameModal(props) {
  return (
    <Modal
      isOpen={props.show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="ZoneNameModal"
      class="customemODAL"
    >
      <button type="button" className="close" onClick={props.closeModal}>
        <i className="fas fa-times"></i>
      </button>
      <div className="modal_content">
        <h2 className="hire_us_head">Zone Name</h2>
        <div className="shuffle_div">
          <form onSubmit={props.updateZone}>
            <input
              type="text"
              placeholder="ENTER ZONE NAME"
              className="login_input"
              value={props.selectedZoneName}
              onChange={(e) => {
                if (e.target.value.toString().length < 4) {
                  props.setSelectedZoneName(e.target.value);
                }
              }}
              autoFocus
            />
            {/* zones color picker */}
            {props.isGammingMode && (
              <>
                <div className="selected_clr">
                  <h6 className="selected_clr_head align-left">
                    Selected Color
                  </h6>
                  <div className="color_img_div">
                    <div
                      className={`div_clr circle_incication_border ${
                        props.zoneColor === zonesDefaultColor.diable
                          ? "red_border"
                          : ""
                      }`}
                      style={{ backgroundColor: props.zoneColor }}
                      title={
                        props.zoneColor === zonesDefaultColor.diable
                          ? "No color"
                          : ""
                      }
                    ></div>
                  </div>
                </div>

                <div className="selectColors">
                  <h6 className="select_clr_head align-left">Select Color</h6>
                  <div className="clr_div">
                    {gamingZonesColorList.map((item) => {
                      return (
                        <div
                          className="color_img_div zone_name_circle"
                          onClick={(e) => props.handleColorSelect(item)}
                        >
                          <div
                            className="div_clr circle_incication_border pointer"
                            style={{ backgroundColor: item }}
                          ></div>
                        </div>
                      );
                    })}

                    {/* no color */}
                    <div
                      className="color_img_div zone_name_circle"
                      onClick={() =>
                        props.handleColorSelect(zonesDefaultColor.diable)
                      }
                    >
                      <div
                        className="div_clr circle_incication_border pointer red_border"
                        style={{ backgroundColor: zonesDefaultColor.diable }}
                        title="No color"
                      ></div>
                    </div>

                    <div className="input_div">
                      <input
                        type="color"
                        className="color_picker frColr"
                        onChange={(e) =>
                          props.handleColorSelect(e.target.value)
                        }
                      />
                      <img
                        src={Group_24145}
                        alt=""
                        className="color_img_picker frz custom-clr-picker"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <button type="submit" className="input_login_btn_mrgn">
              Save
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
}
