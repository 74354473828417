import React, { useEffect, useState, useRef } from "react";
// import QRScanner from "../QRScanner";
import {
  getSacnWristBandDetail,
  validateQRScannEmail,
  validateEmailVerificationCode,
} from "../../actions/wristBands";
import { UPDATE_LOADING } from "../../actions/types";
import {
  ProcessDataInArray,
  getCoachCardInitialWidth,
} from "../../shared/functions";
import {
  wristBandType,
  APP_ERROR_MSGS,
  coachCardLayoutType,
} from "../../shared/constants";
import { useDispatch } from "react-redux";
import PDFNameModal from "../Modals/PDFNameModal";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfWrapper from "../pages/wristband/PdfWrapper";
import CoachCard from "../pages/wristband/CoachCard";
import CollegeCard from "../pages/wristband/CollegeCard";
import QRGenerator from "../QRGenerator";
import QRConfirmationModal from "../Modals/QRConfirmationModal";
import EmailConfirmationModal from "../Modals/EmailConfirmationModal";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import PDFDownloadLinkWrapper from "./wristband/PDFDownloadLinkWrapper";

export default function ScanQRCode() {
  const params = useParams();

  const dispatch = useDispatch();
  const alert = useAlert();

  const coachCardRef = useRef();
  const collegeCardRef = useRef();
  const pdfButtonRef = useRef();

  const [pdfType, setPdfType] = useState("");
  const [showPdfNameModal, setShowPdfNameModal] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [coachCradInitialWidth, setCoachCradInitialWidth] = useState(120);
  const [coachCradInitialHeight, setCoachCradInitialHeight] = useState(50);
  const [selectedRowJson, setSelectedJson] = useState(null);
  const [isShowQR, setIsShowQR] = useState(false);
  const [isReverseIndexes, setIsReverseIndexes] = useState(false);
  const [bandId, setBandId] = useState(null);
  const [coachCardQR, setCoachCardQR] = useState("");
  const [collegeCardQR, setCollegeCardQR] = useState("");
  const [showQRConfirmationModal, setShowQRConfirmationModal] = useState(false);
  const [showEmailConfirmationModal, setShowEmailConfirmationModal] =
    useState(false);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationStep, setVerificationStep] = useState("email");
  const [bandName, setBandName] = useState("");
  const [selectedPdfFileType, setSelectedPdfFileType] = useState(null);
  const [activeSigns, setActiveSigns] = useState([]);

  const [layoutType, setLayoutType] = useState(
    coachCardLayoutType.overThenDown
  );

  useEffect(() => {
    let result = params.PdfDetail;
    if (result) {
      let resultArr = result.split("-");
      let type = resultArr[0];
      let id = resultArr[1];
      setPdfType(type);
      setBandId(id);
      setEmail("");
      setVerificationCode("");
      setShowEmailConfirmationModal(true);
    }
  }, []);

  // const handleQRCodeScan = (result) => {
  //   let resultArr = result.split("-");
  //   let type = resultArr[0];
  //   let id = resultArr[1];
  //   setPdfType(type);
  //   setBandId(id);
  //   setEmail("");
  //   setShowEmailConfirmationModal(true);
  // };
  const onEmailSubmit = (e) => {
    e.preventDefault();
    let EmailDomain = email.split("@")[1];
    if (EmailDomain.includes(".edu")) {
      if (verificationStep === "email") {
        validateUserEmail();
      } else if (verificationStep === "code") {
        validateVerificationCode();
      }
    } else {
      alert.error("Email is invalid");
    }
  };
  const getWristbandDetails = (id, scannedType) => {
    dispatch({ type: UPDATE_LOADING, payload: true });
    getSacnWristBandDetail(id, { email: email })
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          let wristband = result.data.data;
          setBandName(wristband.name);
          let selectedjson = JSON.parse(wristband.wristband_json);
          setSelectedJson(selectedjson);

          // active signs
          const activeSignsList = [...selectedjson.signs].filter(
            (el) => el.status == true
          );
          setActiveSigns(activeSignsList);

          setCoachCradInitialWidth(
            getCoachCardInitialWidth(selectedjson?.zonesDetails?.type)
          );

          if (scannedType == "coach") {
            setIsShowQR(false);
            setPdfName("");
            setLayoutType(coachCardLayoutType.overThenDown);
            setShowPdfNameModal(true);
          }
          if (scannedType == "college") {
            setIsShowQR(false);
            setShowQRConfirmationModal(true);
          }
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };

  const afterQRRender = (base64, type) => {
    if (type == "coach") {
      setCoachCardQR(base64);
    }
    if (type == "college") {
      setCollegeCardQR(base64);
    }
  };
  const downloadPdfFile = (e) => {
    e.preventDefault();
    setIsReverseIndexes(
      layoutType == coachCardLayoutType.overThenDown ? true : false
    );
    dispatch({ type: UPDATE_LOADING, payload: true });
    setTimeout(() => {
      setSelectedPdfFileType("coachCard");
    }, 200);
    // setTimeout(() => {
    //   coachCardRef.current.click();
    //   if (layoutType == coachCardLayoutType.both) {
    //     setLayoutType(coachCardLayoutType.overThenDown);
    //     setIsReverseIndexes(true);
    //     setTimeout(() => {
    //       coachCardRef.current.click();
    //     }, 4000);
    //   }
    // }, 4000);
    setShowPdfNameModal(false);
  };
  const downloadCollegeCardPdf = () => {
    dispatch({ type: UPDATE_LOADING, payload: true });
    setTimeout(() => {
      setSelectedPdfFileType("collegeCard");
    }, 200);
    setShowQRConfirmationModal(false);
    // setTimeout(() => {
    //   dispatch({ type: UPDATE_LOADING, payload: false });
    //   collegeCardRef.current.click();
    //   setShowQRConfirmationModal(false);
    // }, 4500);
  };
  const onPdfRender = () => {
    if (pdfButtonRef.current) {
      pdfButtonRef.current.click();
      if (selectedPdfFileType === "coachCard") {
        if (layoutType == coachCardLayoutType.both) {
          setLayoutType(coachCardLayoutType.overThenDown);
          setIsReverseIndexes(true);
        } else {
          setSelectedPdfFileType(null);
          dispatch({ type: UPDATE_LOADING, payload: false });
        }
      } else {
        setSelectedPdfFileType(null);
        dispatch({ type: UPDATE_LOADING, payload: false });
      }
    }
  };

  const validateUserEmail = () => {
    dispatch({ type: UPDATE_LOADING, payload: true });
    validateQRScannEmail(email)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          if (result.data.message == "Email already Exist") {
            getWristbandDetails(bandId, pdfType);
            setShowEmailConfirmationModal(false);
          } else {
            setVerificationStep("code");
          }
        } else {
          setVerificationStep("email");
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        setVerificationStep("email");
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  const validateVerificationCode = () => {
    dispatch({ type: UPDATE_LOADING, payload: true });
    validateEmailVerificationCode(email, verificationCode)
      .then((result) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        if (result.data.status == true) {
          getWristbandDetails(bandId, pdfType);
          setShowEmailConfirmationModal(false);
        } else {
          alert.error(
            result.data.message
              ? result.data.message
              : APP_ERROR_MSGS.StandardErrorMsg
          );
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADING, payload: false });
        alert.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : APP_ERROR_MSGS.StandardErrorMsg
        );
      });
  };
  return (
    <>
      <QRGenerator type={pdfType} val={bandId} afterQRRender={afterQRRender} />

      <PDFNameModal
        show={showPdfNameModal}
        closeModal={() => setShowPdfNameModal(false)}
        downloadPdfFile={downloadPdfFile}
        pdfName={pdfName}
        isScaneMode={true}
        setPdfName={(val) => setPdfName(val)}
        layoutType={layoutType}
        handleLayoutChange={setLayoutType}
        coachCradInitialHeight={coachCradInitialHeight}
        setCoachCradInitialHeight={setCoachCradInitialHeight}
        bandType={selectedRowJson?.type}
        isShowQR={isShowQR}
        setIsShowQR={setIsShowQR}
      ></PDFNameModal>

      <QRConfirmationModal
        show={showQRConfirmationModal}
        closeModal={() => setShowQRConfirmationModal(false)}
        downloadPdfFile={downloadCollegeCardPdf}
        isShowQR={isShowQR}
        setIsShowQR={setIsShowQR}
        isScaneMode={true}
      />

      <EmailConfirmationModal
        show={showEmailConfirmationModal}
        closeModal={() => setShowEmailConfirmationModal(false)}
        onSubmit={onEmailSubmit}
        email={email}
        setEmail={setEmail}
        verificationStep={verificationStep}
        code={verificationCode}
        setCode={setVerificationCode}
        resendVerificationCode={validateUserEmail}
      />

      {/* <QRScanner afterScan={handleQRCodeScan} /> */}

      {selectedPdfFileType && (
        <PDFDownloadLinkWrapper
          pdfName={
            selectedPdfFileType === "coachCard"
              ? `${bandName}_CoachCard.pdf`
              : selectedPdfFileType === "collegeCard"
              ? `${bandName}_CollegeCoachCard.pdf`
              : ""
          }
          buttonRef={pdfButtonRef}
          onPdfRender={onPdfRender}
        >
          {selectedPdfFileType === "coachCard" ? (
            <PdfWrapper>
              <CoachCard
                title={pdfName}
                zones={selectedRowJson?.zonesDetails?.data}
                matrixType={selectedRowJson?.zonesDetails?.type}
                processedSigns={ProcessDataInArray(activeSigns, "abbreviation")}
                bandType={selectedRowJson?.type}
                selectedSigns={selectedRowJson?.signsIndexesArr}
                isReverseIndexes={isReverseIndexes}
                zoneInitialWidth={coachCradInitialWidth}
                zoneInitialHeight={coachCradInitialHeight}
                QRUrl={coachCardQR}
                isShowQR={isShowQR}
                isMirrorView={
                  selectedRowJson?.zonesDetails?.isMirrorView
                    ? selectedRowJson?.zonesDetails?.isMirrorView
                    : false
                }
                fitToScreen={true}
              ></CoachCard>
            </PdfWrapper>
          ) : selectedPdfFileType === "collegeCard" ? (
            <CollegeCard
              wristbandPattern={selectedRowJson?.wristBandDetails}
              zones={selectedRowJson?.zonesDetails?.data}
              matrixType={selectedRowJson?.zonesDetails?.type}
              processedSigns={ProcessDataInArray(activeSigns, "abbreviation")}
              bandType={selectedRowJson?.type}
              selectedSigns={selectedRowJson?.signsIndexesArr}
              QRUrl={collegeCardQR}
              isShowQR={isShowQR}
              isMirrorView={
                selectedRowJson?.zonesDetails?.isMirrorView
                  ? selectedRowJson?.zonesDetails?.isMirrorView
                  : false
              }
            />
          ) : (
            <></>
          )}
        </PDFDownloadLinkWrapper>
      )}

      {/* Coach card */}
      {/* <PDFDownloadLink
        document={
          <PdfWrapper>
            <CoachCard
              title={pdfName}
              zones={selectedRowJson?.zonesDetails?.data}
              matrixType={selectedRowJson?.zonesDetails?.type}
              processedSigns={ProcessDataInArray(
                activeSigns,
                "abbreviation"
              )}
              bandType={selectedRowJson?.type}
              selectedSigns={selectedRowJson?.signsIndexesArr}
              isReverseIndexes={isReverseIndexes}
              zoneInitialWidth={coachCradInitialWidth}
              zoneInitialHeight={coachCradInitialHeight}
              QRUrl={coachCardQR}
              isShowQR={isShowQR}
              isMirrorView={
                selectedRowJson?.zonesDetails?.isMirrorView
                  ? selectedRowJson?.zonesDetails?.isMirrorView
                  : false
              }
              fitToScreen={true}
            >
            </CoachCard>
          </PdfWrapper>
        }
        fileName={`${bandName}_CoachCard.pdf`}
      >
        {({ blob, url, loading, error }) => (
          <button
            type="button"
            className="input_login_btn disable_Class"
            disabled={loading}
            ref={coachCardRef}
            hidden
          >
            {loading ? "Loading Coach Card..." : "Download Coach Card"}
          </button>
        )}
      </PDFDownloadLink> */}

      {/* College Coach Card */}
      {/* {selectedRowJson && (
        <PDFDownloadLink
          document={
            <CollegeCard
              wristbandPattern={selectedRowJson?.wristBandDetails}
              zones={selectedRowJson?.zonesDetails?.data}
              matrixType={selectedRowJson?.zonesDetails?.type}
              processedSigns={ProcessDataInArray(
                activeSigns,
                "abbreviation"
              )}
              bandType={selectedRowJson?.type}
              selectedSigns={selectedRowJson?.signsIndexesArr}
              QRUrl={collegeCardQR}
              isShowQR={isShowQR}
              isMirrorView={
                selectedRowJson?.zonesDetails?.isMirrorView
                  ? selectedRowJson?.zonesDetails?.isMirrorView
                  : false
              }
            />
          }
          fileName={`${bandName}_CollegeCoachCard.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <button
              type="button"
              className="dnld_btn_1 mt-3 disable_Class"
              disabled={loading}
              ref={collegeCardRef}
              hidden
            >
              {loading
                ? "Loading College Coach Card..."
                : "Download College Coach Card"}
            </button>
          )}
        </PDFDownloadLink>
      )} */}
    </>
  );
}
