// Page loader
export const UPDATE_LOADING = "UPDATE_LOADING";

// Alert
export const SHOW_ALERT = "SHOW_ALERT";

//  Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

// User Auth
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const APP_CONFIGS_LOADED = "APP_CONFIGS_LOADED";
export const SAVE_USER_PERMISSIONS = "SAVE_USER_PERMISSIONS";
