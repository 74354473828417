import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

import PrintWistband from "../pages/wristband/print_wistband";
import CoachCard from "../pages/wristband/CoachCard";
import CoachCard_V1 from "../pages/wristband/CoachCard_V1";
import CollegeCard from "../pages/wristband/CollegeCard";
import PitcherCard from "../pages/wristband/PitcherCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ProcessDataInArray } from "../../shared/functions";
import PdfWrapper from "../pages/wristband/PdfWrapper";
import CardTypeRadio from "../CardTypeRadio";
import PDFDownloadLinkWrapper from "../pages/wristband/PDFDownloadLinkWrapper";
import {
  UserSubscriptionModes,
  defaultZoneSizeLink,
} from "../../shared/constants";
import DropDown from "../dropdownRendrer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function ViewModal(props) {
  const subscriptionMode = localStorage.getItem("mode");

  const bandDetails = props.wristBandDetails;
  const selectedjson = bandDetails
    ? JSON.parse(bandDetails?.wristband_json)
    : null;
  const bandName = bandDetails?.name;

  const downloadFileName = (type) =>
    `${
      props.wristbandPitcherName ? props.wristbandPitcherName : bandName
    }_${type}.pdf`;

  return (
    <>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={props.show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="viewModal"
        class="customemODAL"
      >
        <button type="button" className="close" onClick={props.closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <div className="modal_content">
          <h2 className="hire_us_head">Download wristbands</h2>
          <div className="slect-demo">
            <p className="prnt_2">Select Dimension</p>

            {props.selectedDimension ===
              props.singleDimensionOptions[0].val && (
              <a
                href={defaultZoneSizeLink}
                target="_blank"
                className="ancher_link"
              >
                View details
              </a>
            )}
          </div>

          <DropDown
            multi={false}
            dropdownRenderer={false}
            contentRenderer={false}
            closeOnSelect={true}
            create={false}
            onChange={(lable, val) => props.setPrintDimension(val)}
            Type="selectedDimension"
            options={props.singleDimensionOptions}
            val={props.selectedDimension}
            loading={false}
            searchBy="label"
            labelField="label"
            valueField="val"
            placeholder="Select"
            clearable={false}
            searchable={false}
          />

          {/* <select
            value={props.selectedDimension}
            style={{ width: "100%" }}
            className="select_input"
            onChange={(e) => props.setPrintDimension(e.target.value)}
          >
            {props.singleDimensionOptions.map(function (item, index) {
              return (
                <option value={item.val} title={item.label}>
                  {item.label}
                </option>
              );
            })}
          </select> */}

          <div className="pt-2">
            <p className="prnt_2">Enter file Name</p>
            <input
              type="text"
              className="login_input"
              value={props.wristbandPitcherName}
              onChange={(e) => props.setWristbandPitcherName(e.target.value)}
            />
          </div>

          {/* <CardTypeRadio
            handleOptionChange={props.setCardsViewType}
            CardsType={props.cardsViewType}
          /> */}
          {props.selectedPdfFileType && (
            <PDFDownloadLinkWrapper
              pdfName={downloadFileName(
                props.selectedPdfFileType === "playerCard"
                  ? "PlayerCard"
                  : props.selectedPdfFileType === "coachCard"
                  ? "CoachCard"
                  : props.selectedPdfFileType === "pitcherCard"
                  ? `PitcherCard`
                  : props.selectedPdfFileType === "catcherCard"
                  ? `CatcherCard`
                  : props.selectedPdfFileType === "collegeCard"
                  ? `CollegeCoachCard`
                  : ""
              )}
              buttonRef={props.pdfButtonRef}
              onPdfRender={props.onPdfRender}
            >
              {props.selectedPdfFileType === "playerCard" ? (
                <PdfWrapper>
                  <PrintWistband
                    height={props.PrintHeight}
                    width={props.PrintWidth}
                    wristbandPattern={selectedjson?.wristBandDetails}
                    wristbandPitcherName="" //{props.wristbandPitcherName}
                    printCount={
                      props.cardsViewType === 0
                        ? 3 //1
                        : props.PrintHeight > 180
                        ? 3
                        : 3 //4
                    }
                  />
                </PdfWrapper>
              ) : props.selectedPdfFileType === "coachCard" ? (
                <PdfWrapper>
                  <CoachCard
                    title={props.pdfName}
                    zones={selectedjson?.zonesDetails?.data}
                    matrixType={selectedjson?.zonesDetails?.type}
                    processedSigns={ProcessDataInArray(
                      props.activeSigns,
                      "abbreviation"
                    )}
                    bandType={selectedjson?.type}
                    selectedSigns={selectedjson?.signsIndexesArr}
                    isReverseIndexes={props.isReverseIndexes}
                    zoneInitialWidth={props.zoneInitialWidth}
                    zoneInitialHeight={props.zoneInitialHeight}
                    QRUrl={props.coachCardQR}
                    isShowQR={props.isShowQR}
                    isMirrorView={
                      selectedjson?.zonesDetails?.isMirrorView
                        ? selectedjson?.zonesDetails?.isMirrorView
                        : false
                    }
                    fitToScreen={true}
                  />
                </PdfWrapper>
              ) : props.selectedPdfFileType === "pitcherCard" ? (
                <PitcherCard
                  height={props.PrintHeight}
                  width={props.PrintWidth}
                  bandType={selectedjson?.type}
                  matrixType={selectedjson?.zonesDetails?.type}
                  zones={selectedjson?.zonesDetails?.data}
                  processedSigns={ProcessDataInArray(
                    props.activeSigns,
                    "abbreviation"
                  )}
                  selectedSigns={selectedjson?.signsIndexesArr}
                  wristbandPitcherName="" // {props.wristbandPitcherName}
                  // printCount={
                  //   props.cardsViewType === 0
                  //     ? 1
                  //     : props.PrintHeight > 180
                  //     ? 3
                  //     : 3 //4
                  // }
                />
              ) : props.selectedPdfFileType === "catcherCard" ? (
                <PdfWrapper>
                  <PrintWistband
                    height={props.PrintHeight}
                    width={props.PrintWidth}
                    wristbandPattern={selectedjson?.wristBandDetails}
                    wristbandPitcherName="" // {props.wristbandPitcherName}
                    // printCount={
                    //   props.cardsViewType === 0
                    //     ? 1
                    //     : props.PrintHeight > 180
                    //     ? 3
                    //     : 3 //4
                    // }
                  />
                </PdfWrapper>
              ) : props.selectedPdfFileType === "collegeCard" ? (
                <CollegeCard
                  wristbandPattern={selectedjson?.wristBandDetails}
                  zones={selectedjson?.zonesDetails?.data}
                  matrixType={selectedjson?.zonesDetails?.type}
                  processedSigns={ProcessDataInArray(
                    props.activeSigns,
                    "abbreviation"
                  )}
                  bandType={selectedjson?.type}
                  selectedSigns={selectedjson?.signsIndexesArr}
                  QRUrl={props.collegeCardQR}
                  isShowQR={props.isShowQR}
                  wristbandPitcherName="" // {props.wristbandPitcherName}
                  isMirrorView={
                    selectedjson?.zonesDetails?.isMirrorView
                      ? selectedjson?.zonesDetails?.isMirrorView
                      : false
                  }
                />
              ) : (
                <></>
              )}
            </PDFDownloadLinkWrapper>
          )}
          {/* Game mode */}
          {subscriptionMode === UserSubscriptionModes.gamming && (
            <>
              {/* hidden={props.cardsViewType == 0 ? false : true} */}
              {/* Player Card */}
              <button
                type="button"
                className="input_login_btn mt-3 disable_Class"
                onClick={() => props.handlePdfDownloadClick("playerCard")}
              >
                Download Player Card
              </button>
              {/* Coach Card */}
              <button
                type="button"
                className="input_login_btn mt-3 disable_Class"
                onClick={() => props.handlePdfDownloadClick("coachCard")}
              >
                Download Coach Card
              </button>
              {/* College Card */}
              <button
                type="button"
                className="input_login_btn mt-3 disable_Class"
                onClick={() => props.handlePdfDownloadClick("collegeCard")}
              >
                Download College Coach Card
              </button>
            </>
          )}

          {subscriptionMode === UserSubscriptionModes.showCase && (
            <>
              {/* hidden={props.cardsViewType == 1 ? false : true} */}
              {/* Pitcher Card */}
              <button
                type="button"
                className="input_login_btn mt-3 disable_Class"
                onClick={() => props.handlePdfDownloadClick("pitcherCard")}
              >
                Download Pitcher Card
              </button>
              {/* Catcher Card */}
              <button
                type="button"
                className="input_login_btn mt-3 disable_Class"
                onClick={() => props.handlePdfDownloadClick("catcherCard")}
              >
                Download Catcher Card
              </button>
              {/* College Coach Card */}
              <button
                type="button"
                className="input_login_btn mt-3 disable_Class"
                onClick={() => props.handlePdfDownloadClick("collegeCard")}
              >
                Download College Coach Card
              </button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
