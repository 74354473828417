import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function MessageAlertModal({
  label,
  message,
  closeModal,
  show,
}) {
  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={label}
      class="customemODAL"
    >
      <button type="button" className="close" onClick={closeModal}>
        <i className="fas fa-times"></i>
      </button>
      <div className="modal_content">
        <h2
          style={{ fontSize: "20px", paddingTop: "20px" }}
          className="hire_us_head"
        >
          {message}
        </h2>
        <div className="shuffle_div">
          <button
            className="input_login_btn_mrgn"
            onClick={closeModal}
            type="button"
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
}
