import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import {
  convertToCommaString,
  getReverseIndexes,
  getMergeZoneMarginVal,
} from "../../../shared/functions";
import { wristBandType } from "../../../shared/constants";

// Font.register({
//     family: 'Douglas',
//     src: 'https://twemoji.maxcdn.com/2/72x72/',
//     format: 'png'
// });

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  CoachCardTitle: {
    textAlign: "center",
    paddingTop: 5,
    fontSize: 15,
    fontFamily: "Oswald",
    textTransform: "uppercase",
  },
  BoxHeader: {
    borderBottom: `2px solid #363940`,
    fontSize: 9,
    fontStyle: "normal",
    color: "#363940",
    marginBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  BoxContent: {},
});

function CoachCard_V1(props) {
  const zonePadding = 2;

  const getboxWidth = (item) => {
    if (item.mergedZonesCount == 1) return props.zoneInitialWidth;
    let marginval = getMergeZoneMarginVal(item.mergedZonesCount);
    return (
      props.zoneInitialWidth * item.mergedZonesCount + zonePadding * marginval
    );
  };
  const getboxHeight = (item) => {
    if (item.verticalmergedZonesCount == 1) return props.zoneInitialHeight;
    let marginval = getMergeZoneMarginVal(item.verticalmergedZonesCount);
    return (
      props.zoneInitialHeight * item.verticalmergedZonesCount +
      zonePadding * marginval
    );
  };
  const getItemStyle = (width, height, item, opacity, zIndex, isHidden) => ({
    minHeight: height, //zoneInitialHeight,
    width: width,
    border: `2px solid #363940`,
    margin: `${zonePadding}px`,
    textAlign: "center",
    opacity: opacity, // isHidden ? 0 : 1, //opacity,
    // zIndex: zIndex,
  });
  const getPitchingItemStyle = (
    width,
    height,
    item,
    opacity,
    zIndex,
    isHidden,
    index,
    itemIndex
  ) => {
    let arr = [...props.zones[index]];
    arr.length = itemIndex;
    let prevBoxes = 0;
    if (arr.length > 0) {
      for (let i in arr) {
        prevBoxes = prevBoxes + arr[i].mergedZonesCount;
      }
    }

    let baseStyle = {
      height: height, //zoneInitialHeight,
      width: width,
      border: `2px solid #363940`,
      margin: `${zonePadding}px`,
      textAlign: "center",
      position: "absolute",
      // top:
      //   index == 0
      //     ? 0
      //     : index * props.zoneInitialHeight + index * zonePadding * 2,
      left:
        itemIndex == 0
          ? 0
          : prevBoxes * props.zoneInitialWidth + prevBoxes * zonePadding * 2,
      opacity: opacity, // isHidden ? 0 : 1, //opacity,
    };

    if (zIndex == 1) {
      return {
        ...baseStyle,
        // zIndex: 0.1,
      };
    } else {
      return {
        ...baseStyle,
        // backgroundColor: "white",
      };
    }
  };
  const getLayoutLabel = () =>
    props.isReverseIndexes ? "Over, Then Down" : "Down, Then Over";
  const getzonePadding = () => {
    return zonePadding * 2;
  };
  return (
    <Document>
      <Page size="A4" wrap={false}>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
        >
          <div style={styles.CoachCardTitle}>
            <Text>{props.title}</Text>
          </div>
          {props.bandType == wristBandType.pitching ? (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0px 50px 5px 50px",
              }}
            >
              <Text style={{ fontSize: "15px", paddingLeft: "20px" }}>R</Text>
              <Text style={{ fontSize: "8px" }}>({getLayoutLabel()})</Text>
              <Text style={{ fontSize: "15px", paddingRight: "20px" }}>L</Text>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Text style={{ fontSize: "8px" }}>({getLayoutLabel()})</Text>
            </div>
          )}

          {props.bandType == wristBandType.pitching ? (
            <div
              style={{
                position: "relative",
                margin: "0px 20px",
              }}
            >
              {props.zones?.map((containerItem, containerIndex) => (
                <>
                  <div
                    // wrap={false}
                    style={{
                      flexDirection: "row",
                      height: props.zoneInitialHeight,
                      marginTop: zonePadding,
                      position: "relative",
                    }}
                  >
                    {/* <div
                      style={{
                        // height: props.zoneInitialHeight,
                        position: "absolute",
                        top:
                          containerIndex == 0
                            ? 0
                            : containerIndex * props.zoneInitialHeight +
                              containerIndex * zonePadding * 2,
                      }}
                    > */}
                    {containerItem.map((item, index) => (
                      <div
                        style={getPitchingItemStyle(
                          getboxWidth(item),
                          getboxHeight(item),
                          item,
                          item.opacity,
                          item.zIndex,
                          item.content && item.data.length > 0 ? false : true,
                          containerIndex,
                          index
                        )}
                      >
                        <div style={styles.BoxHeader}>
                          <Text>{item.content ? item.content : "OFF"}</Text>
                        </div>
                        <div style={styles.BoxContent}>
                          {item.data?.map((signsItem, signsIndex) => (
                            <div>
                              <div
                                style={{
                                  color: "white",
                                  backgroundColor:
                                    props.processedSigns[signsItem.label].color,
                                  fontSize: "8px",
                                  fontWeight: "600",
                                  padding: "2px 0px",
                                  textTransform: "uppercase",
                                }}
                              >
                                <Text>{signsItem.label}</Text>
                              </div>
                              <div
                                style={{
                                  fontSize: 5,
                                  color: "#363940",
                                  padding: "2px 0px",
                                }}
                              >
                                <Text>
                                  {convertToCommaString(
                                    props.isReverseIndexes
                                      ? getReverseIndexes([...signsItem.arr])
                                      : signsItem.arr
                                  )}
                                </Text>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    {/* </div> */}
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                padding: "20px 48px 0px 48px",
              }}
            >
              {props.selectedSigns?.map((item, index) => (
                <div style={getItemStyle(120, 100, null, 1, 1, false)}>
                  <div style={styles.BoxContent}>
                    <div
                      style={{
                        color: "white",
                        backgroundColor: props.processedSigns[item.label].color,
                        fontSize: "8px",
                        fontWeight: "600",
                        padding: "2px 0px",
                        textTransform: "uppercase",
                      }}
                    >
                      <Text>{props.processedSigns[item.label].name}</Text>
                    </div>
                    <div
                      style={{
                        fontSize: 7,
                        color: "#363940",
                        padding: "2px 0px",
                      }}
                    >
                      <Text>
                        {convertToCommaString(
                          props.isReverseIndexes
                            ? getReverseIndexes([...item.arr])
                            : item.arr
                        )}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Page>
    </Document>
  );
}

export default CoachCard_V1;
