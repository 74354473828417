import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import {
  convertToCommaString,
  getReverseIndexes,
  getSelectZoneSizeObj,
  getMergeZoneMarginVal,
  measureTextWidth,
} from "../../../shared/functions";
import { wristBandType } from "../../../shared/constants";
import appLogo from "../../../assets/images/Asset_1@4x.png";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

function PitcherCard(props) {
  const spaceBetweenVale = 3;
  const zonePadding = 1;
  const zoneInitialHeight = 20;
  const InitialFontSize = 8;

  const [pitchingSigns, setPitchingSigns] = useState([]);
  const [textFontSize, setTextFontSize] = useState(0);
  const [rowInitialHeight, setRowInitialHeight] = useState(0);

  const getboxHeight = (item) => {
    if (item.verticalmergedZonesCount == 1) return zoneInitialHeight;
    let marginval = getMergeZoneMarginVal(item.verticalmergedZonesCount);
    return (
      zoneInitialHeight * item.verticalmergedZonesCount +
      zonePadding * marginval
    );
  };
  const getPitchingRowHeight = () => {
    return props.height / pitchingSigns?.length;
  };

  // Functions
  const getColumnWidth = (percent) => {
    return (props.width / 100) * percent;
  };
  const getboxWidth = (item) => {
    let zoneInitialWidth = 0;
    let obj = getSelectZoneSizeObj(props.matrixType);
    let boxCount = obj ? obj.x : 0; // props.matrixType == 16 ? 4 : 5;
    zoneInitialWidth = (getColumnWidth(35) - spaceBetweenVale) / boxCount;

    if (item.mergedZonesCount == 1) return zoneInitialWidth - zonePadding;
    let marginval = getMergeZoneMarginVal(item.mergedZonesCount);
    return zoneInitialWidth * item.mergedZonesCount + zonePadding * marginval;
  };

  const getBoxFontSize = (labelCount) => {
    let zoneInitialWidth = 0;
    let obj = getSelectZoneSizeObj(props.matrixType);
    let boxCount = obj ? obj.x : 0; // props.matrixType == 16 ? 4 : 5;
    zoneInitialWidth = (getColumnWidth(35) - spaceBetweenVale) / boxCount;
    let size = +Math.round(zoneInitialWidth) / 3;
    return labelCount > 2 ? size - 2 : size;
  };
  const getItemStyle = (width, height, item, opacity, zIndex, isHidden) => ({
    minHeight: height, //zoneInitialHeight,
    width: width,
    border: `1px solid #363940`,
    margin: `${zonePadding}px`,
    textAlign: "center",
    opacity: isHidden ? 0 : 1, //opacity,
    zIndex: zIndex,
    backgroundColor: item.color,
  });
  const getRowHeight = () => {
    return props.height / props.selectedSigns?.length;
  };
  const getRowFontSize = (height) => {
    let size = +Math.round(height) - 2;
    return size > 7 ? 7 : size;
  };
  const getRowDetails = (List, SignWidth) => {
    let rowsCount = 0;
    let fontSize = 0;
    let singleRowHieght = 0;
    let containerWidth =
      getColumnWidth(props.bandType == wristBandType.pitching ? 65 : 100) -
      SignWidth;
    for (let i in List) {
      let textWidth = measureTextWidth(
        convertToCommaString(List[i].arr),
        InitialFontSize
      );
      let signRows = 1;
      if (textWidth > containerWidth) {
        let decimalRowsCount = textWidth / containerWidth;
        if (decimalRowsCount.toString().includes(".")) {
          signRows = parseInt(decimalRowsCount.toString().split(".")[0]) + 1;
        } else {
          signRows = parseInt(decimalRowsCount);
        }
      }
      List[i].rowsCount = signRows;
      rowsCount = rowsCount + signRows;
    }
    singleRowHieght = props.height / rowsCount;
    fontSize = parseFloat((singleRowHieght * 0.8).toFixed(2));
    if (fontSize > InitialFontSize) fontSize = InitialFontSize;

    // console.log("total count", rowsCount);
    // console.log("single row hieght", singleRowHieght);
    // console.log("font size", fontSize);
    // console.log("List", List);

    return {
      rowsCount: rowsCount,
      fontSize: fontSize,
      singleRowHieght: singleRowHieght,
      formattedList: List,
    };
  };

  useEffect(() => {
    if (props.bandType == wristBandType.pitching && props.zones) {
      let zonesList = [...props.zones];
      let pitchingSigns = [];
      for (let i = 0; i <= zonesList.length; i++) {
        for (const j in zonesList[i]) {
          if (zonesList[i][j].data?.length > 0) {
            for (const l in zonesList[i][j].data) {
              const obj = zonesList[i][j].data[l];
              obj.zoneName = zonesList[i][j].content;
              obj.zoneColor = zonesList[i][j].color;
              obj.title = `${obj.label}${obj.zoneName}`;
              pitchingSigns.push(obj);
            }
          }
        }
      }
      pitchingSigns.sort((a, b) => (a.label > b.label ? 1 : -1));
      const keys = [...new Set(pitchingSigns.map((x) => x.label))];
      let splitDate = keys.map((x) => ({
        val: pitchingSigns
          .filter((s) => s.label == x)
          .map((r) => ({
            label: r.label,
            zoneName: r.zoneName,
            zoneColor: r.zoneColor,
            title: r.title,
            arr: r.arr,
          })),
      }));

      let sortedArr = [];
      for (let i in splitDate) {
        let arr = [...splitDate[i].val];
        arr.sort((a, b) => (a.zoneName > b.zoneName ? 1 : -1));
        sortedArr = sortedArr.concat(arr);
      }

      // Calculate height and fontsize
      let { rowsCount, fontSize, singleRowHieght, formattedList } =
        getRowDetails(sortedArr, 40);
      setRowInitialHeight(singleRowHieght);
      setTextFontSize(fontSize);
      setPitchingSigns(formattedList);
    }
  }, [props.zones]);

  useEffect(() => {
    if (props.bandType != wristBandType.pitching && props.selectedSigns) {
      let signsArr = [...props.selectedSigns];
      signsArr.sort((a, b) => (a.label > b.label ? 1 : -1));

      // Calculate height and fontsize
      let { rowsCount, fontSize, singleRowHieght, formattedList } =
        getRowDetails(signsArr, 40);
      setRowInitialHeight(singleRowHieght);
      setTextFontSize(fontSize);
      setPitchingSigns(formattedList);
    }
  }, [props.selectedSigns]);

  return (
    <Document>
      <Page size="A4" wrap={false}>
        {[...Array(props.printCount ? props.printCount : 1)].map(() => {
          return (
            <div
              style={{
                height: parseInt(props.height) + 25,
                width: props.width,
              }}
            >
              {props.wristbandPitcherName != "" &&
                props.wristbandPitcherName != undefined && (
                  <div style={{ textAlign: "center" }}>
                    <Text style={{ fontSize: 10, marginBottom: "5px" }}>
                      {props.wristbandPitcherName}
                    </Text>
                  </div>
                )}
              <div
                style={{
                  height: props.height,
                  width: props.width,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {/* Zones visual */}
                {props.bandType == wristBandType.pitching && (
                  <div
                    style={{
                      width: getColumnWidth(35) - spaceBetweenVale,
                      paddingRight: spaceBetweenVale,
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: "10px", paddingRight: "8px" }}>
                        L
                      </Text>
                      <Text style={{ fontSize: "10px", paddingLeft: "8px" }}>
                        R
                      </Text>
                    </div>

                    <div>
                      {props.zones?.map((containerItem, containerIndex) => (
                        <View wrap={false} style={{ flexDirection: "row" }}>
                          {/* <div style={{ position: "absolute", top: containerIndex == 0 ? 0 : (containerIndex * props.zoneInitialHeight) + (containerIndex * props.zonePadding * 2) }}> */}
                          {containerItem
                            .slice()
                            .reverse()
                            .map((item, index) => {
                              const label = item.content ? item.content : "OFF";
                              return (
                                <div
                                  style={getItemStyle(
                                    getboxWidth(item),
                                    17,
                                    item,
                                    item.opacity,
                                    item.zIndex,
                                    item.content && item.data.length > 0
                                      ? false
                                      : true
                                  )}
                                >
                                  <Text
                                    style={{
                                      fontSize: getBoxFontSize(label.length),
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {label}
                                  </Text>
                                </div>
                              );
                            })}
                          {/* </div> */}
                        </View>
                      ))}
                    </div>
                  </div>
                )}

                {/* Signs Table */}
                <div
                  style={{
                    width: getColumnWidth(
                      props.bandType == wristBandType.pitching ? 65 : 100
                    ),
                    border: "1px solid black",
                    borderTop: "0px",
                  }}
                >
                  {props.bandType == wristBandType.pitching
                    ? pitchingSigns?.map((item, index) => {
                        return (
                          <div
                            style={{
                              fontSize: textFontSize, // getRowFontSize(getPitchingRowHeight()),
                              minHeight: item.rowsCount * rowInitialHeight, //getPitchingRowHeight(),
                              borderTop:
                                index == 0 ||
                                (index > 0 &&
                                  item.label != pitchingSigns[index - 1].label)
                                  ? "1px solid black"
                                  : "none",
                              borderBottom:
                                index == pitchingSigns.length - 1
                                  ? "1px solid black"
                                  : "none",
                              backgroundColor: item.zoneColor,
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: 40 }}>
                              <Text style={{ textTransform: "uppercase" }}>
                                {item.title}
                              </Text>
                            </div>
                            <div
                              style={{
                                width:
                                  getColumnWidth(
                                    props.bandType == wristBandType.pitching
                                      ? 65
                                      : 100
                                  ) - 40,
                              }}
                            >
                              <Text>
                                {convertToCommaString(
                                  getReverseIndexes(item.arr)
                                )}
                              </Text>
                            </div>
                            {/* <Text>
                        {item.title} {"    "}
                        {convertToCommaString(item.arr)}
                      </Text> */}
                          </div>
                        );
                      })
                    : pitchingSigns?.map((item, index) => (
                        <div
                          style={{
                            fontSize: textFontSize, //getRowFontSize(getRowHeight()),
                            minHeight: item.rowsCount * rowInitialHeight, // getRowHeight(),
                            borderTop: "1px solid black",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: 40 }}>
                            <Text style={{ textTransform: "uppercase" }}>
                              {item.label}
                            </Text>
                          </div>
                          <div
                            style={{
                              width:
                                getColumnWidth(
                                  props.bandType == wristBandType.pitching
                                    ? 65
                                    : 100
                                ) - 40,
                            }}
                          >
                            <Text>
                              {convertToCommaString(
                                getReverseIndexes(item.arr)
                              )}
                            </Text>
                          </div>
                          {/* <Text>
                      {item.label} {"    "}
                      {convertToCommaString(item.arr)}
                    </Text> */}
                        </div>
                      ))}
                </div>
              </div>
            </div>
          );
        })}
        <Image
          src={appLogo}
          style={{
            height: 40,
            width: 120,
            position: "absolute",
            bottom: 65,
            right: "40%",
          }}
        />
      </Page>
    </Document>
  );
}

export default PitcherCard;
